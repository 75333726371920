/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import type { MenuProps } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';
import { Link, Location } from 'react-router-dom';
import { ERoute, ESettingsMenuTab, EUserRole } from 'common/const/enum';
import { getSettingsPath, getShopPath } from 'common/helpers/router.helper';
import { STATISTICS_OVERFLOW_COUNT } from 'common/config';
import { ReactComponent as SettingsIcon } from 'app/assets/images/settings.svg';
import { ReactComponent as ListIcon } from 'app/assets/images/list.svg';
import { ReactComponent as ShopIcon } from 'app/assets/images/shop.svg';
import { ReactComponent as CalendarIcon } from 'app/assets/images/calendar.svg';
import { ReactComponent as CatalogIcon } from 'app/assets/images/redesign/catalog.svg';
import { ReactComponent as TreeIcon } from 'app/assets/images/redesign/tree-alt.svg';
import { ReactComponent as AccountIcon } from 'app/assets/images/redesign/account.svg';
import { ReactComponent as ContractIcon } from 'app/assets/images/redesign/contract.svg';
import { ReactComponent as DrawerIcon } from 'app/assets/images/redesign/drawer.svg';
import { ReactComponent as CartIcon } from 'app/assets/images/redesign/cart.svg';
import { ReactComponent as DrawerFullIcon } from 'app/assets/images/redesign/drawer-full.svg';
import { ReactComponent as ExitIcon } from 'app/assets/images/redesign/exit.svg';
import { IToken } from 'entities/Auth/Auth.models';
import { getUserName } from 'entities/Users/Users.helper';
import { IUserStatistics } from 'entities/Users/Users.models';

export const getSidebarMenuItems = (location: Location, userCatalogId: number | null, roles?: string[]) => {
  const menuItems: MenuProps['items'] = [
    {
      key: 'settings',
      label: (
        <Link
          to={getSettingsPath(roles?.includes(EUserRole.SuperAdmin) ? ESettingsMenuTab.AccountList : ESettingsMenuTab.Account)}
        >
          <SettingsIcon />
        </Link>
      ),
      disabled: !roles?.includes(EUserRole.SuperAdmin) && !roles?.includes(EUserRole.AccountAdmin),
    },
    {
      key: 'list',
      label: (
        <Link to={roles?.includes(EUserRole.SuperAdmin) ? ERoute.CatalogList : ERoute.ActiveBasketList}>
          <ListIcon />
        </Link>
      ),
      disabled: !roles?.includes(EUserRole.SuperAdmin) && !roles?.includes(EUserRole.SellerManager),
    },
    {
      key: 'collection',
      label: (
        <Link to={roles?.includes(EUserRole.SuperAdmin) ? ERoute.PropertiesSettings : ERoute.ManagerNeedList}>
          <UnorderedListOutlined />
        </Link>
      ),
      disabled: !roles?.includes(EUserRole.SuperAdmin) && !roles?.includes(EUserRole.Manager),
    },
    {
      key: 'shop',
      label: (
        <Link to={getShopPath(location, userCatalogId)}>
          <ShopIcon />
        </Link>
      ),
      disabled: roles?.includes(EUserRole.SuperAdmin),
    },
    {
      key: 'calendar',
      label: (
        <Link to={ERoute.SellerNeedList}>
          <CalendarIcon />
        </Link>
      ),
      disabled: !roles?.includes(EUserRole.SellerManager),
    },
  ];

  // @ts-ignore
  return menuItems.filter((item) => !item.disabled).map((item) => ({ ...item, className: 'sidebar__link' }));
};

export const getStatisticsItemCount = (count?: number) => {
  if (!count) {
    return null;
  }

  return count > STATISTICS_OVERFLOW_COUNT ? `${count}+` : count;
};

export const getSidebarItemClassName = ({ isActive }: { isActive: boolean }) =>
  `sidebar-r__item ${isActive ? 'sidebar-r__item-active' : ''}`;

export const getSidebarItems = (
  auth: IToken | null,
  superAdminAsUser: boolean,
  statistics: IUserStatistics | null,
  onClick: () => void,
  logout: () => void,
) => {
  const isSuperAdmin = auth?.access.roles.includes(EUserRole.SuperAdmin);
  const isSellerManager = auth?.access.roles.includes(EUserRole.SellerManager);
  const isManager = auth?.access.roles.includes(EUserRole.Manager);
  const firstName = auth?.access.firstName;
  const lastName = auth?.access.lastName;
  const initials = `${firstName?.[0] || ''}${lastName?.[0] || ''}`;
  const userName = getUserName(firstName, lastName);

  return {
    body: {
      orangeGroup: [
        {
          key: 'catalog-list',
          show: isSuperAdmin,
          to: ERoute.CatalogList,
          icon: <CatalogIcon />,
          label: 'Системные каталоги',
          onClick,
        },
        {
          key: 'catalog',
          show: !isSuperAdmin,
          to: window.location.pathname.includes(ERoute.Catalog) ? `${window.location.pathname}` : ERoute.Catalog,
          icon: <CatalogIcon />,
          label: 'Каталог',
          onClick,
        },
      ],
      darkGreenGroup: [
        {
          key: 'properties-settings',
          show: isSuperAdmin,
          to: ERoute.PropertiesSettings,
          icon: <TreeIcon />,
          label: 'Дерево характеристик',
          onClick,
        },
        {
          key: 'seller-need-list',
          show: !isSuperAdmin && isSellerManager,
          to: ERoute.SellerNeedList,
          icon: <DrawerFullIcon />,
          label: 'Заявки на поставку',
          badge: getStatisticsItemCount(statistics?.requestsCount),
          onClick,
        },
        {
          key: 'manager-need-list',
          show: !isSuperAdmin && isManager,
          to: ERoute.ManagerNeedList,
          icon: <DrawerFullIcon />,
          label: 'Заявки на закупку',
          badge: getStatisticsItemCount(statistics?.requestsCount),
          onClick,
        },
        {
          key: 'need-list',
          show: !isSuperAdmin && !isSellerManager,
          to: ERoute.NeedList,
          icon: <DrawerIcon />,
          label: 'Мои заявки',
          onClick,
        },
      ],
      brightGreenGroup: [
        {
          key: 'account-list',
          show: isSuperAdmin,
          to: ERoute.AccountList,
          icon: <AccountIcon />,
          label: 'Аккаунты',
          onClick,
        },
        {
          key: 'contract-list',
          show: isSuperAdmin,
          to: ERoute.ContractList,
          icon: <ContractIcon />,
          label: 'Контракты',
          onClick,
        },
        {
          key: 'active-basket-list',
          show: !isSuperAdmin && isSellerManager,
          to: ERoute.ActiveBasketList,
          icon: <CartIcon />,
          label: 'Корзины клиентов',
          onClick,
        },
        {
          key: 'seller-contract-list',
          show: !isSuperAdmin && isSellerManager,
          to: ERoute.SellerContractList,
          icon: <ContractIcon />,
          label: 'Контракты',
          onClick,
        },
        {
          key: 'basket',
          show: !isSuperAdmin && !isSellerManager,
          to: ERoute.Basket,
          icon: <CartIcon />,
          label: 'Корзина',
          badge: getStatisticsItemCount(statistics?.basketGoodsCount),
          onClick,
        },
      ],
    },
    footer: [
      {
        key: 'profile',
        show: !isSuperAdmin,
        to: ERoute.Profile,
        icon: (
          <span className="text-profile-icon color-dark-grey" style={{ zIndex: 1 }}>
            {initials}
          </span>
        ),
        label: userName,
        iconClassName: 'sidebar-r__footer-item-profile-icon',
        onClick,
      },
      {
        key: 'logout',
        show: true,
        to: '',
        icon: <ExitIcon />,
        label: superAdminAsUser ? 'Вернуться в аккаунт суперадминистратора' : 'Выйти',
        onClick: () => {
          onClick();
          logout();
        },
      },
    ],
  };
};

export const getSidebarHeaderSubtitle = (roles?: string[], subdivisionName?: string) => {
  const isSuperAdmin = roles?.includes(EUserRole.SuperAdmin);
  const isAccountAdmin = roles?.includes(EUserRole.AccountAdmin);
  const isSellerManager = roles?.includes(EUserRole.SellerManager);
  const isManager = roles?.includes(EUserRole.Manager);

  switch (true) {
    case !!subdivisionName: {
      return subdivisionName;
    }
    case isSuperAdmin: {
      return 'Суперадмин';
    }
    case isAccountAdmin: {
      return 'Администратор';
    }
    case isSellerManager: {
      return 'Менеджер';
    }
    case isManager: {
      return 'Менеджер по закупкам';
    }
    default: {
      return null;
    }
  }
};
