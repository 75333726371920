import { DEFAULT_DEBOUNCE_DELAY, INTEGER_PATTERN } from 'common/config';
import { EColor } from 'common/const/enum';
import { IOption } from 'common/models';

export const toDataSourceMapper = <T>(data?: T[] | null): T[] | undefined =>
  data?.map((item, index) => ({ key: index, ...item }));

export const getId = () => {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
};

export const debounce = <A = unknown, R = void>(func: (args: A) => R) => {
  let timeoutId: NodeJS.Timeout;

  return (args: A) => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func(args);
    }, DEFAULT_DEBOUNCE_DELAY);
  };
};

export const getUniqOptions = (options: IOption[]) => {
  return options.reduce((acc: IOption[], currentItem) => {
    if (!acc.some((item) => item.value === currentItem.value)) {
      acc.push(currentItem);
    }

    return acc;
  }, []);
};

export const getGoodsItemStatusColor = (status?: string) => {
  if (status === 'pending') {
    return EColor.Orange;
  }

  return EColor.Gray;
};

export const stringToNumber = (string: string) => Number(string);

export const numberToString = (number: number) => number.toString();

export const isInteger = (number: number) => INTEGER_PATTERN.test(number.toString());

export const mapToMenuWithDivider = <T>(list: T[]): T[] => {
  return list.reduce<T[]>((acc, curr, index) => {
    if (index > 0) {
      acc.push({ type: 'divider' } as T);
    }

    acc.push(curr);

    return acc;
  }, []);
};

export const getCounterWidth = (count: number, responsive?: boolean) => {
  // 11 - estimated letter width in pixels, 80 - sum of button widths and horizontal padding
  return responsive ? `${Math.min(count.toString().length * 11 + 80, 200)}px` : '100%';
};

export const generateUid = () => Math.random().toString(16).slice(2);
