import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Navigation } from 'common/components/Navigation';
import { sellerNavigationItems } from 'common/const/navigation.const';
import { ESellerManagerNeedListMenuTab } from 'common/const/enum';
import { PendingNeedListForSellerManager } from 'entities/Need/components/PendingNeedListForSellerManager';
import { SupplyListForSellerManager } from 'entities/Supply/components/SupplyListForSellerManager';

export const SellerManagerNeedList: FC = () => {
  const { tab } = useParams();

  const getContent = () => {
    if (tab === ESellerManagerNeedListMenuTab.SupplyList) {
      return <SupplyListForSellerManager />;
    }

    return <PendingNeedListForSellerManager />;
  };

  return (
    <div className="need-list-wrapper">
      <Navigation items={sellerNavigationItems} />

      {getContent()}
    </div>
  );
};
