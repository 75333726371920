import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { INavigationItem } from 'common/models';

interface IComponentProps {
  items: INavigationItem[];
}

export const Navigation: FC<IComponentProps> = ({ items }) => {
  const location = useLocation();

  return (
    <div className="navigation">
      {items.map((item, index) => {
        return (
          <div className="navigation__item-container" key={item.path}>
            <Link className={`navigation__item ${location.pathname === item.path ? 'active' : ''}`} to={item.path}>
              {item.label}
            </Link>

            {index !== items.length - 1 && <div className="navigation__item-dot">●</div>}
          </div>
        );
      })}
    </div>
  );
};
