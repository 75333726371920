import { EBasketMenuTab, EManagerNeedListMenuTab, ERoute, ESellerManagerNeedListMenuTab } from 'common/const/enum';

export const userNavigationItems = [
  {
    label: 'заявки',
    path: ERoute.NeedList,
  },
  {
    label: 'размещенные заказы',
    path: `${ERoute.NeedList}/${EBasketMenuTab.SupplyList}`,
  },
];

export const managerNavigationItems = [
  {
    label: 'входящие',
    path: ERoute.ManagerNeedList,
  },
  {
    label: 'в работе',
    path: `${ERoute.ManagerNeedList}/${EManagerNeedListMenuTab.Pending}`,
  },
  {
    label: 'график поставок',
    path: `${ERoute.ManagerNeedList}/${EManagerNeedListMenuTab.SupplyList}`,
  },
];

export const sellerNavigationItems = [
  {
    label: 'входящие',
    path: ERoute.SellerNeedList,
  },
  {
    label: 'размещенные',
    path: `${ERoute.SellerNeedList}/${ESellerManagerNeedListMenuTab.SupplyList}`,
  },
];
