import React, { FC } from 'react';
import { Tooltip } from 'antd';
import { EGoodsDeliveryStatus } from 'common/const/enum';
import {
  calculateGoodsDeliveryTime,
  findGoodsOptimalStock,
  getGoodsDeliveryBadgeTooltipTitle,
  getGoodsDeliveryStatus,
} from 'common/helpers/delivery.helper';
import { ReactComponent as DeliveryIcon } from 'app/assets/images/redesign/delivery.svg';
import { ReactComponent as InfoRedIcon } from 'app/assets/images/redesign/info-red.svg';
import { ReactComponent as InfoYellowIcon } from 'app/assets/images/redesign/info-yellow.svg';
import { ReactComponent as InfoGreenIcon } from 'app/assets/images/redesign/info-green.svg';
import { IGoodsRemain } from 'entities/Goods/Goods.models';

interface IComponentProps {
  remains: IGoodsRemain[];
  count: number;
}

export const GoodsDeliveryBadge: FC<IComponentProps> = ({ remains, count }) => {
  const optimalStock = findGoodsOptimalStock(remains, count);
  const deliveryTime = calculateGoodsDeliveryTime(remains, optimalStock?.deliveryInDays);
  const deliveryStatus = getGoodsDeliveryStatus(remains, optimalStock?.deliveryInDays);

  return (
    <div className={`goods-delivery-badge ${deliveryStatus}`}>
      <DeliveryIcon className="goods-delivery-badge__car" />

      <div className="text-tag-accent">{deliveryTime}</div>

      <Tooltip overlayClassName="redesign goods-delivery-badge__tooltip" title={getGoodsDeliveryBadgeTooltipTitle(remains)}>
        {deliveryStatus === EGoodsDeliveryStatus.InStock && <InfoGreenIcon className="goods-delivery-badge__info" />}
        {deliveryStatus === EGoodsDeliveryStatus.AvailableOnRequest && <InfoYellowIcon className="goods-delivery-badge__info" />}
        {deliveryStatus === EGoodsDeliveryStatus.OutOfStock && <InfoRedIcon className="goods-delivery-badge__info" />}
      </Tooltip>
    </div>
  );
};
