import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Frame } from 'common/components/Frame';
import { useDebounce } from 'common/hooks/useDebounce';
import { EMessage, ESearchParams, ESubdivisionFrame } from 'common/const/enum';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { IFormValues } from 'common/models';
import { rules } from 'common/helpers/form.helper';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';
import { SubdivisionActions } from 'entities/Subdivisions/components/SubdivisionActions';
import { ISubdivision } from 'entities/Subdivisions/Subdivisions.models';
import { SubdivisionUsers } from 'entities/Subdivisions/components/SubdivisionUsers';
import { SubdivisionAddresses } from 'entities/Subdivisions/components/SubdivisionAddresses';
import { SubdivisionWorkspaces } from 'entities/Subdivisions/components/SubdivisionWorkspaces';
import { SubdivisionLimit } from 'entities/Subdivisions/components/SubdivisionLimit';
import { SubdivisionManager } from 'entities/Subdivisions/components/SubdivisionManager';
import { SubdivisionCode } from 'entities/Subdivisions/components/SubdivisionCode';
import { SubdivisionLegal } from 'entities/Subdivisions/components/SubdivisionLegal';
import { legalCollectionDataToSelectOptions } from 'entities/LegalEntity/LegalEntity.helper';

interface IComponentProps {
  subdivision?: ISubdivision;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const SubdivisionInfoComponent: React.FC<AllType> = (props) => {
  const { subdivision, legalListState, subdivisionState, updateSubdivision } = props;

  const { data: legalList } = legalListState;
  const { loading: subdivisionLoading } = subdivisionState;

  const { searchParams, setSearchParam } = useSearchParamsHook();
  const [formIsChanged, setFormIsChanged] = useState<boolean>(false);
  const [subdivisionNameValue, setSubdivisionNameValue] = useState<string>('');
  const [subdivisionsInfoForm] = useForm();

  const frame = searchParams.get(ESearchParams.Frame);
  const legalOptions = legalCollectionDataToSelectOptions(legalList);

  const onFormValuesChange = (values: IFormValues) => {
    const value = Object.values(values)[0];

    setFormIsChanged(true);
    setSubdivisionNameValue(value);
  };

  const onSubdivisionLimitSetClick = (value: number | null, onSuccess: () => void) => {
    if (subdivision) {
      // @ts-ignore
      updateSubdivision({ id: subdivision.id, limit: value, onSuccess });
    }
  };

  const onSubdivisionMaxGoodsPriceSetClick = (value: number | null, onSuccess: () => void) => {
    if (subdivision) {
      updateSubdivision({ id: subdivision.id, maxGoodPrice: value, onSuccess });
    }
  };

  const onSendingRequestValueChange = (value: boolean) => {
    if (subdivision) {
      updateSubdivision({ id: subdivision.id, requestsConfirmation: value });
    }
  };

  const getFrameContent = () => {
    switch (frame) {
      case ESubdivisionFrame.Users: {
        return <SubdivisionUsers subdivisionId={subdivision?.id} />;
      }
      case ESubdivisionFrame.Addresses: {
        return <SubdivisionAddresses subdivisionId={subdivision?.id} />;
      }
      case ESubdivisionFrame.Workspaces: {
        return <SubdivisionWorkspaces subdivisionId={subdivision?.id} />;
      }
      default: {
        return null;
      }
    }
  };

  useEffect(() => {
    if (subdivision) {
      subdivisionsInfoForm.setFieldsValue({
        name: subdivision.name,
      });
    }
  }, [subdivision]);

  useDebounce(() => {
    if (formIsChanged) {
      const errors = subdivisionsInfoForm
        .getFieldsError()
        .map((item) => item.errors)
        .flat();

      if (!errors.length) {
        updateSubdivision({ id: subdivision?.id as number, name: subdivisionNameValue });
      }
    }
  }, [subdivisionNameValue, formIsChanged]);

  return (
    <div className="subdivision-info">
      <Form form={subdivisionsInfoForm} layout="vertical" requiredMark={false} onValuesChange={onFormValuesChange}>
        <Form.Item label="Название подразделения" name="name" rules={[rules.required()]}>
          {/* Disable if the selected subdivision is the root subdivision */}
          <Input placeholder="Введите название подразделения" disabled={!subdivision?.parentId} />
        </Form.Item>
      </Form>

      {!frame && (
        <>
          <div className="subdivision-info__subdivision-settings">
            <div>Настройки подразделения</div>

            <div className="subdivision-info__subdivision-settings_container">
              <Frame
                title="Пользователи"
                // TODO dynamic description
                description={EMessage.AddRemoveUsersToSubdivision}
                onClick={() => setSearchParam(ESearchParams.Frame, ESubdivisionFrame.Users)}
              />

              <Frame
                title="Адреса доставки"
                // TODO dynamic description
                description={EMessage.DeliveryAddressManagement}
                onClick={() => setSearchParam(ESearchParams.Frame, ESubdivisionFrame.Addresses)}
              />

              <Frame
                title="Карты рабочих мест"
                // TODO dynamic description
                description={EMessage.SetUpNeedForGoods}
                onClick={() => setSearchParam(ESearchParams.Frame, ESubdivisionFrame.Workspaces)}
              />
            </div>
          </div>

          <SubdivisionLegal
            subdivisionId={subdivision?.id}
            subdivisionLegalId={subdivision?.legalId}
            options={legalOptions}
            loading={subdivisionLoading}
            updateSubdivision={updateSubdivision}
          />

          <SubdivisionLimit
            title="Лимит расходов на закупки"
            suffix="/год"
            limit={subdivision?.limit}
            loading={subdivisionLoading}
            onSet={onSubdivisionLimitSetClick}
          />

          <SubdivisionLimit
            title="Лимит цены на товар (включительно)"
            limit={subdivision?.maxGoodPrice}
            loading={subdivisionLoading}
            onSet={onSubdivisionMaxGoodsPriceSetClick}
          />

          <SubdivisionManager subdivision={subdivision} loading={subdivisionLoading} updateSubdivision={updateSubdivision} />

          <SubdivisionCode
            subdivisionId={subdivision?.id}
            subdivisionCode={subdivision?.code}
            loading={subdivisionLoading}
            updateSubdivision={updateSubdivision}
          />

          <div>
            <div className="subdivision-info__label">Отправка запроса</div>

            <Select
              className="subdivision-info__sending-request"
              value={subdivision?.requestsConfirmation}
              onChange={onSendingRequestValueChange}
              options={[
                { label: 'С согласованием', value: true },
                { label: 'Без согласования', value: false },
              ]}
              suffixIcon={<ChevronIcon />}
            />
          </div>

          <SubdivisionActions
            subdivision={subdivision}
            changeSubdivision={(subdivisionId: number) => setSearchParam(ESearchParams.SubdivisionId, subdivisionId)}
          />
        </>
      )}

      {getFrameContent()}
    </div>
  );
};

const mapState = (state: RootState) => ({
  legalListState: state.legalListState,
  subdivisionState: state.subdivisionState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  updateSubdivision: dispatch.subdivisionState.updateSubdivision,
});

export const SubdivisionInfo = connect(mapState, mapDispatch)(SubdivisionInfoComponent);
