import React, { useState } from 'react';
import { Layout, Spin, Table } from 'antd';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toDataSourceMapper } from 'common/helpers/data.helper';
import { EMessage, EModalCollectionKey, ENotificationType, ERoute } from 'common/const/enum';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { ContentLoader } from 'common/components/ContentLoader';
import { RootDispatch, RootState } from 'app/store';
import { renderPreviousRequestsRecords } from 'entities/Requests/Requests.helper';
import { Notification } from 'entities/Modal/components/Notification';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export const PreviousRequestsComponent: React.FC<AllType> = (props) => {
  const {
    basketState,
    requestListState,
    repeatRequestModalParams,
    getBasket,
    getRequestList,
    createBasketBasedOnRequest,
    showModal,
    hideModal,
  } = props;
  const { data: basket, loading: basketLoading } = basketState;
  const { data: requestList } = requestListState;
  const { open } = repeatRequestModalParams;

  const [requestId, setRequestId] = useState<number | null>(null);
  const navigate = useNavigate();

  const dataSource = toDataSourceMapper(requestList);
  const columns = renderPreviousRequestsRecords(onRepeatClick);

  const createBasket = (requestId: number) => {
    createBasketBasedOnRequest({
      requestId,
      onSuccess: () => {
        setRequestId(null);
        hideModal(EModalCollectionKey.RepeatRequest);
        navigate(ERoute.Basket);
      },
    });
  };

  function onRepeatClick(requestId: number) {
    setRequestId(requestId);

    if (basket?.goods.length) {
      showModal(EModalCollectionKey.RepeatRequest);
    } else {
      createBasket(requestId);
    }
  }

  const onRepeatRequestModalCancelClick = () => {
    hideModal(EModalCollectionKey.RepeatRequest);
    setRequestId(null);
  };

  const onRepeatRequestModalConfirmClick = () => {
    if (requestId) {
      createBasket(requestId);
    }
  };

  const { contentLoading } = useContentLoader(async () => {
    await getRequestList({ myRequests: true, limit: 0 });
    await getBasket();
  });

  return (
    <Layout className="previous-requests">
      {contentLoading ? (
        <ContentLoader />
      ) : (
        <Spin spinning={basketLoading}>
          <div className="previous-requests__container">
            <div className="previous-requests__title">Ранее размещенные заказы</div>

            <Table
              dataSource={dataSource}
              columns={columns}
              className="previous-requests__table"
              pagination={false}
              showHeader={false}
              locale={{ emptyText: EMessage.ListIsEmpty }}
            />
          </div>
        </Spin>
      )}

      <Notification
        open={open}
        type={ENotificationType.Notification}
        description={EMessage.ThereAreAlreadyGoodsInActiveBasket}
        loading={basketLoading}
        onCancelClick={onRepeatRequestModalCancelClick}
        onConfirmClick={onRepeatRequestModalConfirmClick}
      />
    </Layout>
  );
};

const mapState = (state: RootState) => ({
  basketState: state.basketState,
  requestListState: state.requestListState,
  repeatRequestModalParams: state.modalCollection.repeatRequestModalParams,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getBasket: dispatch.basketState.getBasket,
  getRequestList: dispatch.requestListState.getRequestList,
  createBasketBasedOnRequest: dispatch.basketState.createBasketBasedOnRequest,
  showModal: dispatch.modalCollection.showModal,
  hideModal: dispatch.modalCollection.hideModal,
});

export const PreviousRequests = connect(mapState, mapDispatch)(PreviousRequestsComponent);
