import React, { FC, useState } from 'react';
import { Button, Select, Tooltip } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { IOption } from 'common/models';
import { ReactComponent as CloseIcon } from 'app/assets/images/close.svg';
import { getBasketLegalAlertTitle } from 'entities/Basket/Basket.helper';

interface IComponentProps {
  subdivisionId?: number;
  options?: IOption[];
  legalId?: number;
  legalName?: string;
  readOnly: boolean;
  onChange: (id: number) => void;
}

export const BasketLegal: FC<IComponentProps> = (props) => {
  const { subdivisionId, options, legalId, legalName, readOnly, onChange } = props;

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const alertTitle = getBasketLegalAlertTitle(subdivisionId, options?.length, legalId);

  const toggleIsEdit = () => setIsEdit((prev) => !prev);

  const onLegalChange = (value: number) => {
    if (value) {
      onChange(value);
    }

    toggleIsEdit();
  };

  return (
    <div className="basket-legal">
      <div>
        <span className="basket-legal__title">Юр.лицо</span>

        {alertTitle && !readOnly && (
          <Tooltip overlayClassName="basket-legal__tooltip" title={alertTitle}>
            <InfoCircleFilled />
          </Tooltip>
        )}
      </div>

      {!isEdit ? (
        <div className="basket-legal__value">
          {legalName && <span>{legalName}</span>}

          {!readOnly && (
            <Button type="link" onClick={toggleIsEdit}>
              Изменить
            </Button>
          )}
        </div>
      ) : (
        <Select
          value={legalId}
          onChange={onLegalChange}
          className="basket-legal__select"
          options={options}
          suffixIcon={false}
          allowClear={{ clearIcon: <CloseIcon /> }}
        />
      )}
    </div>
  );
};
