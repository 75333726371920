import React from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { DEFAULT_DELIVERY_IN_DAYS } from 'common/config';
import { EGoodsDeliveryStatus, EMessage } from 'common/const/enum';
import { countFormatter, deliveryTimeFormatter } from 'common/helpers/formatter.helper';
import { ReactComponent as DeliveryIcon } from 'app/assets/images/redesign/delivery.svg';
import { ReactComponent as TruckIcon } from 'app/assets/images/truck-gray.svg';
import { IGoodsRemain } from 'entities/Goods/Goods.models';

export const getFormattedDeliveryTime = (days?: number) => {
  if (!days) {
    return `${DEFAULT_DELIVERY_IN_DAYS} дн.`;
  }

  const daysInWeek = 7;
  const daysInMonth = 30;

  if (days >= daysInMonth) {
    const months = Math.floor(days / daysInMonth);
    return `${months} мес.`;
  } else if (days >= daysInWeek) {
    const weeks = Math.floor(days / daysInWeek);
    return `${weeks} нед.`;
  } else {
    return `${days} дн.`;
  }
};

export const renderDeliveryTimeTooltipRecords = () => {
  return [
    {
      dataIndex: 'stockName',
      render: (_, record) => (record.count ? record.stockName : 'Под заказ'),
    },
    {
      dataIndex: 'count',
      render: (text) => (text ? `${text} шт.` : ''),
    },
    {
      dataIndex: 'deliveryInDays',
      render: (text) => (
        <div className="goods-delivery-time__tooltip-item-days">
          <TruckIcon />

          <span>{getFormattedDeliveryTime(text)}</span>
        </div>
      ),
    },
  ] as ColumnsType<IGoodsRemain>;
};

export const getGoodsDeliveryTime = (count: number, remains: IGoodsRemain[] = []) => {
  if (!remains.length) {
    return { deliveryTime: 'Уточнить у поставщика' };
  }

  let accumulatedCount = 0;
  let minDeliveryInDays: number | undefined = undefined;

  for (const item of remains) {
    if (item.count !== undefined) {
      accumulatedCount += item.count;

      if (item.deliveryInDays !== undefined) {
        minDeliveryInDays = item.deliveryInDays;
      }

      if (accumulatedCount >= count) {
        return {
          totalCount: accumulatedCount,
          deliveryTime: getFormattedDeliveryTime(minDeliveryInDays),
        };
      }
    }
  }

  return { deliveryTime: getFormattedDeliveryTime() };
};

export const sortRemainsByDeliveryInDays = (remains: IGoodsRemain[] = []) => {
  return remains.sort((a, b) => {
    if (a.deliveryInDays === undefined) {
      return 1;
    }

    if (b.deliveryInDays === undefined) {
      return -1;
    }

    return (a.deliveryInDays as number) - (b.deliveryInDays as number);
  });
};

export const findGoodsOptimalStock = (remains: IGoodsRemain[], count: number) => {
  const suitableStocks = remains.filter(
    (stock) => stock.count !== undefined && stock.count >= count && stock.deliveryInDays !== undefined,
  );

  if (suitableStocks.length === 0) {
    return null;
  }

  return suitableStocks.reduce((minStock, currentStock) => {
    return (currentStock.deliveryInDays as number) < (minStock.deliveryInDays as number) ? currentStock : minStock;
  });
};

export const calculateGoodsDeliveryTime = (remains: IGoodsRemain[], deliveryInDays?: number) => {
  if (!remains.length) {
    return 'Нет на складе';
  }

  if (!deliveryInDays) {
    return 'Доступно под заказ';
  }

  return deliveryTimeFormatter.full(deliveryInDays);
};

export const getGoodsDeliveryStatus = (remains: IGoodsRemain[], deliveryInDays?: number) => {
  if (!remains.length) {
    return EGoodsDeliveryStatus.OutOfStock;
  }

  if (!deliveryInDays) {
    return EGoodsDeliveryStatus.AvailableOnRequest;
  }

  return EGoodsDeliveryStatus.InStock;
};

export const getGoodsDeliveryBadgeTooltipTitle = (remains: IGoodsRemain[]) => {
  return !remains.length ? (
    <div className="goods-delivery-badge__table-empty text-tag">{EMessage.DeliveryBadgeTooltip}</div>
  ) : (
    <Table
      className="goods-delivery-badge__table"
      dataSource={remains.map((item, index) => ({ key: index, ...item }))}
      columns={[
        {
          key: 'stockName',
          dataIndex: 'stockName',
          render: (_, { stockName }) => {
            return <div className="goods-delivery-badge__table-item-stock-name">{stockName}</div>;
          },
        },
        {
          key: 'count',
          dataIndex: 'count',
          render: (_, { count }) => (count ? countFormatter(count) : null),
        },
        {
          key: 'deliveryInDays',
          dataIndex: 'deliveryInDays',
          render: (_, { deliveryInDays }) => {
            return (
              <div className="goods-delivery-badge__table-item-container">
                <DeliveryIcon />

                <span>{deliveryTimeFormatter.short(deliveryInDays)}</span>
              </div>
            );
          },
        },
      ]}
      size="small"
      pagination={false}
      showHeader={false}
    />
  );
};
