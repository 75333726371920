import React, { FC, useState } from 'react';
import { Button, Drawer, Dropdown } from 'antd';
import { ReactComponent as CopyIcon } from 'app/assets/images/redesign/copy.svg';
import { ReactComponent as OptionsIcon } from 'app/assets/images/redesign/options.svg';

interface IComponentProps {
  id?: number;
  loading: boolean;
  clearDisabled: boolean;
  onDelete: (onSuccess: () => void) => void;
}

export const BasketMoreButton: FC<IComponentProps> = ({ id, loading, clearDisabled, onDelete }) => {
  const [open, setOpen] = useState<boolean>(false);

  const toggleOpen = () => setOpen((prev) => !prev);

  const onCopyClick = () => {
    if (id) {
      navigator.clipboard.writeText(id.toString());
    }
  };

  return (
    <>
      <Dropdown
        overlayClassName="redesign basket-more-button"
        menu={{
          items: [
            {
              key: 'copy',
              label: (
                <div className="basket-more-button__menu-item">
                  <span className="text-tag color-dark-grey">Код корзины</span>

                  <div className="basket-more-button__menu-item-container">
                    <span className="text-h4-item-name">{id}</span>

                    <CopyIcon />
                  </div>
                </div>
              ),
              onClick: onCopyClick,
            },
            {
              type: 'divider',
            },
            {
              key: 'clear',
              label: <span className="text-lists color-red">Очистить корзину</span>,
              onClick: toggleOpen,
              disabled: clearDisabled,
            },
          ],
        }}
        trigger={['click']}
        placement="bottomRight"
        dropdownRender={(menus) => {
          return (
            <div className="custom-dropdown" style={{ width: 280 }}>
              {menus}
            </div>
          );
        }}
      >
        <Button className="button-circle secondary" icon={<OptionsIcon />} />
      </Dropdown>

      <Drawer
        rootClassName="redesign basket-delete-goods-button__drawer"
        open={open}
        onClose={!loading ? toggleOpen : undefined}
        width={720}
      >
        <div className="basket-delete-goods-button__drawer-container">
          <span className="text-h1 basket-delete-goods-button__drawer-title">Вы уверены, что хотите очистить корзину?</span>

          <span className="text-h3 color-dark-grey">Все товары будут безвозвратно удалены.</span>
        </div>

        <div className="basket-delete-goods-button__drawer-footer">
          <Button className="button-l danger" onClick={() => onDelete(toggleOpen)} loading={loading}>
            Очистить
          </Button>

          <Button className="button-l secondary" onClick={toggleOpen} disabled={loading}>
            Отмена
          </Button>
        </div>
      </Drawer>
    </>
  );
};
