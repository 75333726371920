import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Pagination, Select, Spin } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { PaginationProps } from 'antd';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { ContentLoader } from 'common/components/ContentLoader';
import { GoodsItem } from 'common/components/GoodsItem';
import { EMessage, ENeedStatus, ESearchParams } from 'common/const/enum';
import { DEFAULT_PAGINATION_PAGE, DEFAULT_LIST_LIMIT, DEFAULT_LIST_OFFSET } from 'common/config';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';
import { getNeedListSubdivisionOptions, getNeedListUserOptions } from 'entities/Need/Need.helper';
import { INeed, INeedListPayload } from 'entities/Need/Need.models';
import { NeedHistory } from 'entities/Need/components/NeedHistory';
import { getUserName } from 'entities/Users/Users.helper';
import { SupplySchedule } from 'entities/Supply/components/SupplySchedule';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const PendingNeedListForManagerComponent: React.FC<AllType> = (props) => {
  const {
    needListState,
    getNeedList,
    getNeedUserList,
    getNeedSubdivisionListForManager,
    changeNeedStatus,
    filterNeedList,
    uploadNeedExcel,
    setNeedList,
    getSupplyList,
    setSupplyList,
    getNeedHistoryList,
    setNeedHistoryList,
    getNeedById,
  } = props;

  const { data: needList, count: needListCount, loading: needListLoading, userList, subdivisionList } = needListState;

  const [selectedNeedIds, setSelectedNeedIds] = useState<number[]>([]);
  const [payload, setPayload] = useState<INeedListPayload>({
    statuses: [ENeedStatus.Pending],
    limit: DEFAULT_LIST_LIMIT,
    offset: DEFAULT_LIST_OFFSET,
  });
  const [page, setPage] = useState<number>(DEFAULT_PAGINATION_PAGE);
  const [selectedNeed, setSelectedNeed] = useState<INeed | null>(null);
  const { getSearchParam, removeSearchParam } = useSearchParamsHook();

  const needId = getSearchParam(ESearchParams.Id);
  const checkAll = !!needList.length && needList.length === selectedNeedIds.length;
  const subdivisionOptions = getNeedListSubdivisionOptions(subdivisionList);
  const userOptions = getNeedListUserOptions(userList);

  const onSubdivisionChange = async (value?: number) => {
    const newPayload = {
      ...payload,
      subdivisionIds: value ? [value] : undefined,
      userId: undefined,
      offset: DEFAULT_LIST_OFFSET,
    };

    setPayload(newPayload);
    await getNeedList(newPayload);
    await getNeedUserList({ subdivisionId: value, statuses: payload.statuses });
    removeSearchParam(ESearchParams.Id);
    setSelectedNeedIds([]);
    setSelectedNeed(null);
    setSupplyList([]);
    setNeedHistoryList([]);
    setPage(DEFAULT_PAGINATION_PAGE);
  };

  const onUserChange = async (value: number | undefined) => {
    const newPayload = { ...payload, userId: value, offset: DEFAULT_LIST_OFFSET };

    setPayload(newPayload);
    await getNeedList(newPayload);
    removeSearchParam(ESearchParams.Id);
    setSelectedNeedIds([]);
    setSelectedNeed(null);
    setSupplyList([]);
    setNeedHistoryList([]);
    setPage(DEFAULT_PAGINATION_PAGE);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    const checkedList = needList.map((need) => need.id);
    setSelectedNeedIds(e.target.checked ? checkedList : []);
  };

  const onRejectClick = async () => {
    await changeNeedStatus({ ids: selectedNeedIds, status: ENeedStatus.Cancelled });
    filterNeedList(selectedNeedIds);
    setSelectedNeedIds([]);

    if (selectedNeedIds.includes(selectedNeed?.id as number)) {
      setSelectedNeed(null);
      setSupplyList([]);
      setNeedHistoryList([]);
    }

    if (selectedNeedIds.length === needList.length) {
      const newPayload = { ...payload, offset: DEFAULT_LIST_OFFSET };

      setPayload(newPayload);
      await getNeedList(newPayload);
      setPage(DEFAULT_PAGINATION_PAGE);
    }
  };

  const onPageChange: PaginationProps['onChange'] = async (value) => {
    const newPayload = { ...payload, offset: (value - 1) * DEFAULT_LIST_LIMIT };

    setPayload(newPayload);
    await getNeedList(newPayload);
    setSelectedNeedIds([]);
    setSelectedNeed(null);
    setSupplyList([]);
    setNeedHistoryList([]);
    setPage(value);
  };

  const onNeedClick = async (id: number) => {
    const selectedNeed = needList.find((need) => need.id === id);

    setSelectedNeed(selectedNeed as INeed);
    await getSupplyList({ supplyNeedId: id, orderDirection: 'ASC' });
    await getNeedHistoryList({ supplyNeedId: id, limit: DEFAULT_LIST_LIMIT });
  };

  useEffect(() => {
    return () => {
      setSupplyList([]);
      setNeedHistoryList([]);
    };
  }, []);

  const { contentLoading } = useContentLoader(async () => {
    if (needId) {
      const response = await getNeedById(Number(needId));

      if (response) {
        await getSupplyList({ supplyNeedId: response.id, orderDirection: 'ASC' });
        setNeedList([response]);
        setSelectedNeed(response);
      }
    } else {
      await getNeedList(payload);
    }

    await getNeedUserList({ statuses: payload.statuses });
    await getNeedSubdivisionListForManager({ statuses: payload.statuses });
  });

  if (contentLoading) {
    return (
      <div className="need-list">
        <ContentLoader />
      </div>
    );
  }

  return (
    <div className="need-list">
      <div className="need-list__content">
        <div className="need-list__filter">
          <div className="need-list__filter-item">
            <span className="need-list__filter-item-label">Подразделение</span>

            <Select
              suffixIcon={<ChevronIcon />}
              value={payload.subdivisionIds?.[0]}
              options={subdivisionOptions}
              popupMatchSelectWidth={false}
              placeholder="Выберите подразделение"
              allowClear
              onChange={onSubdivisionChange}
            />
          </div>

          <div className="need-list__filter-item">
            <span className="need-list__filter-item-label">Пользователь</span>

            <Select
              suffixIcon={<ChevronIcon />}
              value={payload.userId}
              options={userOptions}
              popupMatchSelectWidth={false}
              placeholder="Выберите пользователя"
              allowClear
              onChange={onUserChange}
            />
          </div>

          <Button
            className="btn btn-default"
            onClick={() => {
              uploadNeedExcel(payload);
            }}
          >
            Выгрузить в эксель
          </Button>
        </div>

        <div className="need-list__btn-container">
          <Checkbox checked={checkAll} onChange={onCheckAllChange}>
            Выделить все / Снять выделение
          </Checkbox>

          <Button className="btn btn-red" onClick={onRejectClick} disabled={!selectedNeedIds.length}>
            Завершить выделенные заявки
          </Button>
        </div>

        <Spin spinning={needListLoading}>
          <>
            {needList.length ? (
              needList.map((need) => {
                const checked = selectedNeedIds.includes(need.id);

                return (
                  <GoodsItem
                    key={need.id}
                    id={need.id}
                    options={{
                      canBeSelected: true,
                      showVendorCode: true,
                      showApprovedSuppliesCount: true,
                      showUserName: true,
                      showSubdivisionName: true,
                      checked,
                      image: need.good.image,
                      vendorCode: need.good.vendorCode,
                      count: need.count,
                      approvedSuppliesCount: need.approvedSuppliesCount,
                      name: need.good.name,
                      userName: getUserName(need.user.firstName, need.user.lastName),
                      subdivisionName: need.subdivisionName,
                    }}
                    changeChecked={(id, value) => {
                      setSelectedNeedIds((prev) => (value ? [...prev, id] : prev.filter((item) => item !== id)));
                    }}
                    onItemClick={onNeedClick}
                  />
                );
              })
            ) : (
              <div className="need-list__empty">{EMessage.RequestListIsEmpty}</div>
            )}

            <Pagination total={needListCount} current={page} pageSize={DEFAULT_LIST_LIMIT} onChange={onPageChange} />
          </>
        </Spin>
      </div>

      <div>
        <SupplySchedule need={selectedNeed} />

        <NeedHistory need={selectedNeed} />
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  needListState: state.needListState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getNeedList: dispatch.needListState.getNeedList,
  getNeedUserList: dispatch.needListState.getNeedUserList,
  getNeedSubdivisionListForManager: dispatch.needListState.getNeedSubdivisionListForManager,
  changeNeedStatus: dispatch.needListState.changeNeedStatus,
  filterNeedList: dispatch.needListState.filterNeedList,
  uploadNeedExcel: dispatch.needListState.uploadNeedExcel,
  setNeedList: dispatch.needListState.setNeedList,
  getSupplyList: dispatch.supplyListState.getSupplyList,
  setSupplyList: dispatch.supplyListState.setSupplyList,
  getNeedHistoryList: dispatch.needHistoryListState.getNeedHistoryList,
  setNeedHistoryList: dispatch.needHistoryListState.setNeedHistoryList,
  getNeedById: dispatch.needState.getNeedById,
});

export const PendingNeedListForManager = connect(mapState, mapDispatch)(PendingNeedListForManagerComponent);
