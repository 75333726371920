import React, { FC } from 'react';
import { Button, Image, Skeleton, Space } from 'antd';
import placeholder from 'app/assets/images/placeholder.png';

export const CatalogCategoryListSkeleton: FC = () => (
  <div className="catalog__category-list">
    {Array.from({ length: 10 }, (_, i) => i + 1).map((item) => {
      return (
        <Button key={item} className="catalog-category-card">
          <Image wrapperClassName="image image-80" src={placeholder} preview={false} />

          <Space className="catalog-category-card__skeleton-container">
            <Skeleton.Input className="catalog-category-card__skeleton-input" size="small" active />

            <Skeleton.Button size="small" active />
          </Space>
        </Button>
      );
    })}
  </div>
);
