import { RefObject, useEffect, useRef } from 'react';

export const useScroll = (
  endOfScrollRef: RefObject<HTMLDivElement>,
  loading: boolean,
  canLoad: boolean,
  unobserve: () => void,
  callback: () => void,
) => {
  const observerRef = useRef<any>(null);

  useEffect(() => {
    const handleObserver = ([target]: IntersectionObserverEntry[]) => {
      if (target.isIntersecting && canLoad && !loading) {
        callback();
      }
    };

    observerRef.current = new IntersectionObserver(handleObserver);

    if (endOfScrollRef.current) {
      observerRef.current.observe(endOfScrollRef.current);
    }

    return () => {
      unobserve();
    };
  }, [loading, canLoad]);

  return { observerRef };
};
