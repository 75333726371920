import React, { ChangeEvent, FC, useEffect, useState, MouseEvent } from 'react';
import { Button, Drawer, Input, Image } from 'antd';
import { connect } from 'react-redux';
import { priceFormatter } from 'common/helpers/formatter.helper';
import { useDebounce } from 'common/hooks/useDebounce';
import { useCatalogContext } from 'common/hooks/useCatalogContext';
import { Counter } from 'common/components/Counter-r';
import { GoodsDeliveryBadge } from 'common/components/GoodsDeliveryBadge';
import { EPlaceholder } from 'common/const/enum';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { ReactComponent as CopyIcon } from 'app/assets/images/redesign/copy.svg';
import placeholder from 'app/assets/images/redesign/placeholder.svg';
import { RootDispatch, RootState } from 'app/store';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const GoodsCardComponent: FC<AllType> = ({ goods, basketLoading, setGoods, updateGoods }) => {
  const [vendorCodeIsFocused, setVendorCodeIsFocused] = useState<boolean>(false);
  const [vendorCodeIsChanged, setVendorCodeIsChanged] = useState<boolean>(false);
  const { count, openGoodsCard, setOpenGoodsCard, goToBasket, onCategoryChange, addGoodsToBasket, onCountChange } =
    useCatalogContext();

  const id = goods?.id;
  const categoryId = goods?.categoryId;
  const vendorCode = goods?.vendorCode;

  const onVendorCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (goods) {
      setVendorCodeIsChanged(true);
      setGoods({ ...goods, vendorCode: e.target.value });
    }
  };

  const onVendorCodeCopy = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    if (vendorCode) {
      navigator.clipboard.writeText(vendorCode);
    }
  };

  const handleCategoryClick = () => {
    setOpenGoodsCard(false);
    if (categoryId) {
      onCategoryChange(categoryId);
    }
  };

  useEffect(() => {
    if (!openGoodsCard) {
      setVendorCodeIsChanged(false);
    }
  }, [openGoodsCard]);

  useDebounce(() => {
    if (id && vendorCodeIsChanged) {
      updateGoods({ id, vendorCode: vendorCode ? vendorCode : null });
    }
  }, [vendorCodeIsChanged, vendorCode]);

  if (!goods) {
    return null;
  }

  return (
    <Drawer rootClassName="redesign goods-card-r" open={openGoodsCard} onClose={() => setOpenGoodsCard(false)} width={800}>
      <div className="goods-card-r__header">
        <span className="text-body color-dark-grey">{goods.sellerCode}</span>

        <span className="text-h4 color-bright-green">{goods.brand}</span>
      </div>

      <span className="text-h1 goods-card-r__name">{goods.name}</span>

      <div className="goods-card-r__labels-container">
        <GoodsDeliveryBadge remains={goods.remains} count={count} />
      </div>

      <div className="goods-card-r__price">
        <span className="text-h2">{priceFormatter(goods.priceWithTaxes)}</span>

        <span className="text-controls color-light-grey"> / </span>

        <span className="text-controls color-dark-grey">шт, включая НДС</span>
      </div>

      <div className="goods-card-r__actions-container">
        <div className="input-with-label" style={{ flex: 1 }}>
          <span className="text-tag input-with-label__label">Артикул клиента</span>

          <Input
            className="input-with-value"
            value={goods.vendorCode}
            onChange={onVendorCodeChange}
            placeholder={EPlaceholder.AddVendorCode}
            onFocus={() => setVendorCodeIsFocused(true)}
            onBlur={() => setVendorCodeIsFocused(false)}
            allowClear={vendorCodeIsFocused && { clearIcon: <CloseIcon /> }}
            suffix={
              <span style={{ lineHeight: 0 }}>
                {!!vendorCode?.length && !vendorCodeIsFocused && (
                  <Button className="button-icon" icon={<CopyIcon />} onClick={onVendorCodeCopy} />
                )}
              </span>
            }
          />
        </div>

        <Counter count={count} onChange={(value) => onCountChange(value, goods.goodInBasket)} />
      </div>

      <div className="goods-card-r__btn">
        {goods.goodInBasket ? (
          <Button className="button-l success w-100" onClick={goToBasket}>
            Перейти в корзину
          </Button>
        ) : (
          <Button className="button-l primary w-100" onClick={() => addGoodsToBasket(goods.id)} loading={basketLoading}>
            Добавить в корзину
          </Button>
        )}
      </div>

      <Image
        wrapperClassName="image-640"
        wrapperStyle={{ marginBottom: 72 }}
        src={goods.image ? goods.image : placeholder}
        preview={false}
      />

      <div className="goods-card-r__block">
        <div className="text-h4 goods-card-r__block-title">Характеристики</div>

        <div className="goods-card-r__block-list">
          {goods.properties.map((property) => {
            return (
              <div key={property.propertyId} className="goods-card-r__block-list-row">
                <span className="text-body color-dark-grey">{`${property.propertyName}:`}</span>

                <span className="text-body">{`${property.value} ${property.unitOfMeasurement || ''}`}</span>
              </div>
            );
          })}
        </div>
      </div>

      <div className="goods-card-r__block">
        <div className="text-h4 goods-card-r__block-title">Категории с товаром</div>

        <Button className="button-chip" onClick={handleCategoryClick}>
          {goods.categoryName}
        </Button>
      </div>
    </Drawer>
  );
};

const mapState = (state: RootState) => ({
  goods: state.goodsState.data,
  basketLoading: state.basketState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setGoods: dispatch.goodsState.setGoods,
  updateGoods: dispatch.goodsState.updateGoods,
});

export const GoodsCard = connect(mapState, mapDispatch)(GoodsCardComponent);
