import { ENeedHistoryType, ENeedStatusLabel, ESupplyCorrectionLabel, ESupplyStatusLabel } from 'common/const/enum';
import { getUniqOptions } from 'common/helpers/data.helper';
import {
  INeed,
  INeedHistoryItem,
  INeedHistoryNeedChanges,
  INeedHistorySupplyChanges,
  INeedHistorySupplyCorrectionChanges,
  INeedListState,
  INeedSubdivision,
  INeedUserListItem,
} from 'entities/Need/Need.models';
import { getUserName } from 'entities/Users/Users.helper';

export const getNeedListSubdivisionOptions = (subdivisionList: INeedSubdivision[]) => {
  return getUniqOptions(subdivisionList.map((subdivision) => ({ label: subdivision.name, value: subdivision.id })));
};

export const getNeedListUserOptions = (userList: INeedUserListItem[]) => {
  return getUniqOptions(userList.map((user) => ({ label: getUserName(user.firstName, user.lastName), value: user.id })));
};

export const updateNeedListState = {
  setNeedList: (state: INeedListState, payload: INeed[]) => ({ ...state, data: payload }),
  setNeedListCount: (state: INeedListState, payload: number) => ({ ...state, count: payload }),
  setNeedListLoading: (state: INeedListState, payload: boolean) => ({ ...state, loading: payload }),
  setNeedUserList: (state: INeedListState, payload: INeedUserListItem[]) => ({ ...state, userList: payload }),
  setNeedSubdivisionList: (state: INeedListState, payload: INeedSubdivision[]) => ({
    ...state,
    subdivisionList: payload,
  }),
  filterNeedList: (state: INeedListState, payload: number[]) => {
    return {
      ...state,
      data: state.data.filter((need) => !payload.includes(need.id)),
      count: state.count - payload.length,
    };
  },
  updateNeedList: (state: INeedListState, payload: INeed[]) => {
    return {
      ...state,
      data: state.data.map((need) => {
        const item = payload.find((needItem) => needItem.id === need.id);

        return item ? item : need;
      }),
    };
  },
};

export const getNeedHistoryItemDescription = (item: INeedHistoryItem) => {
  switch (item.type) {
    case ENeedHistoryType.ChangeSupplyNeedStatus: {
      const { oldStatus, newStatus } = item.changes as INeedHistoryNeedChanges;

      return `Изменён статус заявки: ${ENeedStatusLabel[oldStatus]} -> ${ENeedStatusLabel[newStatus]}`;
    }
    case ENeedHistoryType.CreateSupply: {
      const { newStatus } = item.changes as INeedHistorySupplyChanges;

      return `Создана новая поставка. Статус: ${ESupplyStatusLabel[newStatus]}`;
    }
    case ENeedHistoryType.ChangeSupplyStatus: {
      const { oldStatus, newStatus } = item.changes as INeedHistorySupplyChanges;

      return `Изменён статус поставки: ${ESupplyStatusLabel[oldStatus]} -> ${ESupplyStatusLabel[newStatus]}`;
    }
    case ENeedHistoryType.CreateSupplyCorrection: {
      const { reason, comment } = item.changes as INeedHistorySupplyCorrectionChanges;

      // @ts-ignore
      let content = `Запрос корректировки: ${ESupplyCorrectionLabel[reason]}.`;

      if (comment) {
        content += ` Комментарий: ${comment}`;
      }

      return content;
    }
    case ENeedHistoryType.CreateSupplyNeed: {
      return `Создана новая заявка.`;
    }
    default: {
      return '';
    }
  }
};
