import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Pagination, Select, Spin } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { PaginationProps } from 'antd';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { ContentLoader } from 'common/components/ContentLoader';
import { GoodsItem } from 'common/components/GoodsItem';
import { EMessage, ENeedStatus } from 'common/const/enum';
import { debounce } from 'common/helpers/data.helper';
import { DEFAULT_LIST_LIMIT, DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';
import { getNeedListSubdivisionOptions, getNeedListUserOptions } from 'entities/Need/Need.helper';
import { INeed, INeedListPayload } from 'entities/Need/Need.models';
import { NeedHistory } from 'entities/Need/components/NeedHistory';
import { getUserName } from 'entities/Users/Users.helper';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const CreatedNeedListForManagerComponent: React.FC<AllType> = (props) => {
  const {
    needListState,
    getNeedList,
    getNeedUserList,
    getNeedSubdivisionListForManager,
    changeNeedStatus,
    filterNeedList,
    uploadNeedExcel,
    getNeedHistoryList,
    setNeedHistoryList,
    updateNeed,
  } = props;

  const { data: needList, count: needListCount, loading: needListLoading, userList, subdivisionList } = needListState;

  const [selectedNeedIds, setSelectedNeedIds] = useState<number[]>([]);
  const [payload, setPayload] = useState<INeedListPayload>({
    statuses: [ENeedStatus.Created],
    limit: DEFAULT_LIST_LIMIT,
    offset: DEFAULT_LIST_OFFSET,
  });
  const [page, setPage] = useState<number>(DEFAULT_PAGINATION_PAGE);
  const [selectedNeed, setSelectedNeed] = useState<INeed | null>(null);

  const checkAll = !!needList.length && needList.length === selectedNeedIds.length;
  const subdivisionOptions = getNeedListSubdivisionOptions(subdivisionList);
  const userOptions = getNeedListUserOptions(userList);
  const debouncedUpdateNeed = debounce(updateNeed);

  const onSubdivisionChange = async (value?: number) => {
    const newPayload = {
      ...payload,
      subdivisionIds: value ? [value] : undefined,
      userId: undefined,
      offset: DEFAULT_LIST_OFFSET,
    };

    setPayload(newPayload);
    await getNeedList(newPayload);
    await getNeedUserList({ subdivisionId: value, statuses: payload.statuses });
    setSelectedNeedIds([]);
    setSelectedNeed(null);
    setNeedHistoryList([]);
    setPage(DEFAULT_PAGINATION_PAGE);
  };

  const onUserChange = async (value: number | undefined) => {
    const newPayload = { ...payload, userId: value, offset: DEFAULT_LIST_OFFSET };

    setPayload(newPayload);
    await getNeedList(newPayload);
    setSelectedNeedIds([]);
    setSelectedNeed(null);
    setNeedHistoryList([]);
    setPage(DEFAULT_PAGINATION_PAGE);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    const checkedList = needList.map((need) => need.id);
    setSelectedNeedIds(e.target.checked ? checkedList : []);
  };

  const onGetToWorkClick = async () => {
    await changeNeedStatus({ ids: selectedNeedIds, status: ENeedStatus.Pending });
    filterNeedList(selectedNeedIds);
    setSelectedNeedIds([]);

    if (selectedNeedIds.includes(selectedNeed?.id as number)) {
      setSelectedNeed(null);
      setNeedHistoryList([]);
    }

    if (selectedNeedIds.length === needList.length) {
      const newPayload = { ...payload, offset: DEFAULT_LIST_OFFSET };

      setPayload(newPayload);
      await getNeedList(newPayload);
      setPage(DEFAULT_PAGINATION_PAGE);
    }
  };

  const onRejectClick = async () => {
    await changeNeedStatus({ ids: selectedNeedIds, status: ENeedStatus.Cancelled });
    filterNeedList(selectedNeedIds);
    setSelectedNeedIds([]);

    if (selectedNeedIds.includes(selectedNeed?.id as number)) {
      setSelectedNeed(null);
      setNeedHistoryList([]);
    }

    if (selectedNeedIds.length === needList.length) {
      const newPayload = { ...payload, offset: DEFAULT_LIST_OFFSET };

      setPayload(newPayload);
      await getNeedList(newPayload);
      setPage(DEFAULT_PAGINATION_PAGE);
    }
  };

  const onCountChange = (id: number, count: number) => {
    debouncedUpdateNeed({ supplyNeeds: [{ id, count }] });
  };

  const onPageChange: PaginationProps['onChange'] = async (value) => {
    const newPayload = { ...payload, offset: (value - 1) * DEFAULT_LIST_LIMIT };

    setPayload(newPayload);
    await getNeedList(newPayload);
    setSelectedNeedIds([]);
    setPage(value);
  };

  const onNeedClick = (id: number) => {
    const selectedNeed = needList.find((need) => need.id === id);

    setSelectedNeed(selectedNeed as INeed);
    getNeedHistoryList({ supplyNeedId: id, limit: DEFAULT_LIST_LIMIT });
  };

  useEffect(() => {
    return () => {
      setNeedHistoryList([]);
    };
  }, []);

  const { contentLoading } = useContentLoader(async () => {
    await getNeedList(payload);
    await getNeedUserList({ statuses: payload.statuses });
    await getNeedSubdivisionListForManager({ statuses: payload.statuses });
  });

  if (contentLoading) {
    return (
      <div className="need-list">
        <ContentLoader />
      </div>
    );
  }

  return (
    <div className="need-list">
      <div className="need-list__content">
        <div className="need-list__filter">
          <div className="need-list__filter-item">
            <span className="need-list__filter-item-label">Подразделение</span>

            <Select
              suffixIcon={<ChevronIcon />}
              value={payload.subdivisionIds?.[0]}
              options={subdivisionOptions}
              popupMatchSelectWidth={false}
              placeholder="Выберите подразделение"
              allowClear
              onChange={onSubdivisionChange}
            />
          </div>

          <div className="need-list__filter-item">
            <span className="need-list__filter-item-label">Пользователь</span>

            <Select
              suffixIcon={<ChevronIcon />}
              value={payload.userId}
              options={userOptions}
              popupMatchSelectWidth={false}
              placeholder="Выберите пользователя"
              allowClear
              onChange={onUserChange}
            />
          </div>

          <Button className="btn btn-default" onClick={() => uploadNeedExcel(payload)}>
            Выгрузить в эксель
          </Button>
        </div>

        <div className="need-list__btn-container">
          <Checkbox checked={checkAll} onChange={onCheckAllChange}>
            Выделить все / Снять выделение
          </Checkbox>

          <Button className="btn btn-primary" onClick={onGetToWorkClick} disabled={!selectedNeedIds.length}>
            Взять в работу
          </Button>

          <Button className="btn btn-red" onClick={onRejectClick} disabled={!selectedNeedIds.length}>
            Отклонить выделенные заявки
          </Button>
        </div>

        <Spin spinning={needListLoading}>
          <>
            {needList.length ? (
              needList.map((need) => {
                const checked = selectedNeedIds.includes(need.id);

                return (
                  <GoodsItem
                    key={need.id}
                    id={need.id}
                    options={{
                      canBeSelected: true,
                      showVendorCode: true,
                      showCounter: true,
                      showTotalAmount: true,
                      showDeliveryDate: true,
                      showUserName: true,
                      showSubdivisionName: true,
                      checked,
                      image: need.good.image,
                      vendorCode: need.good.vendorCode,
                      count: need.count,
                      price: need.good.price,
                      deliveryDate: need.deliveryDate,
                      name: need.good.name,
                      userName: getUserName(need.user.firstName, need.user.lastName),
                      subdivisionName: need.subdivisionName,
                    }}
                    changeChecked={(id, value) => {
                      setSelectedNeedIds((prev) => (value ? [...prev, id] : prev.filter((item) => item !== id)));
                    }}
                    changeCount={onCountChange}
                    onItemClick={onNeedClick}
                  />
                );
              })
            ) : (
              <div className="need-list__empty">{EMessage.RequestListIsEmpty}</div>
            )}

            <Pagination total={needListCount} current={page} pageSize={DEFAULT_LIST_LIMIT} onChange={onPageChange} />
          </>
        </Spin>
      </div>

      <NeedHistory need={selectedNeed} />
    </div>
  );
};

const mapState = (state: RootState) => ({
  needListState: state.needListState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getNeedList: dispatch.needListState.getNeedList,
  getNeedUserList: dispatch.needListState.getNeedUserList,
  getNeedSubdivisionListForManager: dispatch.needListState.getNeedSubdivisionListForManager,
  changeNeedStatus: dispatch.needListState.changeNeedStatus,
  filterNeedList: dispatch.needListState.filterNeedList,
  uploadNeedExcel: dispatch.needListState.uploadNeedExcel,
  getNeedHistoryList: dispatch.needHistoryListState.getNeedHistoryList,
  setNeedHistoryList: dispatch.needHistoryListState.setNeedHistoryList,
  updateNeed: dispatch.needState.updateNeed,
});

export const CreatedNeedListForManager = connect(mapState, mapDispatch)(CreatedNeedListForManagerComponent);
