import React, { FC, useState } from 'react';
import { Button, Checkbox, Spin } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ContentLoader } from 'common/components/ContentLoader';
import { GoodsItem } from 'common/components/GoodsItem';
import { EMessage, ENotificationType, EStorageKey } from 'common/const/enum';
import { removeStorageItem, saveStorageItem } from 'common/helpers/axios.helper';
import { getGoodsDeliveryTime, sortRemainsByDeliveryInDays } from 'common/helpers/delivery.helper';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { useDebounce } from 'common/hooks/useDebounce';
import { useCategoryClick } from 'common/hooks/useCategoryClick';
import { DEFAULT_DELIVERY_IN_DAYS, DEFAULT_GOODS_COUNT } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as CloseIcon } from 'app/assets/images/close.svg';
import { IBasketGoods } from 'entities/Basket/Basket.models';
import { basketGoodsToPayloadGoods, getGoodsWithIncreasedPriceLimitModalDescription } from 'entities/Basket/Basket.helper';
import { BasketSummary } from 'entities/Basket/components/BasketSummary';
import { IGoods } from 'entities/Goods/Goods.models';
import { CreateGoodsFormModal } from 'entities/Modal/components/CreateGoodsFormModal';
import { QuicklyAddGoodsModal } from 'entities/Modal/components/QuicklyAddGoodsModal';
import { Notification } from 'entities/Modal/components/Notification';
import { GoodsCard } from 'entities/Modal/components/GoodsCard';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const ActiveBasketComponent: FC<AllType> = (props) => {
  const {
    categoryListState,
    authState,
    basketState,
    goodsState,
    getCategoryList,
    getBasketById,
    updateBasket,
    uploadBasketExcel,
    createBasketRequest,
    getGoods,
    createGoods,
    getGoodsForNavigation,
    getCategoryById,
    setAuthUserCatalogId,
    setAuthXBasketId,
  } = props;

  const { data: categoryList } = categoryListState;
  const { data: auth, xBasketId } = authState;
  const { data: basket, loading: basketLoading } = basketState;
  const { data: goods, loading: goodsLoading } = goodsState;

  const catalogId = basket?.defaultCatalogId;

  const [goodsList, setGoodsList] = useState<IBasketGoods[]>([]);
  const [goodsWithIncreasedPriceLimit, setGoodsWithIncreasedPriceLimit] = useState<IBasketGoods[]>([]);
  const [goodsId, setGoodsId] = useState<number | null>(null);
  const [selectedGoodsIds, setSelectedGoodsIds] = useState<number[]>([]);
  const [goodsCountIsChanged, setGoodsCountIsChanged] = useState<boolean>(false);
  const [requestLoading, setRequestLoading] = useState<boolean>(false);
  const [openGoodsCard, setOpenGoodsCard] = useState<boolean>(false);
  const [openQuicklyAddGoodsModal, setOpenQuicklyAddGoodsModal] = useState<boolean>(false);
  const [openCreateGoodsModal, setOpenCreateGoodsModal] = useState<boolean>(false);
  const [openDeleteGoodsModal, setOpenDeleteGoodsModal] = useState<boolean>(false);
  const [openGoodsWithIncreasedPriceLimitModal, setOpenGoodsWithIncreasedPriceLimitModal] = useState<boolean>(false);
  const { id } = useParams();
  const { onCategoryClick } = useCategoryClick(getGoodsForNavigation, getCategoryList, getCategoryById, catalogId);

  const roles = auth?.access.roles;
  const isCurrentBasket = xBasketId === basket?.id;
  const checkAll = !!goodsList.length && goodsList.length === selectedGoodsIds.length;
  const goodsWithIncreasedPriceLimitModalDescription =
    getGoodsWithIncreasedPriceLimitModalDescription(goodsWithIncreasedPriceLimit);

  const onQuicklyAddGoodsModalAddToBasketClick = (goods: IGoods) => {
    if (basket) {
      const newGoods = [
        ...goodsList,
        { ...goods, goodId: goods.id, count: DEFAULT_GOODS_COUNT, deliveryInDays: DEFAULT_DELIVERY_IN_DAYS } as IBasketGoods,
      ];

      updateBasket({
        id: basket.id,
        goods: basketGoodsToPayloadGoods(newGoods),
        onSuccess: () => {
          setGoodsList(newGoods);
          setOpenQuicklyAddGoodsModal(false);
        },
      });
    }
  };

  const onQuicklyAddGoodsModalAddGoodsToDatabaseClick = async () => {
    setOpenQuicklyAddGoodsModal(false);
    await getCategoryList({ catalogId, limit: 0 });
    setOpenCreateGoodsModal(true);
  };

  const linkBasket = () => {
    if (basket) {
      saveStorageItem(EStorageKey.XBasketId, basket.id);
      saveStorageItem(EStorageKey.UserCatalogId, basket.defaultCatalogId as number);
      setAuthXBasketId(basket.id);
      setAuthUserCatalogId(basket.defaultCatalogId as number);
    }
  };

  const unlinkBasket = () => {
    removeStorageItem(EStorageKey.XBasketId);
    removeStorageItem(EStorageKey.UserCatalogId);
    setAuthXBasketId(null);
    setAuthUserCatalogId(null);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    const checkedList = goodsList.map((goods) => goods.goodId);
    setSelectedGoodsIds(e.target.checked ? checkedList : []);
  };

  const onDeleteGoodsModalConfirmClick = () => {
    if (basket && selectedGoodsIds.length) {
      const filteredGoods = goodsList.filter((goods) => !selectedGoodsIds.includes(goods.goodId));

      updateBasket({
        id: basket.id,
        goods: basketGoodsToPayloadGoods(filteredGoods),
        onSuccess: () => {
          setGoodsList(filteredGoods);
          setSelectedGoodsIds([]);
          setOpenDeleteGoodsModal(false);
        },
      });
    }
  };

  const onGoodsCountChange = (id: number, count: number) => {
    setGoodsCountIsChanged(true);

    const newGoodsList = goodsList.map((goods) => {
      if (goods.goodId === id) {
        return { ...goods, count };
      }

      return goods;
    });

    setGoodsList(newGoodsList);
  };

  const onGoodsClick = (id: number) => {
    setGoodsId(id);
    getGoods({ id, onSuccess: () => setOpenGoodsCard(true) });
  };

  const onGoodsCardCancelClick = () => {
    setGoodsId(null);
    setOpenGoodsCard(false);
  };

  const onGoodsDeleteClick = (id: number) => {
    if (basket) {
      const filteredGoods = goodsList.filter((goods) => goods.goodId !== id);
      const filteredSelectedGoodsIds = selectedGoodsIds.filter((goodsId) => goodsId !== id);

      updateBasket({
        id: basket.id,
        goods: basketGoodsToPayloadGoods(filteredGoods),
        onSuccess: () => {
          setGoodsList(filteredGoods);
          setSelectedGoodsIds(filteredSelectedGoodsIds);
          onGoodsCardCancelClick();
        },
      });
    }
  };

  const onGoodsCardCategoryClick = async (id: number) => {
    await onCategoryClick(id);
    setOpenGoodsCard(false);
  };

  const onGoodsWithIncreasedPriceLimitModalConfirmClick = async () => {
    const filteredGoodsList = goodsList.filter((goods) => {
      return !goodsWithIncreasedPriceLimit.some((item) => item.goodId === goods.goodId);
    });
    const filteredSelectedGoodsIds = selectedGoodsIds.filter((goodsId) => {
      return !goodsWithIncreasedPriceLimit.some((item) => item.goodId === goodsId);
    });

    if (basket) {
      await updateBasket({
        id: basket.id,
        goods: basketGoodsToPayloadGoods(filteredGoodsList),
        onSuccess: () => {
          setGoodsList(filteredGoodsList);
          setSelectedGoodsIds(filteredSelectedGoodsIds);
          setGoodsWithIncreasedPriceLimit([]);
          setOpenGoodsWithIncreasedPriceLimitModal(false);
        },
      });
    }
  };

  const onSendRequestClick = async () => {
    const goodsWithIncreasedPriceLimit = goodsList.filter((goods) => {
      return goods.price > Number(basket?.maxGoodPrice) && selectedGoodsIds.includes(goods.goodId);
    });
    const selectedGoods = goodsList.filter((goods) => selectedGoodsIds.includes(goods.goodId));
    const filteredGoods = goodsList.filter((goods) => !selectedGoodsIds.includes(goods.goodId));

    if (goodsWithIncreasedPriceLimit.length) {
      setGoodsWithIncreasedPriceLimit(goodsWithIncreasedPriceLimit);
      setOpenGoodsWithIncreasedPriceLimitModal(true);
      return;
    }

    setRequestLoading(true);

    await createBasketRequest({
      goods: basketGoodsToPayloadGoods(selectedGoods),
      onSuccess: () => {
        setGoodsList(filteredGoods);
        setSelectedGoodsIds([]);
      },
    });

    setRequestLoading(false);
  };

  useDebounce(() => {
    if (basket && goodsCountIsChanged) {
      updateBasket({
        id: basket.id,
        goods: basketGoodsToPayloadGoods(goodsList),
        onSuccess: () => {
          setGoodsCountIsChanged(false);
        },
      });
    }
  }, [basket, goodsList, goodsCountIsChanged]);

  const { contentLoading } = useContentLoader(async () => {
    if (id) {
      const basket = await getBasketById(Number(id));

      if (basket) {
        setGoodsList(basket.goods);
      }
    }
  });

  if (contentLoading) {
    return (
      <div className="basket">
        <ContentLoader />
      </div>
    );
  }

  return (
    <div className="basket">
      <div className="basket__container">
        <div className="basket__content">
          <span className="basket__title">Корзина товаров</span>

          <div className="basket__btn-container">
            <Button className="btn btn-default" onClick={() => setOpenQuicklyAddGoodsModal(true)}>
              Быстрое добавление товара
            </Button>

            <Button className="btn btn-default" onClick={() => uploadBasketExcel(basket?.id as number)}>
              Экспорт в Excel
            </Button>

            {!isCurrentBasket ? (
              <Button className="btn btn-primary" onClick={linkBasket}>
                Сделать текущей
              </Button>
            ) : (
              <Button className="btn btn-red" icon={<CloseOutlined />} onClick={unlinkBasket}>
                Отвязать
              </Button>
            )}
          </div>

          <div className="basket__btn-container">
            <Checkbox checked={checkAll} onChange={onCheckAllChange}>
              Выделить все / Снять выделение
            </Checkbox>

            <Button
              className="basket__remove-all"
              icon={<CloseIcon />}
              onClick={() => setOpenDeleteGoodsModal(true)}
              disabled={!selectedGoodsIds.length}
            >
              Удалить выделенное
            </Button>
          </div>

          <Spin spinning={goodsLoading || requestLoading}>
            {goodsList.length ? (
              goodsList.map((goods) => {
                const checked = selectedGoodsIds.includes(goods.goodId);
                const remains = sortRemainsByDeliveryInDays(goods.remains);
                const { deliveryTime } = getGoodsDeliveryTime(goods.count, remains);

                return (
                  <GoodsItem
                    key={goods.goodId}
                    id={goods.goodId}
                    options={{
                      canBeSelected: true,
                      showVendorCode: true,
                      showCounter: true,
                      showTotalAmount: true,
                      showDeliveryTime: true,
                      showDeleteBtn: true,
                      checked,
                      image: goods.image,
                      vendorCode: goods.vendorCode,
                      count: goods.count,
                      price: goods.price,
                      deliveryTime,
                      name: goods.name,
                      isUnavailable: !goods.isAvailableForCustomer,
                    }}
                    changeChecked={(id, value) => {
                      if (value) {
                        setSelectedGoodsIds((prev) => [...prev, id]);
                      } else {
                        setSelectedGoodsIds((prev) => prev.filter((item) => item !== id));
                      }
                    }}
                    changeCount={onGoodsCountChange}
                    onDelete={onGoodsDeleteClick}
                    onItemClick={onGoodsClick}
                  />
                );
              })
            ) : (
              <div className="basket__empty">{EMessage.BasketIsEmpty}</div>
            )}
          </Spin>
        </div>

        <BasketSummary
          basket={basket}
          selectedGoodsIds={selectedGoodsIds}
          goodsList={goodsList}
          readOnly={true}
          isCurrentBasket={isCurrentBasket}
          onSendRequestClick={onSendRequestClick}
        />
      </div>

      <QuicklyAddGoodsModal
        open={openQuicklyAddGoodsModal}
        loading={basketLoading}
        userRoles={roles}
        goodsInBasketIds={goodsList.map((goods) => goods.goodId)}
        onCancelClick={() => setOpenQuicklyAddGoodsModal(false)}
        onAddToBasketClick={onQuicklyAddGoodsModalAddToBasketClick}
        onAddGoodsToDatabaseClick={onQuicklyAddGoodsModalAddGoodsToDatabaseClick}
      />

      <CreateGoodsFormModal
        open={openCreateGoodsModal}
        loading={goodsLoading}
        categoryList={categoryList}
        onCancel={() => setOpenCreateGoodsModal(false)}
        onSubmit={createGoods}
      />

      <Notification
        open={openDeleteGoodsModal}
        type={ENotificationType.Warning}
        description={EMessage.SelectedGoodsWillBeRemovedFromActiveBasket}
        loading={basketLoading}
        onCancelClick={() => setOpenDeleteGoodsModal(false)}
        onConfirmClick={onDeleteGoodsModalConfirmClick}
      />

      <GoodsCard
        open={openGoodsCard}
        goods={goods}
        count={goodsList.find((item) => item.goodId === goodsId)?.count as number}
        loading={basketLoading}
        readOnly
        onCancel={onGoodsCardCancelClick}
        onDelete={onGoodsDeleteClick}
        onCategoryClick={onGoodsCardCategoryClick}
      />

      <Notification
        open={openGoodsWithIncreasedPriceLimitModal}
        type={ENotificationType.Warning}
        description={goodsWithIncreasedPriceLimitModalDescription}
        loading={basketLoading}
        confirmTitle="Удалить"
        onCancelClick={() => setOpenGoodsWithIncreasedPriceLimitModal(false)}
        onConfirmClick={onGoodsWithIncreasedPriceLimitModalConfirmClick}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  categoryListState: state.categoryListState,
  authState: state.authState,
  basketState: state.basketState,
  goodsState: state.goodsState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getCategoryList: dispatch.categoryListState.getCategoryList,
  getBasketById: dispatch.basketState.getBasketById,
  updateBasket: dispatch.basketState.updateBasket,
  uploadBasketExcel: dispatch.basketState.uploadBasketExcel,
  createBasketRequest: dispatch.basketState.createBasketRequest,
  getGoods: dispatch.goodsState.getGoods,
  createGoods: dispatch.goodsState.createGoods,
  getGoodsForNavigation: dispatch.goodsForNavigationState.getGoodsForNavigation,
  getCategoryById: dispatch.categoryState.getCategoryById,
  setAuthUserCatalogId: dispatch.authState.setAuthUserCatalogId,
  setAuthXBasketId: dispatch.authState.setAuthXBasketId,
});

export const ActiveBasket = connect(mapState, mapDispatch)(ActiveBasketComponent);
