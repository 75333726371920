import { useEffect } from 'react';
import { DEFAULT_DEBOUNCE_DELAY } from 'common/config';

export const useDebounce = (callback: () => void, depth: any[]) => {
  useEffect(() => {
    const submitForm = setTimeout(callback, DEFAULT_DEBOUNCE_DELAY);

    return () => clearTimeout(submitForm);
  }, [...depth]);
};
