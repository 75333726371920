import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Input, Pagination, Select, Spin } from 'antd';
import type { PaginationProps } from 'antd';
import { EMessage, ENeedStatus, ENeedStatusLabel, ESupplyStatus } from 'common/const/enum';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { ContentLoader } from 'common/components/ContentLoader';
import { useDebounce } from 'common/hooks/useDebounce';
import { GoodsItem } from 'common/components/GoodsItem';
import { DEFAULT_LIST_LIMIT, DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as SearchIcon } from 'app/assets/images/search.svg';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';
import { INeed, INeedListPayload } from 'entities/Need/Need.models';
import { NeedHistory } from 'entities/Need/components/NeedHistory';
import { SupplySchedule } from 'entities/Supply/components/SupplySchedule';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const CreatedNeedListForUserComponent: React.FC<AllType> = (props) => {
  const {
    authState,
    needListState,
    getBasket,
    getNeedList,
    getSupplyList,
    setSupplyList,
    getNeedHistoryList,
    setNeedHistoryList,
  } = props;

  const { data: auth } = authState;
  const { data: needList, count: needListCount, loading: needListLoading } = needListState;

  const userId = auth?.access.userId;

  const [payload, setPayload] = useState<INeedListPayload>({
    userId,
    search: '',
    statuses: [ENeedStatus.Created],
    limit: DEFAULT_LIST_LIMIT,
    offset: DEFAULT_LIST_OFFSET,
  });
  const [page, setPage] = useState<number>(DEFAULT_PAGINATION_PAGE);
  const [selectedNeed, setSelectedNeed] = useState<INeed | null>(null);

  const statusOptions = Object.values(ENeedStatus).map((status) => ({
    label: ENeedStatusLabel[status],
    value: status,
  }));

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newPayload = { ...payload, search: e.target.value, offset: DEFAULT_LIST_OFFSET };

    setPayload(newPayload);
  };

  const onStatusChange = async (value: ENeedStatus) => {
    const newPayload = { ...payload, statuses: [value], offset: DEFAULT_LIST_OFFSET };

    setPayload(newPayload);
    await getNeedList(newPayload);
    setSelectedNeed(null);
    setSupplyList([]);
    setNeedHistoryList([]);
    setPage(DEFAULT_PAGINATION_PAGE);
  };

  const onPageChange: PaginationProps['onChange'] = async (value) => {
    const newPayload = { ...payload, offset: (value - 1) * DEFAULT_LIST_LIMIT };

    setPayload(newPayload);
    await getNeedList(newPayload);
    setSelectedNeed(null);
    setSupplyList([]);
    setNeedHistoryList([]);
    setPage(value);
  };

  const onNeedClick = async (id: number) => {
    const selectedNeed = needList.find((need) => need.id === id);

    setSelectedNeed(selectedNeed as INeed);

    if (!payload.statuses?.includes(ESupplyStatus.Created)) {
      await getSupplyList({ supplyNeedId: id, orderDirection: 'ASC' });
    }

    await getNeedHistoryList({ supplyNeedId: id, limit: DEFAULT_LIST_LIMIT });
  };

  useEffect(() => {
    return () => {
      setSupplyList([]);
      setNeedHistoryList([]);
    };
  }, []);

  useDebounce(async () => {
    await getNeedList(payload);
    setSelectedNeed(null);
    setSupplyList([]);
    setNeedHistoryList([]);
    setPage(DEFAULT_PAGINATION_PAGE);
  }, [payload.search]);

  const { contentLoading } = useContentLoader(async () => {
    await getBasket();
    await getNeedList(payload);
  });

  if (contentLoading) {
    return (
      <div className="need-list">
        <ContentLoader />
      </div>
    );
  }

  return (
    <div className="need-list">
      <div className="need-list__content">
        <div className="need-list__filter">
          <Input
            className="need-list__filter-search"
            prefix={<SearchIcon />}
            placeholder="Search"
            value={payload.search}
            onChange={onSearchChange}
          />

          <div className="need-list__filter-status">
            <span>Статус:</span>

            <Select
              suffixIcon={<ChevronIcon />}
              value={payload.statuses?.[0] as ENeedStatus}
              options={statusOptions}
              popupMatchSelectWidth={false}
              onChange={onStatusChange}
            />
          </div>
        </div>

        <Spin spinning={needListLoading}>
          <>
            {needList.length ? (
              needList.map((need) => {
                return (
                  <GoodsItem
                    key={need.id}
                    id={need.id}
                    options={{
                      showNeedStatus: true,
                      showPendingSuppliesCount: true,
                      image: need.good.image,
                      status: need.status,
                      count: need.count,
                      pendingSuppliesCount: need.pendingSuppliesCount,
                      name: need.good.name,
                    }}
                    onItemClick={onNeedClick}
                  />
                );
              })
            ) : (
              <div className="need-list__empty">{EMessage.RequestListIsEmpty}</div>
            )}

            <Pagination total={needListCount} current={page} pageSize={DEFAULT_LIST_LIMIT} onChange={onPageChange} />
          </>
        </Spin>
      </div>

      <div>
        {!payload.statuses?.includes(ESupplyStatus.Created) && <SupplySchedule need={selectedNeed} />}

        <NeedHistory need={selectedNeed} />
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  authState: state.authState,
  needListState: state.needListState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getBasket: dispatch.basketState.getBasket,
  getNeedList: dispatch.needListState.getNeedList,
  getSupplyList: dispatch.supplyListState.getSupplyList,
  setSupplyList: dispatch.supplyListState.setSupplyList,
  getNeedHistoryList: dispatch.needHistoryListState.getNeedHistoryList,
  setNeedHistoryList: dispatch.needHistoryListState.setNeedHistoryList,
});

export const CreatedNeedListForUser = connect(mapState, mapDispatch)(CreatedNeedListForUserComponent);
