import React, { useState } from 'react';
import { Button, Select, Tooltip } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { IOption } from 'common/models';
import { ReactComponent as CloseIcon } from 'app/assets/images/close.svg';
import { getBasketDeliveryAddressAlertTitle } from 'entities/Basket/Basket.helper';

interface IComponentProps {
  subdivisionId?: number;
  options?: IOption[];
  addressId?: number;
  addressName?: string;
  readOnly: boolean;
  onChange: (id: number) => void;
}

export const BasketDeliveryAddress: React.FC<IComponentProps> = (props) => {
  const { subdivisionId, options, addressId, addressName, readOnly, onChange } = props;

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const alertTitle = getBasketDeliveryAddressAlertTitle(subdivisionId, options?.length, addressId);

  const toggleIsEdit = () => setIsEdit((prev) => !prev);

  const onAddressChange = (value: number) => {
    if (value) {
      onChange(value);
    }

    toggleIsEdit();
  };

  return (
    <div className="basket-delivery-address">
      <div>
        <span className="basket-delivery-address__title">Адрес доставки</span>

        {alertTitle && !readOnly && (
          <Tooltip overlayClassName="basket-delivery-address__tooltip" title={alertTitle}>
            <InfoCircleFilled />
          </Tooltip>
        )}
      </div>

      {!isEdit ? (
        <div className="basket-delivery-address__value">
          <span>{addressName}</span>

          {!readOnly && (
            <Button type="link" onClick={toggleIsEdit}>
              Изменить
            </Button>
          )}
        </div>
      ) : (
        <Select
          value={addressId}
          onChange={onAddressChange}
          className="basket-delivery-address__select"
          popupClassName="basket-delivery-address__select-dropdown"
          options={options}
          suffixIcon={false}
          allowClear={{ clearIcon: <CloseIcon /> }}
        />
      )}
    </div>
  );
};
