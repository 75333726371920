import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRootModel } from 'app/store';
import {
  ISearchSuggestionsCategory,
  ISearchSuggestionsGoods,
  ISearchSuggestionsPayload,
  ISearchSuggestionsState,
} from 'entities/Search/Search.models';
import { searchTransport } from 'entities/Search/Search.transport';

export const searchSuggestionsState = createModel<IRootModel>()({
  state: {
    data: {
      categories: [],
      goods: [],
    },
    loading: false,
    goodsCount: 0,
  } as ISearchSuggestionsState,
  reducers: {
    setSearchSuggestionsCategories: (state, payload: ISearchSuggestionsCategory[]) => ({
      ...state,
      data: { ...state.data, categories: payload },
    }),
    setSearchSuggestionsGoods: (state, payload: ISearchSuggestionsGoods[]) => ({
      ...state,
      data: { ...state.data, goods: payload },
    }),
    setSearchSuggestionsLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    setSearchSuggestionsGoodsCount: (state, payload: number) => ({ ...state, goodsCount: payload }),
    resetSearchSuggestions: (state) => ({ ...state, data: { categories: [], goods: [] }, goodsCount: 0 }),
  },
  effects: (dispatch) => ({
    async getSearchSuggestions(payload: ISearchSuggestionsPayload) {
      dispatch.searchSuggestionsState.setSearchSuggestionsLoading(true);

      try {
        const response = await searchTransport.getSearchSuggestions(payload);
        dispatch.searchSuggestionsState.setSearchSuggestionsCategories(response.categories);
        dispatch.searchSuggestionsState.setSearchSuggestionsGoods(response.goods);
        dispatch.searchSuggestionsState.setSearchSuggestionsGoodsCount(response.totalGoodsCount);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.searchSuggestionsState.setSearchSuggestionsLoading(false);
      }
    },
  }),
});
