import React from 'react';

interface IComponentProps {
  size: number;
  firstName?: string;
  lastName?: string;
}

export const Avatar: React.FC<IComponentProps> = ({ size, firstName, lastName }) => (
  <div style={{ width: `${size}px`, height: `${size}px` }} className="avatar">
    {`${firstName?.[0] || ''}${lastName?.[0] || ''}`}
  </div>
);
