import { createModel } from '@rematch/core';
import { destroyMessage, showLoadingMessage, showSuccessMessage } from 'common/helpers/message.helper';
import { IRequestSuccess } from 'common/models';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRootModel } from 'app/store';
import { usersTransport } from 'entities/Users/Users.transport';
import {
  IUserListState,
  IUserListParams,
  IUser,
  IUserState,
  IUserChangePasswordPayload,
  IUserDeletePayload,
  IUserRemoveSubdivisionPayload,
  IUserUpdateByAdminPayload,
  IUserUpdatePayload,
  IUserStatisticsState,
  IUserStatistics,
} from 'entities/Users/Users.models';
import { updateUserListState } from 'entities/Users/Users.helper';

export const userListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as IUserListState,
  reducers: {
    setUserList: (state, payload: IUser[]) => ({ ...state, data: payload }),
    setUserListLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    addUser: updateUserListState.addUser,
    updateUser: updateUserListState.updateUser,
    deleteUser: updateUserListState.deleteUser,
  },
  effects: (dispatch) => ({
    async getUserList(params: IUserListParams) {
      dispatch.userListState.setUserListLoading(true);

      try {
        const response = await usersTransport.getUserList(params);
        dispatch.userListState.setUserList(response.data);
        return response.data;
      } catch (error) {
        axiosErrorHandler(error);
        return null;
      } finally {
        dispatch.userListState.setUserListLoading(false);
      }
    },
  }),
});

export const userState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
    error: null,
  } as IUserState,
  reducers: {
    setUser: (state, payload: IUser) => ({ ...state, data: payload }),
    setUserLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    setUserError: (state, payload: string | null) => ({ ...state, error: payload }),
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    async getUserById(id: number) {
      dispatch.userState.setUserLoading(true);

      try {
        const response = await usersTransport.getUserById(id);
        dispatch.userState.setUser(response);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.userState.setUserLoading(false);
      }
    },
    async updateUser(payload: IUserUpdatePayload) {
      showLoadingMessage(0);

      try {
        const response = await usersTransport.updateUser(payload);
        dispatch.userState.setUser(response);
        showSuccessMessage();
      } catch (error) {
        destroyMessage();
        axiosErrorHandler(error);
      }
    },
    async updateUserByAdmin(payload: IUserUpdateByAdminPayload) {
      dispatch.userState.setUserLoading(true);

      try {
        const response = await usersTransport.updateUserByAdmin(payload);
        dispatch.userListState.updateUser(response);
        dispatch.userState.setUser(response);
        dispatch.userState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.userState.setUserLoading(false);
      }
    },
    async deleteUser(payload: IUserDeletePayload) {
      dispatch.userState.setUserLoading(true);

      try {
        const response = await usersTransport.deleteUser(payload);
        dispatch.userListState.deleteUser(response.id);
        dispatch.userState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.userState.setUserLoading(false);
      }
    },
    async removeUserSubdivision(payload: IUserRemoveSubdivisionPayload) {
      dispatch.userState.setUserLoading(true);

      try {
        await usersTransport.removeUserSubdivision(payload);
        dispatch.userState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.userState.setUserLoading(false);
      }
    },
    async changeUserPassword(payload: IUserChangePasswordPayload) {
      dispatch.userState.setUserLoading(true);

      try {
        const response = await usersTransport.changeUserPassword(payload);
        dispatch.userState.setUser(response);
        dispatch.userState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error, dispatch.userState.setUserError);
      } finally {
        dispatch.userState.setUserLoading(false);
      }
    },
  }),
});

export const accountUserListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as IUserListState,
  reducers: {
    setAccountUserList: (state, payload: IUser[]) => ({ ...state, data: payload }),
    setAccountUserListLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getAccountUserList(params: IUserListParams) {
      dispatch.accountUserListState.setAccountUserListLoading(true);

      try {
        const response = await usersTransport.getUserList(params);
        dispatch.accountUserListState.setAccountUserList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.accountUserListState.setAccountUserListLoading(false);
      }
    },
  }),
});

export const statisticsState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: true,
  } as IUserStatisticsState,
  reducers: {
    setStatistics: (state, payload: IUserStatistics) => ({ ...state, data: payload }),
    setStatisticsLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getUserStatistics() {
      dispatch.statisticsState.setStatisticsLoading(true);

      try {
        const response = await usersTransport.getUserStatistics();
        dispatch.statisticsState.setStatistics(response);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.statisticsState.setStatisticsLoading(false);
      }
    },
  }),
});
