import React, { FC } from 'react';
import { Button, Dropdown } from 'antd';
import { ESupplyStatus } from 'common/const/enum';
import { countFormatter, priceFormatter } from 'common/helpers/formatter.helper';
import { ISupply } from 'entities/Supply/Supply.models';
import { getSupplyChangeStatusMenuItems, getSupplyOrderInfo, getSupplyStatus } from 'entities/Supply/Supply.helper';

interface IComponentProps {
  selectedList: ISupply[];
  status?: string;
  onStatusChange: (value: ESupplyStatus) => void;
}

export const SupplyListSummary: FC<IComponentProps> = (props) => {
  const { selectedList, status, onStatusChange } = props;

  const { selectedItemCount, selectedItemAmount, vat, selectedItemAmountWithVat } = getSupplyOrderInfo(selectedList);
  const items = getSupplyChangeStatusMenuItems(onStatusChange, status);
  const { supplyPending, supplyApproved, supplyPlaced, supplyInProcess, supplyInDelivery, supplyDelivered } =
    getSupplyStatus(status);

  return (
    <div className="supply-list-summary">
      <div className="supply-list-summary__label">Информация о заказе</div>

      <div className="supply-list-summary__container">
        <div className="supply-list-summary__item">
          <span>Выбрано товаров:</span>

          <span className="supply-list-summary__item-value">{countFormatter(selectedItemCount)}</span>
        </div>

        <div className="supply-list-summary__item">
          <span>Стоимость товаров:</span>

          <span className="supply-list-summary__item-value">{priceFormatter(selectedItemAmount)}</span>
        </div>

        <div className="supply-list-summary__item">
          <span>Сумма НДС:</span>

          <span className="supply-list-summary__item-value">{priceFormatter(vat)}</span>
        </div>

        <div className="supply-list-summary__item">
          <span>Итого с НДС:</span>

          <span className="supply-list-summary__item-value">{priceFormatter(selectedItemAmountWithVat)}</span>
        </div>
      </div>

      <div>
        {supplyPending && (
          <Button
            className="btn btn-primary"
            disabled={!selectedList.length}
            onClick={() => onStatusChange(ESupplyStatus.Approved)}
          >
            Разместить заказы
          </Button>
        )}

        {supplyApproved && (
          <Button
            className="btn btn-primary"
            disabled={!selectedList.length}
            onClick={() => onStatusChange(ESupplyStatus.Placed)}
          >
            Передать заказ в учетную систему
          </Button>
        )}

        {(supplyPlaced || supplyInProcess || supplyInDelivery || supplyDelivered) && (
          <Dropdown
            overlayClassName="three-dot-menu__dropdown"
            menu={{ items }}
            trigger={['click']}
            disabled={!selectedList.length}
          >
            <Button className="btn btn-primary">Изменить статус</Button>
          </Dropdown>
        )}
      </div>
    </div>
  );
};
