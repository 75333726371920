import React, { FC, useState } from 'react';
import { Button, Checkbox, Spin, Tooltip } from 'antd';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { ThreeDotMenu } from 'common/components/ThreeDotMenu';
import { EDateFormat, EMessage, ESupplyCorrectionValue, ESupplyStatus, ESupplyStatusLabel, EUserRole } from 'common/const/enum';
import { priceFormatter } from 'common/helpers/formatter.helper';
import { RootDispatch, RootState } from 'app/store';
import { ISupply, ISupplyShort } from 'entities/Supply/Supply.models';
import {
  getMaxCountToCreateSupply,
  getNewSupplyDefaultDeliveryDate,
  getNewSupplyStatus,
  getSupplyScheduleItemCheckboxClassName,
  getSupplyScheduleItemMenuItems,
  getSupplyStatusColor,
} from 'entities/Supply/Supply.helper';
import { CreateSupplyModal } from 'entities/Modal/components/CreateSupplyModal';
import { INeed } from 'entities/Need/Need.models';
import { RequestCorrectionModal } from 'entities/Modal/components/RequestCorrectionModal';

interface IComponentProps {
  need: INeed | null;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const SupplyScheduleComponent: FC<AllType> = (props) => {
  const {
    need,
    supplyListState,
    supplyState,
    authState,
    changeSupplyStatus,
    updateNeedList,
    createSupply,
    updateSupply,
    deleteSupply,
    requestSupplyCorrection,
    getNeedById,
  } = props;

  const { data: supplyList, loading: supplyListLoading } = supplyListState;
  const { loading: supplyLoading } = supplyState;
  const { data: auth } = authState;

  const [newSupply, setNewSupply] = useState<ISupplyShort | null>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [maxCountToEditSupply, setMaxCountToEditSupply] = useState<number>(0);
  const [supplyId, setSupplyId] = useState<number | null>(null);
  const [correctionReason, setCorrectionReason] = useState<ESupplyCorrectionValue | null>(null);
  const [correctionComment, setCorrectionComment] = useState<string>('');
  const [openCreateSupplyModal, setOpenCreateSupplyModal] = useState<boolean>(false);
  const [openRequestCorrectionModal, setOpenRequestCorrectionModal] = useState<boolean>(false);

  const roles = auth?.access.roles;
  const isManager = roles?.includes(EUserRole.Manager);
  const isSellerManager = roles?.includes(EUserRole.SellerManager);
  const isUser = !isManager && !isSellerManager;
  const maxCountToCreateSupply = getMaxCountToCreateSupply(supplyList, need?.count);

  const onCreateSupplyClick = () => {
    if (need) {
      setNewSupply({
        count: maxCountToCreateSupply,
        goodId: need.good.id,
        deliveryDate: getNewSupplyDefaultDeliveryDate(need.deliveryDate),
        price: need.price,
      });
      setOpenCreateSupplyModal(true);
    }
  };

  const onSupplyCountChange = (value: number) => {
    setNewSupply((prev) => (prev ? { ...prev, count: value } : prev));
  };

  const onSupplyPriceChange = (value: number) => {
    setNewSupply((prev) => (prev ? { ...prev, price: value } : prev));
  };

  const onSupplyDateChange = (value: string) => {
    setNewSupply((prev) => (prev ? { ...prev, deliveryDate: value } : prev));
  };

  const onCreateSupplyModalCancelClick = () => {
    setIsEdit(false);
    setSupplyId(null);
    setOpenCreateSupplyModal(false);
  };

  const onCreateSupplyModalCreateClick = async () => {
    if (need && newSupply) {
      await createSupply({
        supplyNeedId: need.id,
        supplies: [newSupply],
        onSuccess: onCreateSupplyModalCancelClick,
      });
    }
  };

  const onCreateSupplyModalEditClick = async () => {
    if (newSupply && supplyId) {
      await updateSupply({
        id: supplyId,
        count: newSupply.count,
        price: newSupply.price,
        deliveryDate: newSupply.deliveryDate,
        onSuccess: onCreateSupplyModalCancelClick,
      });
    }
  };

  const handleEditSupply = (supply: ISupply) => {
    setNewSupply({
      count: supply.count,
      goodId: supply.good.id,
      deliveryDate: getNewSupplyDefaultDeliveryDate(supply.deliveryDate),
      price: supply.price,
    });
    setIsEdit(true);
    setSupplyId(supply.id);
    setMaxCountToEditSupply(maxCountToCreateSupply + supply.count);
    setOpenCreateSupplyModal(true);
  };

  const handleSupplyStatusChange = async ({ id, status, supplyNeedId }: ISupply) => {
    const newStatus = getNewSupplyStatus(status);

    await changeSupplyStatus({ ids: [id], status: newStatus });

    if (newStatus === ESupplyStatus.Pending || newStatus === ESupplyStatus.Approved) {
      const response = await getNeedById(supplyNeedId);

      if (response) {
        updateNeedList([response]);
      }
    }
  };

  const handleDeleteSupply = async ({ id, supplyNeedId }: ISupply) => {
    await deleteSupply({ id });

    const response = await getNeedById(supplyNeedId);

    if (response) {
      updateNeedList([response]);
    }
  };

  const handleCancelSupply = ({ id }: ISupply) => {
    changeSupplyStatus({ ids: [id], status: ESupplyStatus.Cancelled });
  };

  const handleRequestCorrection = (id: number) => {
    setSupplyId(id);
    setOpenRequestCorrectionModal(true);
  };

  const onRequestCorrectionModalCancelClick = () => {
    setOpenRequestCorrectionModal(false);
    setSupplyId(null);
    setCorrectionReason(null);
    setCorrectionComment('');
  };

  const onRequestCorrectionModalSendClick = () => {
    if (supplyId && correctionReason) {
      requestSupplyCorrection({
        supplyId,
        reason: correctionReason,
        comment: correctionComment,
        onSuccess: onRequestCorrectionModalCancelClick,
      });
    }
  };

  return (
    <div className="supply-schedule">
      <div className="supply-schedule__title">График поставок</div>

      <Spin spinning={supplyListLoading}>
        {!need && <div className="supply-schedule__empty">{EMessage.NoSupplySelected}</div>}

        {need && !!supplyList.length && (
          <>
            <div className="supply-schedule__name">{need?.good.name}</div>

            {isSellerManager && (
              <Button
                className="btn btn-primary"
                style={{ marginBottom: 10 }}
                onClick={onCreateSupplyClick}
                disabled={!maxCountToCreateSupply}
              >
                Создать поставку
              </Button>
            )}

            <div className="supply-schedule__table">
              {supplyList.map((supply) => {
                const { id, count, price, deliveryDate, status } = supply;

                return (
                  <div key={id} className="supply-schedule__item">
                    {!isUser && deliveryDate && (
                      <Checkbox
                        className={getSupplyScheduleItemCheckboxClassName(status, roles)}
                        onChange={() => handleSupplyStatusChange(supply)}
                      />
                    )}

                    {!isUser && !deliveryDate && (
                      <Tooltip title={EMessage.SupplyDeliveryDateIsRequired}>
                        <Checkbox disabled />
                      </Tooltip>
                    )}

                    {isUser && <div />}

                    <div className="supply-schedule__item-count">{count} шт.</div>

                    <div className="supply-schedule__item-total-amount">{priceFormatter(count * price)}</div>

                    {deliveryDate ? (
                      <div className="supply-schedule__item-delivery-date">
                        {dayjs(deliveryDate).locale('ru').format(EDateFormat.ShortDate)}
                      </div>
                    ) : (
                      <div />
                    )}

                    <div className={`status ${getSupplyStatusColor(status)} supply-schedule__item-status`}>
                      {ESupplyStatusLabel[status]}
                    </div>

                    {!isUser ? (
                      <ThreeDotMenu
                        items={getSupplyScheduleItemMenuItems(
                          supply,
                          handleEditSupply,
                          handleSupplyStatusChange,
                          handleRequestCorrection,
                          handleDeleteSupply,
                          handleCancelSupply,
                          roles,
                        )}
                      />
                    ) : (
                      <div />
                    )}
                  </div>
                );
              })}
            </div>
          </>
        )}

        {need && !supplyList.length && <div className="supply-schedule__empty">{EMessage.SupplyListIsEmpty}</div>}
      </Spin>

      <CreateSupplyModal
        open={openCreateSupplyModal}
        loading={supplyLoading}
        count={newSupply?.count}
        maxCount={isEdit ? maxCountToEditSupply : maxCountToCreateSupply}
        price={newSupply?.price}
        date={newSupply?.deliveryDate}
        confirmBtnTitle={isEdit ? 'Редактировать' : 'Создать'}
        onCountChange={onSupplyCountChange}
        onPriceChange={onSupplyPriceChange}
        onDateChange={onSupplyDateChange}
        onCancelClick={onCreateSupplyModalCancelClick}
        onConfirmClick={isEdit ? onCreateSupplyModalEditClick : onCreateSupplyModalCreateClick}
      />

      <RequestCorrectionModal
        open={openRequestCorrectionModal}
        loading={supplyLoading}
        reason={correctionReason}
        comment={correctionComment}
        onReasonChange={setCorrectionReason}
        onCommentChange={setCorrectionComment}
        onCancelClick={onRequestCorrectionModalCancelClick}
        onSendClick={onRequestCorrectionModalSendClick}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  supplyListState: state.supplyListState,
  supplyState: state.supplyState,
  authState: state.authState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  changeSupplyStatus: dispatch.supplyListState.changeSupplyStatus,
  updateNeedList: dispatch.needListState.updateNeedList,
  createSupply: dispatch.supplyState.createSupply,
  updateSupply: dispatch.supplyState.updateSupply,
  deleteSupply: dispatch.supplyState.deleteSupply,
  requestSupplyCorrection: dispatch.supplyState.requestSupplyCorrection,
  getNeedById: dispatch.needState.getNeedById,
});

export const SupplySchedule = connect(mapState, mapDispatch)(SupplyScheduleComponent);
