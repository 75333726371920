import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Input, InputNumber, Layout, Row, Spin } from 'antd';
import { connect } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import { useNavigate } from 'react-router-dom';
import { EFormFieldMessage, EMessage } from 'common/const/enum';
import { CHARACTER_LIMIT_MIN, PHONE_PATTERN } from 'common/config';
import { useDebounce } from 'common/hooks/useDebounce';
import { Frame } from 'common/components/Frame';
import { IFormValues } from 'common/models';
import { Avatar } from 'common/components/Avatar';
import { rules } from 'common/helpers/form.helper';
import { phoneFormatter } from 'common/helpers/formatter.helper';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { RootDispatch, RootState } from 'app/store';
import { ChangePasswordModal } from 'entities/Modal/components/ChangePasswordModal';
import { IUserChangePasswordPayload } from 'entities/Users/Users.models';
import { getProfileFrames, getUserName } from 'entities/Users/Users.helper';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const ProfileComponent: React.FC<AllType> = (props) => {
  const { authState, userState, getUserById, updateUser, changeUserPassword, setUserError } = props;

  const { data: auth } = authState;
  const { data: user, loading: userLoading, error } = userState;

  const userId = auth?.access.userId;
  const roles = auth?.access.roles;
  const userName = getUserName(user?.firstName, user?.lastName);
  const subdivisionId = user?.subdivisionId;

  const [formIsChanged, setFormIsChanged] = useState<boolean>(false);
  const [firstNameValue, setFirstNameValue] = useState<string>('');
  const [lastNameValue, setLastNameValue] = useState<string>('');
  const [phoneValue, setPhoneValue] = useState<string>('');
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState<boolean>(false);
  const [userForm] = useForm();
  const [passwordForm] = useForm();
  const navigate = useNavigate();
  const formRef = useRef(null);

  const frames = getProfileFrames(navigate, subdivisionId, roles);

  const onFormValuesChange = (_: IFormValues, allValues: IFormValues) => {
    setFormIsChanged(true);

    Object.entries(allValues).forEach(([key, value]) => {
      switch (key) {
        case 'firstName': {
          setFirstNameValue(value);
          break;
        }
        case 'lastName': {
          setLastNameValue(value);
          break;
        }
        case 'phone': {
          setPhoneValue(value);
          break;
        }
        default: {
          break;
        }
      }
    });
  };

  const onCancelClick = () => {
    passwordForm.resetFields();
    setUserError(null);
    setOpenChangePasswordModal(false);
  };

  const onPasswordSubmit = (values: IUserChangePasswordPayload) => {
    const { currentPassword, newPassword } = values;

    setUserError(null);

    changeUserPassword({
      currentPassword,
      newPassword,
      onSuccess: () => {
        passwordForm.resetFields();
        setOpenChangePasswordModal(false);
      },
    });
  };

  useEffect(() => {
    if (user && !formIsChanged) {
      userForm.setFieldsValue({
        subdivisionName: user.subdivisionName,
        workspaceName: user.workspaceName,
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
      });
    }
  }, [user, formIsChanged]);

  useDebounce(() => {
    if (formRef.current && formIsChanged) {
      const errors = userForm
        .getFieldsError()
        .map((item) => item.errors)
        .flat();

      if (!errors.length) {
        updateUser({
          id: user?.id as number,
          firstName: firstNameValue,
          lastName: lastNameValue,
          phone: phoneValue,
        });
      }
    }
  }, [userForm, firstNameValue, lastNameValue, phoneValue, formIsChanged]);

  const { contentLoading } = useContentLoader(async () => {
    if (userId) {
      await getUserById(userId);
    }
  });

  if (contentLoading) {
    return (
      <div className="spin-container">
        <Spin />
      </div>
    );
  }

  return (
    <Layout className="profile">
      <Spin spinning={userLoading}>
        <Row>
          <Col className="profile__col" xs={24} lg={11}>
            <Form
              className="profile__form"
              layout="vertical"
              form={userForm}
              ref={formRef}
              requiredMark={false}
              onValuesChange={onFormValuesChange}
            >
              <div className="profile__header">
                <Avatar size={64} firstName={user?.firstName || ''} lastName={user?.lastName || ''} />

                <div>
                  <div className="profile__header_user-name">{userName}</div>

                  <div className="profile__header_user-email">{user?.email || ''}</div>
                </div>
              </div>

              <Form.Item label="Подразделение" name="subdivisionName">
                <Input placeholder="Название подразделения" disabled />
              </Form.Item>

              <Form.Item label="Карта рабочего места" name="workspaceName">
                <Input placeholder="Название карты рабочего места" disabled />
              </Form.Item>

              <Row gutter={40}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Имя"
                    name="firstName"
                    rules={[rules.required(), rules.min(CHARACTER_LIMIT_MIN, EFormFieldMessage.CharacterLimitMin)]}
                  >
                    <Input placeholder="Введите имя" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Фамилия"
                    name="lastName"
                    rules={[rules.required(), rules.min(CHARACTER_LIMIT_MIN, EFormFieldMessage.CharacterLimitMin)]}
                  >
                    <Input placeholder="Введите фамилию" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Контактный телефон"
                    name="phone"
                    rules={[rules.required(), rules.pattern(PHONE_PATTERN, EFormFieldMessage.InvalidPhoneFormat)]}
                  >
                    <InputNumber placeholder="Введите номер телефона" formatter={phoneFormatter} prefix="+" controls={false} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            <Button className="btn btn-primary" onClick={() => setOpenChangePasswordModal(true)}>
              Изменить пароль
            </Button>
          </Col>

          <Col className="profile__col" xs={24} lg={13}>
            {!frames.length ? (
              <div>{EMessage.AccountIsNotLinkedToSubdivision}</div>
            ) : (
              <div className="profile__frame-container">
                {frames.map((frame, index) => {
                  return <Frame key={index} title={frame.title} description={frame.description} onClick={frame.onClick} />;
                })}
              </div>
            )}
          </Col>
        </Row>
      </Spin>

      <Form layout="vertical" form={passwordForm}>
        <ChangePasswordModal
          open={openChangePasswordModal}
          form={passwordForm}
          loading={userLoading}
          error={error}
          onSubmit={onPasswordSubmit}
          onCancelClick={onCancelClick}
        />
      </Form>
    </Layout>
  );
};

const mapState = (state: RootState) => ({
  authState: state.authState,
  userState: state.userState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getUserById: dispatch.userState.getUserById,
  updateUser: dispatch.userState.updateUser,
  changeUserPassword: dispatch.userState.changeUserPassword,
  setUserError: dispatch.userState.setUserError,
  getBasket: dispatch.basketState.getBasket,
});

export const Profile = connect(mapState, mapDispatch)(ProfileComponent);
