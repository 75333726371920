import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Pagination, Select, Spin } from 'antd';
import type { PaginationProps } from 'antd';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { ContentLoader } from 'common/components/ContentLoader';
import { GoodsItem } from 'common/components/GoodsItem';
import { EMessage, ENeedStatus, ESearchParams } from 'common/const/enum';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { DEFAULT_LIST_LIMIT, DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE } from 'common/config';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';
import { RootDispatch, RootState } from 'app/store';
import { NeedHistory } from 'entities/Need/components/NeedHistory';
import { INeed, INeedListPayload } from 'entities/Need/Need.models';
import { getNeedListSubdivisionOptions } from 'entities/Need/Need.helper';
import { SupplySchedule } from 'entities/Supply/components/SupplySchedule';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const PendingNeedListForSellerManagerComponent: FC<AllType> = (props) => {
  const {
    needListState,
    buyerListState,
    getNeedList,
    getNeedSubdivisionListForSeller,
    setNeedList,
    getBuyerList,
    getSupplyList,
    setSupplyList,
    getNeedHistoryList,
    setNeedHistoryList,
    getNeedById,
  } = props;

  const { data: needList, count: needListCount, loading: needListLoading, subdivisionList } = needListState;
  const { data: buyerList } = buyerListState;

  const [payload, setPayload] = useState<INeedListPayload>({
    statuses: [ENeedStatus.Pending],
    limit: DEFAULT_LIST_LIMIT,
    offset: DEFAULT_LIST_OFFSET,
  });
  const [page, setPage] = useState<number>(DEFAULT_PAGINATION_PAGE);
  const [selectedNeed, setSelectedNeed] = useState<INeed | null>(null);
  const { getSearchParam, removeSearchParam } = useSearchParamsHook();

  const needId = getSearchParam(ESearchParams.Id);
  const buyerOptions = buyerList.map((buyer) => ({ label: buyer.name, value: buyer.id }));
  const subdivisionOptions = getNeedListSubdivisionOptions(subdivisionList);

  const onCompanyChange = async (value?: number) => {
    const newPayload = {
      ...payload,
      buyerId: value,
      subdivisionIds: undefined,
      limit: DEFAULT_LIST_LIMIT,
      offset: DEFAULT_LIST_OFFSET,
    };

    setPayload(newPayload);
    await getNeedList(newPayload);
    await getNeedSubdivisionListForSeller(value);
    removeSearchParam(ESearchParams.Id);
    setSelectedNeed(null);
    setSupplyList([]);
    setNeedHistoryList([]);
    setPage(DEFAULT_PAGINATION_PAGE);
  };

  const onSubdivisionChange = async (value?: number) => {
    const newPayload = {
      ...payload,
      subdivisionIds: value ? [value] : undefined,
      offset: DEFAULT_LIST_OFFSET,
    };

    setPayload(newPayload);
    await getNeedList(newPayload);
    removeSearchParam(ESearchParams.Id);
    setSelectedNeed(null);
    setSupplyList([]);
    setNeedHistoryList([]);
    setPage(DEFAULT_PAGINATION_PAGE);
  };

  const onPageChange: PaginationProps['onChange'] = async (value) => {
    const newPayload = { ...payload, offset: (value - 1) * DEFAULT_LIST_LIMIT };

    setPayload(newPayload);
    await getNeedList(newPayload);
    setSelectedNeed(null);
    setSupplyList([]);
    setNeedHistoryList([]);
    setPage(value);
  };

  const onNeedClick = async (id: number) => {
    const selectedNeed = needList.find((need) => need.id === id);

    setSelectedNeed(selectedNeed as INeed);
    await getSupplyList({ supplyNeedId: id, orderDirection: 'ASC' });
    await getNeedHistoryList({ supplyNeedId: id, limit: DEFAULT_LIST_LIMIT });
  };

  useEffect(() => {
    return () => {
      setSupplyList([]);
      setNeedHistoryList([]);
    };
  }, []);

  const { contentLoading } = useContentLoader(async () => {
    if (needId) {
      const response = await getNeedById(Number(needId));

      if (response) {
        await getSupplyList({ supplyNeedId: response.id, orderDirection: 'ASC' });
        setNeedList([response]);
        setSelectedNeed(response);
      }
    } else {
      await getNeedList(payload);
    }

    await getBuyerList();
    await getNeedSubdivisionListForSeller();
  });

  if (contentLoading) {
    return (
      <div className="need-list">
        <ContentLoader />
      </div>
    );
  }

  return (
    <div className="need-list">
      <div className="need-list__content">
        <div className="need-list__filter">
          <div className="need-list__filter-item">
            <span className="need-list__filter-item-label">Компания</span>

            <Select
              suffixIcon={<ChevronIcon />}
              value={payload.buyerId}
              options={buyerOptions}
              popupMatchSelectWidth={false}
              placeholder="Выберите компанию"
              allowClear
              onChange={onCompanyChange}
            />
          </div>

          <div className="need-list__filter-item">
            <span className="need-list__filter-item-label">Подразделение</span>

            <Select
              suffixIcon={<ChevronIcon />}
              value={payload.subdivisionIds?.[0]}
              options={subdivisionOptions}
              popupMatchSelectWidth={false}
              placeholder="Выберите подразделение"
              allowClear
              onChange={onSubdivisionChange}
            />
          </div>
        </div>

        <Spin spinning={needListLoading}>
          <>
            {needList.length ? (
              needList.map((need) => {
                return (
                  <GoodsItem
                    key={need.id}
                    id={need.id}
                    options={{
                      showVendorCode: true,
                      showPendingSuppliesCount: true,
                      showCompanyName: true,
                      showCity: true,
                      image: need.good.image,
                      vendorCode: need.good.vendorCode,
                      count: need.count,
                      pendingSuppliesCount: need.pendingSuppliesCount,
                      name: need.good.name,
                      companyName: need.account.name,
                      city: need.address?.city,
                    }}
                    onItemClick={onNeedClick}
                  />
                );
              })
            ) : (
              <div className="need-list__empty">{EMessage.RequestListIsEmpty}</div>
            )}

            <Pagination total={needListCount} current={page} pageSize={DEFAULT_LIST_LIMIT} onChange={onPageChange} />
          </>
        </Spin>
      </div>

      <div>
        <SupplySchedule need={selectedNeed} />

        <NeedHistory need={selectedNeed} />
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  needListState: state.needListState,
  buyerListState: state.buyerListState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getNeedList: dispatch.needListState.getNeedList,
  getNeedSubdivisionListForSeller: dispatch.needListState.getNeedSubdivisionListForSeller,
  setNeedList: dispatch.needListState.setNeedList,
  getBuyerList: dispatch.buyerListState.getBuyerList,
  getSupplyList: dispatch.supplyListState.getSupplyList,
  setSupplyList: dispatch.supplyListState.setSupplyList,
  getNeedHistoryList: dispatch.needHistoryListState.getNeedHistoryList,
  setNeedHistoryList: dispatch.needHistoryListState.setNeedHistoryList,
  getNeedById: dispatch.needState.getNeedById,
});

export const PendingNeedListForSellerManager = connect(mapState, mapDispatch)(PendingNeedListForSellerManagerComponent);
