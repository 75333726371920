import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { EBasketMenuTab } from 'common/const/enum';
import { Navigation } from 'common/components/Navigation';
import { userNavigationItems } from 'common/const/navigation.const';
import { CreatedNeedListForUser } from 'entities/Need/components/CreatedNeedListForUser';
import { SupplyListForUser } from 'entities/Supply/components/SupplyListForUser';

export const BasketWrapper: FC = () => {
  const { tab } = useParams();

  const getContent = () => {
    if (tab === EBasketMenuTab.SupplyList) {
      return <SupplyListForUser />;
    }

    return <CreatedNeedListForUser />;
  };

  return (
    <div className="basket-wrapper">
      <Navigation items={userNavigationItems} />

      {getContent()}
    </div>
  );
};
