import React from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { priceFormatter } from 'common/helpers/formatter.helper';
import { RootDispatch, RootState } from 'app/store';
import { IBasket, IBasketGoods } from 'entities/Basket/Basket.models';
import { BasketDeliveryDate } from 'entities/Basket/components/BasketDeliveryDate';
import { BasketDeliveryAddress } from 'entities/Basket/components/BasketDeliveryAddress';
import { BasketLegal } from 'entities/Basket/components/BasketLegal';
import { getBasketOrderInfo } from 'entities/Basket/Basket.helper';
import { addressListToOptionsWithId } from 'entities/Addresses/Addresses.helper';

interface IComponentProps {
  basket: IBasket | null;
  selectedGoodsIds: number[];
  goodsList: IBasketGoods[];
  readOnly: boolean;
  isCurrentBasket?: boolean;
  onSendRequestClick: () => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const BasketSummaryComponent: React.FC<AllType> = (props) => {
  const {
    basket,
    selectedGoodsIds,
    goodsList,
    readOnly,
    isCurrentBasket,
    addressListState,
    legalListState,
    onSendRequestClick,
    updateBasket,
  } = props;

  const { data: addressList } = addressListState;
  const { data: legalList } = legalListState;

  const sendingDisabled =
    !isCurrentBasket ||
    !selectedGoodsIds.length ||
    !basket?.addressId ||
    !basket?.legalId ||
    goodsList.some((goods) => selectedGoodsIds.includes(goods.goodId) && !goods.isAvailableForCustomer);
  const addressOptions = addressListToOptionsWithId(addressList);
  const legalOptions = legalList?.map((legal) => ({ label: legal.name, value: legal.id }));
  const { selectedGoodsCount, selectedGoodsPrice, vat, totalPriceWithVat } = getBasketOrderInfo(
    selectedGoodsIds,
    goodsList,
    basket,
  );

  const onDeliveryAddressChange = (addressId: number) => {
    if (basket) {
      updateBasket({ id: basket.id, addressId });
    }
  };

  const onLegalChange = (legalId: number) => {
    if (basket) {
      updateBasket({ id: basket.id, legalId });
    }
  };

  const onDeliveryDateChange = (deliveryDate: string | null) => {
    if (basket) {
      updateBasket({ id: basket.id, deliveryDate });
    }
  };

  return (
    <div className="basket-summary">
      <div className="basket-summary__body">
        <div className="basket-summary__limit">
          <span>Доступный лимит средств:</span>

          <span>{priceFormatter(basket?.availableLimit)}</span>
        </div>

        {basket?.maxGoodPrice && (
          <div className="basket-summary__limit">
            <span>Лимит цены на товар:</span>

            <span>{priceFormatter(basket?.maxGoodPrice)}</span>
          </div>
        )}

        <BasketDeliveryAddress
          subdivisionId={basket?.subdivisionId}
          options={addressOptions}
          addressId={basket?.addressId}
          addressName={basket?.address}
          readOnly={readOnly}
          onChange={onDeliveryAddressChange}
        />

        <BasketLegal
          subdivisionId={basket?.subdivisionId}
          options={legalOptions}
          legalId={basket?.legalId}
          legalName={basket?.legalName}
          readOnly={readOnly}
          onChange={onLegalChange}
        />

        <BasketDeliveryDate date={basket?.deliveryDate} readOnly={readOnly} onChange={onDeliveryDateChange} />

        <div className="basket-summary__info">
          <span className="basket-summary__info-title">Информация о заказе</span>

          <div className="basket-summary__info-details">
            <div className="basket-summary__info-details-item">
              <span>Выбрано товаров:</span>

              <span className="basket-summary__info-details-item-value">{selectedGoodsCount}</span>
            </div>

            <div className="basket-summary__info-details-item">
              <span>Стоимость товаров:</span>

              <span className="basket-summary__info-details-item-value">{selectedGoodsPrice}</span>
            </div>

            <div className="basket-summary__info-details-item">
              <span>Сумма НДС:</span>

              <span className="basket-summary__info-details-item-value">{vat}</span>
            </div>

            <div className="basket-summary__info-details-item">
              <span>Итого с НДС:</span>

              <span className="basket-summary__info-details-item-value">{totalPriceWithVat}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="basket-summary__footer">
        <Button type="link" disabled={sendingDisabled} onClick={onSendRequestClick}>
          Отправить заявку
        </Button>
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  addressListState: state.addressListState,
  legalListState: state.legalListState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  updateBasket: dispatch.basketState.updateBasket,
});

export const BasketSummary = connect(mapState, mapDispatch)(BasketSummaryComponent);
