import { ESupplyCorrectionLabel, ESupplyCorrectionValue, ESupplyStatus, ESupplyStatusLabel } from 'common/const/enum';

export const supplyCorrectionOptions = [
  {
    label: ESupplyCorrectionLabel.price,
    value: ESupplyCorrectionValue.Price,
  },
  {
    label: ESupplyCorrectionLabel.date,
    value: ESupplyCorrectionValue.Date,
  },
];

export const supplyListStatusFilterOptions = [
  {
    label: ESupplyStatusLabel.approved,
    value: ESupplyStatus.Approved,
  },
  {
    label: ESupplyStatusLabel.placed,
    value: ESupplyStatus.Placed,
  },
  {
    label: ESupplyStatusLabel.processing,
    value: ESupplyStatus.Processing,
  },
  {
    label: ESupplyStatusLabel.delivery,
    value: ESupplyStatus.Delivery,
  },
  {
    label: ESupplyStatusLabel.delivered,
    value: ESupplyStatus.Delivered,
  },
  {
    label: ESupplyStatusLabel.cancelled,
    value: ESupplyStatus.Cancelled,
  },
];

export const supplyListStatusFilterOptionsForManager = [
  {
    label: ESupplyStatusLabel.created,
    value: ESupplyStatus.Created,
  },
  {
    label: ESupplyStatusLabel.pending,
    value: ESupplyStatus.Pending,
  },
];
