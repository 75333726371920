import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Navigation } from 'common/components/Navigation';
import { EManagerNeedListMenuTab } from 'common/const/enum';
import { managerNavigationItems } from 'common/const/navigation.const';
import { CreatedNeedListForManager } from 'entities/Need/components/CreatedNeedListForManager';
import { PendingNeedListForManager } from 'entities/Need/components/PendingNeedListForManager';
import { SupplyListForManager } from 'entities/Supply/components/SupplyListForManager';

export const ManagerNeedList: FC = () => {
  const { tab } = useParams();

  const getContent = () => {
    switch (tab) {
      case EManagerNeedListMenuTab.Pending: {
        return <PendingNeedListForManager />;
      }
      case EManagerNeedListMenuTab.SupplyList: {
        return <SupplyListForManager />;
      }
      default: {
        return <CreatedNeedListForManager />;
      }
    }
  };

  return (
    <div className="need-list-wrapper">
      <Navigation items={managerNavigationItems} />

      {getContent()}
    </div>
  );
};
