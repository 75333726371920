import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Button, Input, Modal, Spin, Table } from 'antd';
import { connect } from 'react-redux';
import { useDebounce } from 'common/hooks/useDebounce';
import { toDataSourceMapper } from 'common/helpers/data.helper';
import { useScroll } from 'common/hooks/useScroll';
import { EMessage, EUserRole } from 'common/const/enum';
import { GOODS_LIST_LIMIT, QUICKLY_GOODS_VIEW_LIMIT } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as SearchIcon } from 'app/assets/images/search.svg';
import { IGoods } from 'entities/Goods/Goods.models';
import { renderQuicklyAddGoodsModalRecords } from 'entities/Modal/Modal.helper';

interface IComponentProps {
  open: boolean;
  loading: boolean;
  userRoles?: EUserRole;
  goodsInBasketIds: number[];
  onCancelClick: () => void;
  onAddToBasketClick: (goods: IGoods) => void;
  onAddGoodsToDatabaseClick: () => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

export const QuicklyAddGoodsModalComponent: React.FC<AllType> = (props) => {
  const {
    open,
    loading,
    userRoles,
    goodsInBasketIds,
    goodsListState,
    onCancelClick,
    onAddToBasketClick,
    onAddGoodsToDatabaseClick,
    getGoodsList,
    clearGoodsListState,
  } = props;
  const { data: goodsList, loading: goodsListLoading, count } = goodsListState;

  const [codeSearch, setCodeSearch] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [goods, setGoods] = useState<IGoods[]>([]);
  const [selectedGoods, setSelectedGoods] = useState<IGoods | null>(null);
  const endOfScrollRef = useRef<HTMLDivElement>(null);

  const dataSource = toDataSourceMapper(goods);
  const columns = renderQuicklyAddGoodsModalRecords();

  const { observerRef } = useScroll(endOfScrollRef, goodsListLoading, page < count, unobserve, () => setPage((prev) => prev + 1));

  function unobserve() {
    if (endOfScrollRef.current) {
      observerRef.current.unobserve(endOfScrollRef.current);
    }
  }

  const onCodeSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    unobserve();
    setPage(0);
    setGoods([]);
    setCodeSearch(e.target.value);
  };

  const handleAddToBasketClick = () => {
    if (selectedGoods) {
      onAddToBasketClick(selectedGoods);
    }
  };

  useEffect(() => {
    if (!open) {
      unobserve();
      setCodeSearch('');
      setPage(0);
      setSelectedGoods(null);
      clearGoodsListState();
      setGoods([]);
    }
  }, [open]);

  useDebounce(() => {
    if (open) {
      getGoodsList({ codeSearch, limit: GOODS_LIST_LIMIT, offset: GOODS_LIST_LIMIT * page });
    }
  }, [open, codeSearch, page]);

  useEffect(() => {
    if (open) {
      setGoods((prev) => [...prev, ...goodsList.filter((goods) => !goodsInBasketIds.includes(goods.id))]);
    }
  }, [open, goodsList]);

  return (
    <Modal open={open} className="modal quickly-add-goods-modal" footer={false} closeIcon={false} width={577}>
      <Spin spinning={loading}>
        <div className="modal-content">
          <Input
            value={codeSearch}
            onChange={onCodeSearchChange}
            placeholder="Введите артикул поставщика или клиента"
            suffix={<SearchIcon />}
          />

          <Spin spinning={goodsListLoading}>
            <div className="quickly-add-goods-modal__container">
              <Table
                dataSource={dataSource}
                columns={columns}
                className="quickly-add-goods-modal__table"
                pagination={false}
                showHeader={false}
                size="small"
                locale={{ emptyText: EMessage.NothingFound }}
                onRow={(record) => ({ onClick: () => setSelectedGoods(record) })}
                rowClassName={(record) => {
                  return record.id === selectedGoods?.id ? 'selected' : '';
                }}
              />

              {goodsList.length > QUICKLY_GOODS_VIEW_LIMIT && <div ref={endOfScrollRef} />}
            </div>
          </Spin>

          <div className="modal-footer">
            <Button className="btn btn-default" onClick={onCancelClick}>
              Отмена
            </Button>

            {userRoles?.includes(EUserRole.SellerManager) && !goods.length ? (
              // TODO remove temp disabled
              <Button className="btn btn-default" onClick={onAddGoodsToDatabaseClick} disabled>
                Добавить товар в базу
              </Button>
            ) : (
              <Button className="btn btn-default" onClick={handleAddToBasketClick} disabled={!selectedGoods}>
                Добавить в корзину
              </Button>
            )}
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

const mapState = (state: RootState) => ({
  goodsListState: state.goodsListState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getGoodsList: dispatch.goodsListState.getGoodsList,
  clearGoodsListState: dispatch.goodsListState.clearGoodsListState,
});

export const QuicklyAddGoodsModal = connect(mapState, mapDispatch)(QuicklyAddGoodsModalComponent);
