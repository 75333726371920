import React, { FC } from 'react';
import { Button, Drawer } from 'antd';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { EDateFormat, ERoute } from 'common/const/enum';

interface IComponentProps {
  open: boolean;
  onClose: () => void;
}

export const BasketSuccessfulRequest: FC<IComponentProps> = ({ open, onClose }) => {
  const navigate = useNavigate();

  return (
    <Drawer rootClassName="redesign basket-successful-request__drawer" open={open} onClose={onClose} width={720}>
      <div className="basket-successful-request__drawer-container">
        <span className="text-h3 color-white-40 basket-successful-request__drawer-subtitle">Отправлено!</span>

        <span className="text-h1 color-white">
          {`Заявка от ${dayjs().format(EDateFormat.FullDateDotSeparator)} в очереди на рассмотрение`}
        </span>
      </div>

      <div className="basket-delete-goods-button__drawer-footer">
        <Button className="button-l green" onClick={() => navigate(ERoute.NeedList)}>
          К моим заявкам
        </Button>
      </div>
    </Drawer>
  );
};
