import React, { FC, useEffect, useState } from 'react';
import { Pagination, Spin } from 'antd';
import type { PaginationProps } from 'antd';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { EDateFormat, EMessage } from 'common/const/enum';
import { DEFAULT_LIST_LIMIT, DEFAULT_PAGINATION_PAGE } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { INeed } from 'entities/Need/Need.models';
import { getNeedHistoryItemDescription } from 'entities/Need/Need.helper';

interface IComponentProps {
  need: INeed | null;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const NeedHistoryComponent: FC<AllType> = (props) => {
  const { need, needHistoryListState, getNeedHistoryList, setNeedHistoryListCount } = props;

  const { data: needHistory, loading, count } = needHistoryListState;

  const [page, setPage] = useState<number>(DEFAULT_PAGINATION_PAGE);

  const onPageChange: PaginationProps['onChange'] = async (value) => {
    if (need) {
      await getNeedHistoryList({ supplyNeedId: need.id, limit: DEFAULT_LIST_LIMIT, offset: (value - 1) * DEFAULT_LIST_LIMIT });
      setPage(value);
    }
  };

  useEffect(() => {
    setPage(DEFAULT_PAGINATION_PAGE);
    setNeedHistoryListCount(0);
  }, [need]);

  return (
    <div className="need-history">
      <div className="need-history__title">Лог событий</div>

      <Spin spinning={loading}>
        {!need && <div className="need-history__empty">{EMessage.NoSupplySelected}</div>}

        {needHistory.map((item) => {
          return (
            <div key={item.id} className="need-history__item">
              <div className="need-history__item-date">
                {dayjs(item.createdAt).locale('ru').format(EDateFormat.FullDateWithTime)}
              </div>

              <div className="need-history__item-description">{getNeedHistoryItemDescription(item)}</div>
            </div>
          );
        })}
      </Spin>

      <Pagination
        className="need-history__pagination"
        total={count}
        current={page}
        pageSize={DEFAULT_LIST_LIMIT}
        onChange={onPageChange}
        size="small"
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  needHistoryListState: state.needHistoryListState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getNeedHistoryList: dispatch.needHistoryListState.getNeedHistoryList,
  setNeedHistoryListCount: dispatch.needHistoryListState.setNeedHistoryListCount,
});

export const NeedHistory = connect(mapState, mapDispatch)(NeedHistoryComponent);
