/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { useDebounce } from 'common/hooks/useDebounce';
import { EPresetType, ERoute } from 'common/const/enum';
import { DEFAULT_EXPANDED_ROW_KEY, DEFAULT_GOODS_COUNT, DEFAULT_PAGINATION_PAGE } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { getSearchParamsUrlFromPropertyList, setCatalogGoodsListFilterItemsHidden } from 'entities/Catalog/Catalog.helper';
import { ICatalogGoodsListFilterProperty } from 'entities/Property/Property.models';

export interface ICatalogContext {
  page: number;
  count: number;
  expandedGoodsId: number;
  fetchLoading: boolean;
  openGoodsCard: boolean;
  countIsChanged: boolean;
  propertiesForFilter: ICatalogGoodsListFilterProperty[];
  setPage: (value: number) => void;
  setCount: (value: number) => void;
  setExpandedGoodsId: (id: number) => void;
  setFetchLoading: (value: boolean) => void;
  setOpenGoodsCard: (value: boolean) => void;
  setCountIsChanged: (value: boolean) => void;
  setFilterIsChanged: (value: boolean) => void;
  setPropertiesForFilter: (value: ICatalogGoodsListFilterProperty[]) => void;
  goToBasket: () => void;
  onCatalogChange: (id: number) => void;
  onOpenGoodsCard: (id: number) => void;
  onCategoryChange: (id: number) => void;
  addGoodsToBasket: (id: number) => void;
  onExpandRow: (id: number, goodInBasket: boolean) => void;
  onCountChange: (value: number, goodInBasket: boolean) => void;
}

export const CatalogContext = createContext<ICatalogContext>({
  page: DEFAULT_PAGINATION_PAGE,
  count: DEFAULT_GOODS_COUNT,
  expandedGoodsId: DEFAULT_EXPANDED_ROW_KEY,
  fetchLoading: true,
  openGoodsCard: false,
  countIsChanged: false,
  propertiesForFilter: [],
  setPage: () => {},
  setCount: () => {},
  setExpandedGoodsId: () => {},
  setFetchLoading: () => {},
  setOpenGoodsCard: () => {},
  setCountIsChanged: () => {},
  setFilterIsChanged: () => {},
  setPropertiesForFilter: () => {},
  goToBasket: () => {},
  onCatalogChange: () => {},
  onOpenGoodsCard: () => {},
  onCategoryChange: () => {},
  addGoodsToBasket: () => {},
  onExpandRow: () => {},
  onCountChange: () => {},
});

export const useCatalogContext = () => useContext(CatalogContext);

export interface IComponentProps {
  children: React.ReactNode;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

export const CatalogProviderComponent: FC<AllType> = (props) => {
  const { children, propertyList, goods, basket, updateGoodsListGoodsInBasket, setGoods, getGoods, updateBasket } = props;

  const [page, setPage] = useState<number>(DEFAULT_PAGINATION_PAGE);
  const [count, setCount] = useState<number>(DEFAULT_GOODS_COUNT);
  const [expandedGoodsId, setExpandedGoodsId] = useState<number>(DEFAULT_EXPANDED_ROW_KEY);
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const [openGoodsCard, setOpenGoodsCard] = useState<boolean>(false);
  const [countIsChanged, setCountIsChanged] = useState<boolean>(false);
  const [filterIsChanged, setFilterIsChanged] = useState<boolean>(false);
  const [propertiesForFilter, setPropertiesForFilter] = useState<ICatalogGoodsListFilterProperty[]>([]);
  const { catalogId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { getSearchParam } = useSearchParamsHook();

  const onCatalogChange = (id: number) => {
    navigate(`${ERoute.Catalog}/${id}`);
  };

  const onCategoryChange = (id: number) => {
    if (catalogId) {
      navigate(`${ERoute.Catalog}/${catalogId}/${id}`);
    }
  };

  const onCountChange = (value: number, goodInBasket: boolean) => {
    if (goodInBasket) {
      setCountIsChanged(true);
    }

    setCount(value);
  };

  const onOpenGoodsCard = (id: number) => {
    getGoods({ id, onSuccess: () => setOpenGoodsCard(true) });
  };

  const goToBasket = () => {
    navigate(ERoute.Basket);
  };

  const addGoodsToBasket = (id: number) => {
    if (basket) {
      updateBasket({
        id: basket.id,
        goods: [
          ...basket.goods.map((basketGoods) => ({ goodId: basketGoods.goodId, count: basketGoods.count })),
          { goodId: id, count },
        ],
        onSuccess: () => {
          if (goods) {
            setGoods({ ...goods, goodInBasket: true });
          }

          updateGoodsListGoodsInBasket({ id, goodInBasket: true });
        },
      });
    }
  };

  const onExpandRow = (id: number, goodInBasket: boolean) => {
    if (goodInBasket && basket) {
      const goodsFromBasket = basket.goods.find((goods) => goods.goodId === id);

      if (goodsFromBasket) {
        setCount(goodsFromBasket.count);
      }
    } else {
      setCount(DEFAULT_GOODS_COUNT);
    }

    setExpandedGoodsId(id);
  };

  useEffect(() => {
    const propertiesForView = propertyList
      .filter(({ presetType }) => presetType !== EPresetType.NotApplicable)
      .map((property) => {
        const result = getSearchParam(property.id.toString());

        if (result) {
          return { ...property, result: JSON.parse(result) };
        }

        return { ...property, result: [] };
      });

    setPropertiesForFilter(setCatalogGoodsListFilterItemsHidden(propertiesForView));
  }, [location, propertyList]);

  useDebounce(() => {
    if (filterIsChanged) {
      const searchUrl = getSearchParamsUrlFromPropertyList(propertiesForFilter);

      navigate(`${location.pathname}?${searchUrl}`);
      setFilterIsChanged(false);
    }
  }, [filterIsChanged, propertiesForFilter]);

  useDebounce(() => {
    if (basket && countIsChanged) {
      updateBasket({
        id: basket.id,
        goods: basket.goods.map((basketGoods) => {
          if (basketGoods.goodId === expandedGoodsId) {
            return { ...basketGoods, count };
          }

          return basketGoods;
        }),
        onSuccess: () => setCountIsChanged(false),
      });
    }
  }, [basket, expandedGoodsId, countIsChanged, count]);

  return (
    <CatalogContext.Provider
      value={{
        page,
        count,
        expandedGoodsId,
        fetchLoading,
        openGoodsCard,
        countIsChanged,
        propertiesForFilter,
        setPage,
        setCount,
        setExpandedGoodsId,
        setFetchLoading,
        setOpenGoodsCard,
        setCountIsChanged,
        setFilterIsChanged,
        setPropertiesForFilter,
        goToBasket,
        onCatalogChange,
        onOpenGoodsCard,
        onCategoryChange,
        addGoodsToBasket,
        onExpandRow,
        onCountChange,
      }}
    >
      {children}
    </CatalogContext.Provider>
  );
};

const mapState = (state: RootState) => ({
  propertyList: state.propertyListState.data,
  goods: state.goodsState.data,
  basket: state.basketState.data,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  updateGoodsListGoodsInBasket: dispatch.goodsListState.updateGoodsListGoodsInBasket,
  setGoods: dispatch.goodsState.setGoods,
  getGoods: dispatch.goodsState.getGoods,
  updateBasket: dispatch.basketState.updateBasket,
});

export const CatalogProvider = connect(mapState, mapDispatch)(CatalogProviderComponent);
