import React, { useState } from 'react';
import { Button, DatePicker, Tooltip } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { Dayjs } from 'dayjs';
import locale from 'antd/es/date-picker/locale/ru_RU';
import 'dayjs/locale/ru';
import { EDateFormat, EMessage } from 'common/const/enum';
import { disabledDate, toPayloadDate } from 'common/helpers/date.helper';
import { ReactComponent as CloseIcon } from 'app/assets/images/close.svg';

interface IComponentProps {
  date?: string;
  readOnly: boolean;
  onChange: (value: string | null) => void;
}

export const BasketDeliveryDate: React.FC<IComponentProps> = ({ date, readOnly, onChange }) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const toggleIsEdit = () => setIsEdit((prev) => !prev);

  const onDateChange = (value: Dayjs | null) => {
    if (value) {
      onChange(toPayloadDate(value));
      toggleIsEdit();
    }
  };

  return (
    <div className="basket-delivery-date">
      <div>
        <span className="basket-delivery-date__title">Дата поставки</span>

        {!readOnly && (
          <Tooltip
            overlayClassName="basket-delivery-date__tooltip"
            title={EMessage.YouCanIndicateDesiredDateForReceivingTheGoods}
          >
            <InfoCircleFilled />
          </Tooltip>
        )}
      </div>

      {!isEdit ? (
        <div className="basket-delivery-date__value">
          <span>{date}</span>

          {!readOnly ? (
            date ? (
              <Button type="link" onClick={() => onChange(null)}>
                Удалить
              </Button>
            ) : (
              <Button type="link" onClick={toggleIsEdit}>
                Добавить
              </Button>
            )
          ) : null}
        </div>
      ) : (
        <div className="basket-delivery-date__container">
          <DatePicker
            onChange={onDateChange}
            locale={locale}
            allowClear={false}
            suffixIcon={false}
            disabledDate={disabledDate}
            format={EDateFormat.FullDateSlashSeparator}
          />

          <Button className="btn btn-icon basket-delivery-date__close" icon={<CloseIcon />} onClick={toggleIsEdit} />
        </div>
      )}
    </div>
  );
};
