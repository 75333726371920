import React, { FC } from 'react';
import { Button, DatePicker, InputNumber, Modal, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { Counter } from 'common/components/Counter';
import { EDateFormat } from 'common/const/enum';
import { disabledDate, getCounterWidthStyles } from 'common/helpers/date.helper';

interface IComponentProps {
  open: boolean;
  loading: boolean;
  count?: number;
  maxCount?: number;
  price?: number;
  date?: string;
  confirmBtnTitle: string;
  onCountChange: (value: number) => void;
  onPriceChange: (value: number) => void;
  onDateChange: (value: string) => void;
  onCancelClick: () => void;
  onConfirmClick: () => void;
}

export const CreateSupplyModal: FC<IComponentProps> = (props) => {
  const {
    open,
    loading,
    count,
    maxCount,
    price,
    date,
    confirmBtnTitle,
    onCountChange,
    onPriceChange,
    onDateChange,
    onCancelClick,
    onConfirmClick,
  } = props;

  const formattedPrice = `${price} руб/шт`;

  const handlePriceChange = (value: number | null) => {
    if (value) {
      onPriceChange(value);
    }
  };

  const handleDateChange = (value: Dayjs | null) => {
    onDateChange(dayjs(value).locale('ru').format(EDateFormat.FullDateReverse));
  };

  return (
    <Modal className="modal-gray" open={open} footer={false} closeIcon={false} width={250}>
      <Spin spinning={loading}>
        <div className="modal-gray__body">
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <Counter count={count} max={maxCount} onChange={onCountChange} />

            <InputNumber
              className="supply__price-input"
              value={price}
              controls={false}
              min={0}
              formatter={() => formattedPrice}
              onChange={handlePriceChange}
              style={getCounterWidthStyles(formattedPrice, 48, 300)}
            />

            <DatePicker
              className="supply__date-picker"
              value={dayjs(date)}
              onChange={handleDateChange}
              locale={locale}
              allowClear={false}
              suffixIcon={false}
              disabledDate={disabledDate}
              format={EDateFormat.ShortDate}
            />
          </div>
        </div>

        <div className="modal-gray__footer">
          <Button className="btn btn-default" onClick={onCancelClick}>
            Отмена
          </Button>

          <Button className="btn btn-primary" onClick={onConfirmClick}>
            {confirmBtnTitle}
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};
