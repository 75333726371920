import React from 'react';
import { Button } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { EColor, EDateFormat, ESupplyStatus, ESupplyStatusLabel, EUserRole } from 'common/const/enum';
import { ISupply, ISupplyListState, ISupplySubdivision, ISupplyUser } from 'entities/Supply/Supply.models';

dayjs.extend(isSameOrAfter);

export const getSupplyStatusColor = (status?: string) => {
  if (status === ESupplyStatus.Approved) {
    return EColor.Blue;
  } else if (status === ESupplyStatus.Cancelled) {
    return EColor.Red;
  } else if (status === ESupplyStatus.Created) {
    return EColor.Gray;
  } else {
    return EColor.Green;
  }
};

export const getSupplyScheduleItemCheckboxClassName = (status: string, roles?: string[]) => {
  let className = 'supply-schedule__item-checkbox ';

  if (
    roles?.includes(EUserRole.SellerManager) &&
    ![ESupplyStatus.Created, ESupplyStatus.Approved].includes(status as ESupplyStatus)
  ) {
    className += 'hidden';
  }

  if (roles?.includes(EUserRole.Manager) && ![ESupplyStatus.Pending].includes(status as ESupplyStatus)) {
    className += 'hidden';
  }

  return className;
};

export const getSupplyScheduleItemMenuItems = (
  supply: ISupply,
  editSupply: (supply: ISupply) => void,
  changeSupplyStatus: (supply: ISupply) => void,
  requestCorrection: (id: number) => void,
  deleteSupply: (supply: ISupply) => void,
  cancelSupply: (supply: ISupply) => void,
  roles?: string[],
) => {
  const isManager = roles?.includes(EUserRole.Manager);
  const isSellerManager = roles?.includes(EUserRole.SellerManager);
  const { supplyCreated, supplyPending, supplyApproved, supplyCancelled } = getSupplyStatus(supply.status);

  return [
    {
      key: 'edit',
      label: (
        <Button onClick={() => editSupply(supply)} disabled={!(isSellerManager && supplyCreated)}>
          Редактировать поставку
        </Button>
      ),
    },
    {
      key: 'confirm',
      label: (
        <Button onClick={() => changeSupplyStatus(supply)} disabled={!(isSellerManager && supplyCreated)}>
          Подтвердить
        </Button>
      ),
    },
    {
      key: 'place',
      label: (
        <Button onClick={() => changeSupplyStatus(supply)} disabled={!(isManager && supplyPending)}>
          Разместить заказ
        </Button>
      ),
    },
    {
      key: 'request',
      label: (
        <Button onClick={() => requestCorrection(supply.id)} disabled={!(isManager && supplyPending)}>
          Запросить корректировку
        </Button>
      ),
    },
    {
      key: 'transfer',
      label: (
        <Button onClick={() => changeSupplyStatus(supply)} disabled={!(isSellerManager && supplyApproved)}>
          Передать в УС
        </Button>
      ),
    },
    {
      key: 'cancel',
      label: (
        <Button onClick={() => cancelSupply(supply)} disabled={supplyCancelled || (isManager && supplyApproved)}>
          Отказаться
        </Button>
      ),
      className: 'supply-schedule__item-menu cancel',
    },
    {
      key: 'delete',
      label: (
        <Button onClick={() => deleteSupply(supply)} disabled={!(isSellerManager && supplyCreated)}>
          Удалить
        </Button>
      ),
      className: 'supply-schedule__item-menu delete',
    },
  ];
};

export const getMaxCountToCreateSupply = (supplyList: ISupply[], needCount?: number) => {
  if (!needCount) {
    return 0;
  }

  return needCount - supplyList.reduce((acc, supply) => acc + supply.count, 0);
};

export const getNewSupplyDefaultDeliveryDate = (needDeliveryDate?: string) => {
  const today = dayjs().locale('ru').format(EDateFormat.FullDateReverse);

  if (!needDeliveryDate) {
    return today;
  }

  return dayjs(needDeliveryDate).isSameOrAfter(today) ? needDeliveryDate : today;
};

export const updateSupplyListState = {
  setSupplyList: (state: ISupplyListState, payload: ISupply[]) => ({ ...state, data: payload }),
  setSupplyListCount: (state: ISupplyListState, payload: number) => ({ ...state, count: payload }),
  setSupplyListLoading: (state: ISupplyListState, payload: boolean) => ({ ...state, loading: payload }),
  setSupplyListUserList: (state: ISupplyListState, payload: ISupplyUser[]) => ({ ...state, userList: payload }),
  setSupplyListSubdivisionList: (state: ISupplyListState, payload: ISupplySubdivision[]) => ({
    ...state,
    subdivisionList: payload,
  }),
  updateSupplyList: (state: ISupplyListState, payload: ISupply[]) => {
    return {
      ...state,
      data: state.data.map((supply) => {
        const item = payload.find((supplyItem) => supplyItem.id === supply.id);

        return item ? item : supply;
      }),
    };
  },
  filterSupplyList: (state: ISupplyListState, payload: number[]) => {
    return {
      ...state,
      data: state.data.filter((supply) => !payload.includes(supply.id)),
      count: state.count - payload.length,
    };
  },
  addSupply: (state: ISupplyListState, payload: ISupply) => {
    return {
      ...state,
      data: [...state.data, payload],
    };
  },
  deleteSupply: (state: ISupplyListState, payload: number) => {
    return {
      ...state,
      data: state.data.filter((supply) => supply.id !== payload),
    };
  },
};

export const getSupplyOrderInfo = (list: ISupply[]) => {
  const selectedItemCount = list.reduce((acc, curr) => acc + curr.count, 0);
  const selectedItemAmount = list.reduce((acc, curr) => acc + curr.price * curr.count, 0);
  const vat = list.reduce((acc, curr) => acc + (curr.priceWithTaxes - curr.price) * curr.count, 0);
  const selectedItemAmountWithVat = selectedItemAmount + vat;

  return { selectedItemCount, selectedItemAmount, vat, selectedItemAmountWithVat };
};

export const getSupplyStatus = (status?: string) => {
  return {
    supplyCreated: status === ESupplyStatus.Created,
    supplyPending: status === ESupplyStatus.Pending,
    supplyApproved: status === ESupplyStatus.Approved,
    supplyPlaced: status === ESupplyStatus.Placed,
    supplyInProcess: status === ESupplyStatus.Processing,
    supplyInDelivery: status === ESupplyStatus.Delivery,
    supplyDelivered: status === ESupplyStatus.Delivered,
    supplyCancelled: status === ESupplyStatus.Cancelled,
  };
};

export const getSupplyChangeStatusMenuItems = (onStatusChange: (value: ESupplyStatus) => void, status?: string) => {
  const { supplyPlaced, supplyInProcess, supplyInDelivery, supplyDelivered } = getSupplyStatus(status);

  return [
    {
      label: (
        <Button
          onClick={() => onStatusChange(ESupplyStatus.Processing)}
          disabled={supplyInProcess || supplyInDelivery || supplyDelivered}
        >
          {ESupplyStatusLabel.processing}
        </Button>
      ),
      key: ESupplyStatus.Processing,
    },
    {
      label: (
        <Button
          onClick={() => onStatusChange(ESupplyStatus.Delivery)}
          disabled={supplyPlaced || supplyInDelivery || supplyDelivered}
        >
          {ESupplyStatusLabel.delivery}
        </Button>
      ),
      key: ESupplyStatus.Delivery,
    },
    {
      label: (
        <Button
          onClick={() => onStatusChange(ESupplyStatus.Delivered)}
          disabled={supplyPlaced || supplyInProcess || supplyDelivered}
        >
          {ESupplyStatusLabel.delivered}
        </Button>
      ),
      key: ESupplyStatus.Delivered,
    },
    {
      label: <Button onClick={() => onStatusChange(ESupplyStatus.Cancelled)}>{ESupplyStatusLabel.cancelled}</Button>,
      key: ESupplyStatus.Cancelled,
    },
  ];
};

export const getNewSupplyStatus = (status: string) => {
  switch (status) {
    case ESupplyStatus.Created: {
      return ESupplyStatus.Pending;
    }
    case ESupplyStatus.Pending: {
      return ESupplyStatus.Approved;
    }
    case ESupplyStatus.Approved: {
      return ESupplyStatus.Placed;
    }
    case ESupplyStatus.Placed: {
      return ESupplyStatus.Processing;
    }
    case ESupplyStatus.Processing: {
      return ESupplyStatus.Delivery;
    }
    default: {
      return ESupplyStatus.Delivered;
    }
  }
};
