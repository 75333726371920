import React, { FC } from 'react';
import { Button, Input, Modal, Select, Spin } from 'antd';
import { ESupplyCorrectionValue } from 'common/const/enum';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';
import { supplyCorrectionOptions } from 'entities/Supply/Supply.const';

interface IComponentProps {
  open: boolean;
  loading: boolean;
  reason: ESupplyCorrectionValue | null;
  comment: string;
  onReasonChange: (value: ESupplyCorrectionValue) => void;
  onCommentChange: (value: string) => void;
  onCancelClick: () => void;
  onSendClick: () => void;
}

export const RequestCorrectionModal: FC<IComponentProps> = (props) => {
  const { open, loading, reason, comment, onReasonChange, onCommentChange, onCancelClick, onSendClick } = props;

  return (
    <Modal className="modal-gray" open={open} footer={false} closeIcon={false} width={360}>
      <Spin spinning={loading}>
        <div className="modal-gray__body">
          <div className="modal-gray__title">Причина корректировки</div>

          <Select
            value={reason}
            onChange={onReasonChange}
            options={supplyCorrectionOptions}
            style={{ marginBottom: 16 }}
            placeholder="Выберите причину"
            suffixIcon={<ChevronIcon />}
          />

          <div className="modal-gray__title">Укажите, что нужно подкорректировать</div>

          <Input.TextArea value={comment} onChange={(e) => onCommentChange(e.target.value)} placeholder="Комментарий" rows={4} />
        </div>

        <div className="modal-gray__footer">
          <Button className="btn btn-default" onClick={onCancelClick}>
            Отмена
          </Button>

          <Button className="btn btn-primary" onClick={onSendClick} disabled={!reason}>
            Отправить
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};
