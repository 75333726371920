import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { uploadExcelFile } from 'common/helpers/file';
import { destroyMessage, showLoadingMessage, showSuccessMessage } from 'common/helpers/message.helper';
import { IRootModel } from 'app/store';
import { needTransport } from 'entities/Need/Need.transport';
import {
  INeed,
  INeedChangeStatusPayload,
  INeedHistoryItem,
  INeedHistoryListPayload,
  INeedHistoryListState,
  INeedListPayload,
  INeedListState,
  INeedState,
  INeedSubdivisionListParams,
  INeedUpdatePayload,
  INeedUserListParams,
} from 'entities/Need/Need.models';
import { updateNeedListState } from 'entities/Need/Need.helper';

export const needListState = createModel<IRootModel>()({
  state: {
    data: [],
    count: 0,
    loading: false,
    userList: [],
    subdivisionList: [],
  } as INeedListState,
  reducers: {
    setNeedList: updateNeedListState.setNeedList,
    setNeedListCount: updateNeedListState.setNeedListCount,
    setNeedListLoading: updateNeedListState.setNeedListLoading,
    setNeedUserList: updateNeedListState.setNeedUserList,
    setNeedSubdivisionList: updateNeedListState.setNeedSubdivisionList,
    filterNeedList: updateNeedListState.filterNeedList,
    updateNeedList: updateNeedListState.updateNeedList,
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    async getNeedList(payload: INeedListPayload) {
      dispatch.needListState.setNeedListLoading(true);

      try {
        const response = await needTransport.getNeedList(payload);
        dispatch.needListState.setNeedList(response.data);
        dispatch.needListState.setNeedListCount(response.count);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.needListState.setNeedListLoading(false);
      }
    },
    async getNeedUserList(params: INeedUserListParams) {
      dispatch.needListState.setNeedListLoading(true);

      try {
        const response = await needTransport.getNeedUserList(params);
        dispatch.needListState.setNeedUserList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.needListState.setNeedListLoading(false);
      }
    },
    async changeNeedStatus(payload: INeedChangeStatusPayload) {
      dispatch.needListState.setNeedListLoading(true);

      try {
        const response = await needTransport.changeNeedStatus(payload);
        dispatch.needListState.updateNeedList(response.data);
        dispatch.needListState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.needListState.setNeedListLoading(false);
      }
    },
    async uploadNeedExcel(payload: INeedListPayload) {
      dispatch.needListState.setNeedListLoading(true);

      try {
        const response = await needTransport.uploadNeedExcel(payload);
        uploadExcelFile(response, `supply_need_list_${Date.now()}`);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.needListState.setNeedListLoading(false);
      }
    },
    async getNeedSubdivisionListForManager(params: INeedSubdivisionListParams) {
      dispatch.needListState.setNeedListLoading(true);

      try {
        const response = await needTransport.getNeedSubdivisionListForManager(params);
        dispatch.needListState.setNeedSubdivisionList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.needListState.setNeedListLoading(false);
      }
    },
    async getNeedSubdivisionListForSeller(buyerId?: number) {
      dispatch.needListState.setNeedListLoading(true);

      try {
        const response = await needTransport.getNeedSubdivisionListForSeller(buyerId);
        dispatch.needListState.setNeedSubdivisionList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.needListState.setNeedListLoading(false);
      }
    },
  }),
});

export const needState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as INeedState,
  reducers: {
    setNeed: (state, payload: INeed) => ({ ...state, data: payload }),
    setNeedLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getNeedById(id: number) {
      dispatch.needState.setNeedLoading(true);

      try {
        const response = await needTransport.getNeedById(id);
        dispatch.needState.setNeed(response);
        return response;
      } catch (error) {
        axiosErrorHandler(error);
        return null;
      } finally {
        dispatch.needState.setNeedLoading(false);
      }
    },
    async updateNeed(payload: INeedUpdatePayload) {
      dispatch.needState.setNeedLoading(true);
      showLoadingMessage(0);

      try {
        const response = await needTransport.updateNeed(payload);
        dispatch.needListState.updateNeedList(response.data);
        showSuccessMessage();
      } catch (error) {
        destroyMessage();
        axiosErrorHandler(error);
      } finally {
        dispatch.needState.setNeedLoading(false);
      }
    },
  }),
});

export const needHistoryListState = createModel<IRootModel>()({
  state: {
    data: [],
    count: 0,
    loading: false,
  } as INeedHistoryListState,
  reducers: {
    setNeedHistoryList: (state, payload: INeedHistoryItem[]) => ({ ...state, data: payload }),
    setNeedHistoryListCount: (state, payload: number) => ({ ...state, count: payload }),
    setNeedHistoryListLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getNeedHistoryList(payload: INeedHistoryListPayload) {
      dispatch.needHistoryListState.setNeedHistoryListLoading(true);

      try {
        const response = await needTransport.getNeedHistoryList(payload);
        dispatch.needHistoryListState.setNeedHistoryList(response.data);
        dispatch.needHistoryListState.setNeedHistoryListCount(response.count);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.needHistoryListState.setNeedHistoryListLoading(false);
      }
    },
  }),
});
