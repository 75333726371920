import React, { FC } from 'react';
import { Button, Image } from 'antd';
import emptyPlaceholder from 'app/assets/images/redesign/empty-state.svg';

interface IComponentProps {
  open: boolean;
  title: string;
  description: string;
  btnTitle?: string;
  onBack?: () => void;
}

export const CatalogEmpty: FC<IComponentProps> = ({ open, title, description, btnTitle, onBack }) => {
  if (!open) {
    return null;
  }

  return (
    <div className="catalog__empty">
      <Image wrapperClassName="image-160" src={emptyPlaceholder} preview={false} />

      <div className="catalog__empty-container">
        <span className="text-h2 color-black catalog__empty-title">{title}</span>

        <span className="text-h4 color-dark-grey catalog__empty-description">{description}</span>

        {btnTitle && onBack && (
          <Button className="button-l secondary" onClick={onBack}>
            {btnTitle}
          </Button>
        )}
      </div>
    </div>
  );
};
