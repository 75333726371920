import { DEFAULT_DELIVERY_IN_DAYS, PHONE_PATTERN, PRICE_PATTERN } from 'common/config';

export const countFormatter = (value?: number) => `${value} шт`;

export const phoneFormatter = (value?: string) => `${value?.replace(PHONE_PATTERN, '$1 $2 $3 $4 $5')}`;

export const priceFormatter = (price?: number) => (price ? `${price.toFixed(2).replace(PRICE_PATTERN, ' ')} ₽` : '0 ₽');

export const positionFormatter = (count: number) => {
  const countArray = Array.from(String(count)).map((num) => Number(num));
  const lastDigit = countArray[countArray.length - 1];
  const penultimateDigit = countArray[countArray.length - 2];

  switch (true) {
    case lastDigit === 1 && lastDigit === penultimateDigit:
    case lastDigit > 1 && lastDigit < 5 && penultimateDigit === 1:
    case lastDigit > 4 && lastDigit < 10:
    case lastDigit === 0: {
      /*
      1. Numbers including 11
      2. Numbers including 12, 13, 14
      3. Numbers ending in 5, 6, 7, 8, 9
      4. Numbers ending in 0
      */
      return `${count} позиций`;
    }
    case lastDigit > 1 && lastDigit < 5 && penultimateDigit !== 1: {
      // Numbers ending in 2, 3, 4, excluding numbers including 12, 13, 14
      return `${count} позиции`;
    }
    default: {
      // Numbers ending in 1, excluding numbers including 11
      return `${count} позиция`;
    }
  }
};

export const discountFormatter = (value?: number) => `${value}%`;

export const deliveryTimeFormatter = {
  full: (deliveryInDays: number) => {
    switch (true) {
      case deliveryInDays === 1: {
        return `${deliveryInDays} день`;
      }
      case deliveryInDays >= 2 && deliveryInDays <= 4: {
        return `${deliveryInDays} дня`;
      }
      case deliveryInDays >= 5 && deliveryInDays <= 6: {
        return `${deliveryInDays} дней`;
      }
      case deliveryInDays === 7: {
        return '1 неделя';
      }
      case deliveryInDays > 7 && deliveryInDays <= 14: {
        return '2 недели';
      }
      case deliveryInDays > 14 && deliveryInDays <= 21: {
        return '3 недели';
      }
      case deliveryInDays > 21 && deliveryInDays <= 28: {
        return '1 месяц';
      }
      case deliveryInDays > 28 && deliveryInDays <= 56: {
        return '2 месяца';
      }
      case deliveryInDays > 56 && deliveryInDays <= 84: {
        return '3 месяца';
      }
      default: {
        return 'Доступно под заказ';
      }
    }
  },
  short: (deliveryInDays?: number) => {
    if (!deliveryInDays) {
      return `${DEFAULT_DELIVERY_IN_DAYS} дн.`;
    }

    const daysInWeek = 7;
    const daysInMonth = 30;

    switch (true) {
      case deliveryInDays >= daysInMonth: {
        const months = Math.floor(deliveryInDays / daysInMonth);
        return `${months} мес.`;
      }
      case deliveryInDays >= daysInWeek: {
        const weeks = Math.floor(deliveryInDays / daysInWeek);
        return `${weeks} нед.`;
      }
      default: {
        return `${deliveryInDays} дн.`;
      }
    }
  },
};
