import React, { FC, useState } from 'react';
import { Button, Drawer } from 'antd';
import { ReactComponent as BinIcon } from 'app/assets/images/redesign/bin.svg';

interface IComponentProps {
  id: number;
  loading: boolean;
  onDelete: (id: number, onSuccess: () => void) => void;
}

export const BasketDeleteGoodsButton: FC<IComponentProps> = ({ id, loading, onDelete }) => {
  const [open, setOpen] = useState<boolean>(false);

  const toggleOpen = () => setOpen((prev) => !prev);

  const handleDeleteClick = () => {
    onDelete(id, toggleOpen);
  };

  return (
    <>
      <Button className="button-text" onClick={toggleOpen}>
        <span>Удалить товар</span>

        <BinIcon />
      </Button>

      <Drawer
        rootClassName="redesign basket-delete-goods-button__drawer"
        open={open}
        onClose={!loading ? toggleOpen : undefined}
        width={720}
      >
        <div className="basket-delete-goods-button__drawer-container">
          <span className="text-h1 basket-delete-goods-button__drawer-title">Вы уверены, что хотите удалить товар?</span>

          <span className="text-h3 color-dark-grey">Отменить данное действие будет невозможно.</span>
        </div>

        <div className="basket-delete-goods-button__drawer-footer">
          <Button className="button-l danger" onClick={handleDeleteClick} loading={loading}>
            Удалить
          </Button>

          <Button className="button-l secondary" onClick={toggleOpen} disabled={loading}>
            Отмена
          </Button>
        </div>
      </Drawer>
    </>
  );
};
