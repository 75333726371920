/* eslint-disable sonarjs/no-duplicate-string */
export enum ERoute {
  Activation = '/activation',
  Login = '/login',
  SendRestorePasswordEmail = '/send-restore-password-email',
  RestorePasswordConfirmation = '/restore-password-confirmation',
  RestorePassword = '/restore-password',
  Workspace = '/workspace/:id',
  WorkspacePosition = '/workspace-position/:mode',
  Shop = '/shop',
  Listing = '/listing',
  PreviousRequests = '/previous-requests',
  SpecificationList = '/specification-list',
  Specification = '/specification/:id',
  QrfpPosition = '/qrfp-position/:mode',
  QrfpList = '/qrfp-list',
  ClientsQrfpList = '/clients-qrfp-list',
  Admin = '/admin/:tab',
  // Redesign
  // Super Admin
  CatalogList = '/catalog-list',
  CatalogListItem = '/catalog-list/:id',
  PropertiesSettings = '/properties-settings',
  AccountList = '/account-list',
  ContractList = '/contract-list',
  // Admin
  Settings = '/settings',
  // Seller Manager
  SellerNeedList = '/seller-need-list',
  ActiveBasketList = '/active-basket-list',
  ActiveBasket = '/active-basket-list/:id',
  SellerContractList = '/seller-contract-list',
  Contract = '/seller-contract-list/:id',
  DeliveryForContract = '/seller-contract-list/:id/delivery/:buyerId',
  // Manager
  ManagerNeedList = '/manager-need-list',
  // Common
  Catalog = '/catalog',
  Basket = '/basket',
  NeedList = '/need-list',
  Profile = '/profile',
}

export enum EUserRole {
  User = 'user',
  Manager = 'manager',
  SellerManager = 'seller_manager',
  AccountAdmin = 'account_admin',
  SuperAdmin = 'super_admin',
}

export enum EUserRoleLabel {
  user = 'Пользователь',
  manager = 'Закупщик',
  seller_manager = 'Аккаунт-менеджер',
  account_admin = 'Администратор аккаунта',
  super_admin = 'Администратор системы',
}

export enum EErrorStatus {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  UnprocessableEntity = 422,
  InternalServerError = 500,
}

export enum EFormFieldMessage {
  RequiredField = 'Заполните поле',
  InvalidEmailFormat = 'Неверный формат электронной почты',
  InvalidPasswordFormat = 'Пароль должен иметь длину не менее 8 символов и содержать хотя бы одну заглавную букву, одну строчную букву и один специальный символ',
  InvalidPhoneFormat = 'Неверный формат телефона',
  PasswordMismatch = 'Пароли не совпадают',
  PasswordsMatch = 'Текущий и новый пароли совпадают',
  CharacterLimitMin = 'Не менее 3 символов',
  InvalidRange = 'Некорректный диапазон',
  LegalInnLength = 'ИНН должен быть равен 10 символам',
  LegalKppLength = 'КПП должен быть равен 9 символам',
  PropertyCascaderCharacterLimitMin = 'Не менее 1 символа',
}

export enum EAccountStatus {
  Active = 'active',
  Blocked = 'blocked',
}

export enum EAccountStatusTitle {
  active = 'Активный',
  blocked = 'Блокирован',
}

export enum ESettingsMenuTab {
  Account = 'account',
  Subdivisions = 'subdivisions',
  Users = 'users',
  LegalEntities = 'legal-entities',
  Discounts = 'discounts',
  Stocks = 'stocks',
  Integration = 'integration',
  AccountList = 'account-list',
}

export enum EAccountTariffPlanTitle {
  free = 'Бесплатный',
}

export enum EModalCollectionKey {
  CreateAccount = 'createAccountModalParams',
  BlockAccount = 'blockAccountModalParams',
  DeleteAccount = 'deleteAccountModalParams',
  ChangePassword = 'changePasswordModalParams',
  CreateSubdivision = 'createSubdivisionModalParams',
  DeleteSubdivision = 'deleteSubdivisionModalParams',
  ChangeSubdivisionLimit = 'changeSubdivisionLimitModalParams',
  SelectUser = 'selectUserModalParams',
  InviteUser = 'inviteUserModalParams',
  ChangeSubdivisionUser = 'changeSubdivisionUserModalParams',
  ExcludeSubdivisionUser = 'excludeSubdivisionUserModalParams',
  CreateSubdivisionAddress = 'createSubdivisionAddressModalParams',
  DeleteSubdivisionAddress = 'deleteSubdivisionAddressModalParams',
  CreateSubdivisionWorkspace = 'createSubdivisionWorkspaceModalParams',
  DeleteSubdivisionWorkspace = 'deleteSubdivisionWorkspaceModalParams',
  SelectSubdivision = 'selectSubdivisionModalParams',
  SelectWorkspace = 'selectWorkspaceModalParams',
  DeleteUser = 'deleteUserModalParams',
  SelectManager = 'selectManagerModalParams',
  SelectTemplate = 'selectTemplateModalParams',
  PositionUnsaved = 'positionUnsavedModalParams',
  DeleteWorkspacePosition = 'deleteWorkspacePositionModalParams',
  RepeatRequest = 'repeatRequestModalParams',
  ChangeSubdivisionCode = 'changeSubdivisionCodeModalParams',
  AddContract = 'addContractModalParams',
  LegalEntity = 'legalEntityModalParams',
  CreateDiscount = 'createDiscountModalParams',
  StockForm = 'stockFormModalParams',
  AddShippingStock = 'addShippingStockModalParams',
}

export enum ESearchParams {
  SubdivisionId = 'subdivisionId',
  UserId = 'userId',
  Frame = 'frame',
  WorkspaceId = 'workspaceId',
  PositionId = 'positionId',
  CategoryId = 'categoryId',
  BasketId = 'basketId',
  RequestId = 'requestId',
  QrfpId = 'qrfpId',
  IsAvailableForPurchase = 'isAvailableForPurchase',
  SellerId = 'sellerId',
  BuyerId = 'buyerId',
  ProductId = 'productId',
  Search = 'search',
  CatalogId = 'catalogId',
  Id = 'id',
}

export enum ESubdivisionFrame {
  Users = 'users',
  Addresses = 'addresses',
  Workspaces = 'workspaces',
}

export enum EUserStatus {
  Active = 'active',
  NotActive = 'not_active',
}

export enum EUserStatusLabel {
  active = 'Активирован',
  not_active = 'Не активирован',
}

export enum EUserFilterOption {
  NotActive = 'not_active',
  WithoutSubdivision = 'without_subdivision',
}

export enum EUserFilterOptionLable {
  not_active = 'Не активированы',
  without_subdivision = 'Не привязаны к подразделению',
}

export enum EUserFrameRole {
  User = 'user',
  Manager = 'manager',
  AccountAdmin = 'account_admin',
  SellerManager = 'seller_manager',
}

export enum EPositionFormMode {
  Create = 'create',
  Edit = 'edit',
  Copy = 'copy',
  View = 'view',
}

export enum EPropertyType {
  Label = 'label',
  Number = 'number',
  List = 'list',
}

export enum EPresetTypeLabel {
  label = 'boolean',
  number = 'число',
  list = 'текст',
}

export enum EPresetType {
  NotApplicable = 'not_applicable',
  SingleValue = 'single_value',
  Range = 'range',
  MultipleValue = 'multiple_value',
  Checkbox = 'checkbox',
}

export enum EPresetTypeLabel {
  NotApplicable = 'Неприменимо',
  SingleValue = 'Единичное значение',
  Range = 'Диапазон',
  MultipleValue = 'Множественное значение',
  Checkbox = 'Флажок',
}

export enum ENotificationType {
  Warning = 'warning',
  Notification = 'notification',
}

export enum ECommonErrorMessage {
  UnexpectedError = 'Unexpected Error',
}

export enum EDateFormat {
  FullDateSlashSeparator = 'DD/MM/YYYY',
  FullDateDotSeparator = 'DD.MM.YYYY',
  FullDateReverse = 'YYYY-MM-DD',
  FullDate = 'DD MMMM YYYY',
  ShortDate = 'DD MMMM',
  FullDateWithTime = 'DD.MM.YYYY HH:mm',
}

export enum ESpecificationStatus {
  OnApproval = 'on_approval',
  Ordered = 'ordered',
}

export enum ESpecificationStatusLabel {
  on_approval = 'На согласовании',
  ordered = 'Заказ отправлен',
}

export enum EBasketStatus {
  Active = 'active',
  Completed = 'completed',
  Cancelled = 'cancelled',
}

export enum EPropertyFormType {
  AddPositionToWorkplaceForm = 'addPositionToWorkplaceForm',
  ListingFilterForm = 'listingFilterForm',
  AddGoodsForm = 'addGoodForm',
}

export enum EQrfpStatus {
  All = 'all',
  New = 'new',
  Completed = 'completed',
}

export enum EQrfpStatusLabel {
  all = '',
  new = 'Ожидает ответа',
  completed = 'Закрыт',
}

export enum EMessage {
  AccountIsNotLinkedToSubdivision = 'Ваш аккаунт не привязан ни к одному подразделению, обратитесь к администратору.',
  SubdivisionDoesNotHaveDeliveryAddresses = 'Подразделение не имеет адресов доставки, обратитесь к администратору.',
  MustSelectDeliveryAddress = 'Необходимо выбрать адрес доставки.',
  UnexpectedError = 'Произошла непредвиденная ошибка.',
  InvalidRefreshToken = 'Неверный рефреш токен.',
  NotAuthorized = 'Не авторизован',
  ClientsAccountWillBeBlocked = 'Аккаунт клиента будет заблокирован. При блокировке все пользователи, привязанные к Аккаунту клиента, потеряют доступ к данным в системе.',
  ClientsAccountWillBeDeleted = 'Аккаунт клиента будет удалён. Все данные будут потеряны. Вы уверены, что хотите удалить Аккаунт?',
  ManagerListIsEmpty = 'Список пуст. Создайте нового менеджера.',
  SelectedGoodsWillBeRemovedFromActiveBasket = 'Выбранные товары будут удалены из активной корзины.',
  YouCanIndicateDesiredDateForReceivingTheGoods = 'Вы можете указать желаемую дату получения товара. Система отметит товары, доставка которых невозможна в указанный срок.',
  NothingFound = 'Ничего не найдено.',
  WorkspaceListIsEmpty = 'Список пуст. Добавьте новую Карту рабочего места.',
  ThereAreAlreadyGoodsInActiveBasket = 'В Активной корзине уже есть товары. При подтверждении, текущие товары будут потеряны.',
  LetterWasSentSuccessfully = 'Письмо успешно отправлено',
  GoodsWillBeRemovedFromSpecification = 'Товар будет удален из спецификации.',
  SelectedGoodsWillBeRemovedFromSpecification = 'Выбранные товары будут удалены из спецификации.',
  SpecificationWillBeRemoved = 'При удалении последнего товара/ов спуцификация будет удалена.',
  InviteNewUser = 'Список пуст. Пригласите нового пользователя.',
  SubdivisionWillBeRemoved = 'Подразделение будет удалено. Все данные будут утеряны.',
  AddressListIsEmpty = 'Список пуст. Добавьте новый Адрес доставки.',
  AddressWillBeRemoved = 'Адрес будет удален из аккаунта.',
  AddRemoveUsersToSubdivision = 'Привязать/убрать пользователей в подразделение. Установить лимиты трат на покупки.',
  DeliveryAddressManagement = 'Управление адресами доставки, доступными для пользователей подразделения.',
  SetUpNeedForGoods = 'Настроить потребность в товарах на рабочих местах.',
  AddNewUser = 'Список пуст. Добавьте нового Пользователя.',
  UserWillBeRemovedFromSubdivision = 'Пользователь будет исключен из подразделения.',
  WorkspaceWillBeRemovedFromAccount = 'Карта рабочего места будет удалена из Аккаунта.',
  UserWillBeRemovedFromAccount = 'Пользователь будет удален из аккаунта.',
  ChangesWereNotSaved = 'Изменения не были сохранены.',
  ImageUploadError = 'Произошла ошибка при загрузке изображения.',
  ListIsEmpty = 'Список пуст.',
  QRFPListIsEmpty = 'Список пуст. Создайте новый запрос.',
  ContractListForSuperAdminIsEmpty = 'Список пуст. Создайте новый контракт.',
  LegalEntityListIsEmpty = 'Список пуст. Создайте новое юридическое лицо.',
  DiscountListIsEmpty = 'Список пуст. Создайте новый вид скидок.',
  DiscountsOnBrandsListIsEmpty = 'Список пуст. Создайте новую скидку для бренда.',
  StockListIsEmpty = 'Список пуст. Создайте новый склад отгрузки.',
  ContractListForSellerManagerIsEmpty = 'Список контрактов пуст.',
  CatalogListIsEmpty = 'Список пуст. Создайте новый каталог.',
  SubdivisionDoesNotHaveLegalEntities = 'Подразделение не имеет юридических лиц, обратитесь к администратору.',
  MustSelectLegal = 'Необходимо выбрать юридическое лицо.',
  BasketIsEmpty = 'Корзина пуста. Добавьте товары из Магазина',
  IrreversibleEffect = 'Это действие необратимо, вы уверены?',
  RequestListIsEmpty = 'Список заявок пуст.',
  SupplyDeliveryDateIsRequired = 'Для перевода поставки в статус Потдверждено, необходимо указать дату поставки.',
  NoSupplySelected = 'Ни одна заявка не выбрана.',
  SupplyListIsEmpty = 'Список поставок пуст.',
  NeedHistoryIsEmpty = 'Список событий пуст.',
  DeliveryBadgeTooltip = 'Товар отсутствует на складе. Информация о поступлении товара будет предоставлена поставщиком позднее.',
  // Redesing
  GoodsHasBeenRemoved = 'Товар удален из корзины.',
  AllGoodsHaveBeenRemoved = 'Все товары удалены из корзины.',
  DeliveryAddressSuccessfullyChanged = 'Адрес доставки успешно изменен.',
  LegalSuccessfullyChanged = 'Юридическое лицо успешно изменено.',
}

export enum EPlaceholder {
  SearchGoodsByNameOrVendorCode = 'Искать по названию или артикулу товара',
  AddVendorCode = 'Добавить артикул',
  SelectAddress = 'Выберите адрес',
  SelectLegal = 'Выберите юр.лицо',
}

export enum EAddressModalTabKey {
  AutomaticSearch = 'automaticSearch',
  ManualInput = 'manualInput',
}

export enum EShopSearchType {
  Goods = 'goods',
  Category = 'category',
}

export enum EPropertyCascaderItemType {
  Properties = 'properties',
  Options = 'options',
  All = 'all',
}

export enum EStorageKey {
  Creds = 'creds',
  SuperAdminCreds = 'super-admin-creds',
  XUserId = 'x-user-id',
  XBasketId = 'x-basket-id',
  UserCatalogId = 'user-сatalog-id',
}

export enum ENeedStatus {
  Created = 'created',
  Pending = 'pending',
  Approved = 'approved',
  Cancelled = 'cancelled',
}

export enum ENeedStatusLabel {
  created = 'Заявка отправлена',
  pending = 'В работе',
  approved = 'Обработана',
  cancelled = 'Отменена',
}

export enum EBasketMenuTab {
  NeedList = 'need-list',
  SupplyList = 'supply-list',
}

export enum EManagerNeedListMenuTab {
  Pending = 'pending',
  SupplyList = 'supply-list',
}

export enum ESellerManagerNeedListMenuTab {
  SupplyList = 'supply-list',
}

export enum EColor {
  Gray = 'gray',
  Green = 'green',
  Red = 'red',
  Blue = 'blue',
  Orange = 'orange',
}

export enum ESupplyStatus {
  Created = 'created',
  Pending = 'pending',
  Approved = 'approved',
  Cancelled = 'cancelled',
  Placed = 'placed',
  Processing = 'processing',
  Delivery = 'delivery',
  Delivered = 'delivered',
}

export enum ESupplyStatusLabel {
  created = 'уточняется',
  pending = 'подтверждено',
  approved = 'заказ размещен',
  cancelled = 'отменено',
  placed = 'размещен в 1с',
  processing = 'обрабатывается',
  delivery = 'в пути',
  delivered = 'доставлен',
}

export enum ESupplyCorrectionLabel {
  price = 'Не устраивает цена',
  date = 'Не устраивает дата поставки',
}

export enum ESupplyCorrectionValue {
  Price = 'price',
  Date = 'date',
}

export enum ENeedHistoryType {
  CreateSupplyCorrection = 'createSupplyCorrection',
  CreateSupply = 'createSupply',
  CreateSupplyNeed = 'createSupplyNeed',
  ChangeSupplyStatus = 'changeSupplyStatus',
  ChangeSupplyNeedStatus = 'changeSupplyNeedStatus',
}

export enum EGoodsDeliveryStatus {
  InStock = 'in_stock',
  AvailableOnRequest = 'available_on_request',
  OutOfStock = 'out_of_stock',
}

export enum EGoodsDeliveryStatusLabel {
  in_stock = 'В наличии',
  available_on_request = 'Доступно под заказ',
  out_of_stock = 'Нет на складе',
}
