import React, { FC, useState } from 'react';
import { Button, Checkbox, Image } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { Link } from 'react-router-dom';
import { Counter } from 'common/components/Counter';
import { ThreeDotMenu } from 'common/components/ThreeDotMenu';
import { IGoodsItemOptions } from 'common/models';
import { countFormatter, priceFormatter } from 'common/helpers/formatter.helper';
import { EDateFormat, ENeedStatus, ENeedStatusLabel, ESupplyStatus, ESupplyStatusLabel } from 'common/const/enum';
import { getGoodsItemStatusColor } from 'common/helpers/data.helper';
import { getSupplyStatusColor } from 'entities/Supply/Supply.helper';

interface IComponentProps {
  id: number;
  options: IGoodsItemOptions;
  changeChecked?: (id: number, value: boolean) => void;
  changeCount?: (id: number, value: number) => void;
  onDelete?: (id: number) => void;
  onItemClick?: (id: number) => void;
}

export const GoodsItem: FC<IComponentProps> = (props) => {
  const { id, options, changeChecked, changeCount, onDelete, onItemClick } = props;

  const {
    canBeSelected,
    showVendorCode,
    showCounter,
    showCount,
    showTotalAmount,
    showNeedStatus,
    showSupplyStatus,
    showDeliveryTime,
    showDeliveryDate,
    showPendingSuppliesCount,
    showApprovedSuppliesCount,
    showDeleteBtn,
    showUserName,
    showSubdivisionName,
    showCompanyName,
    showCity,
    showMenu,
    showOrderNumber,
    showLink,
    checked,
    image,
    vendorCode,
    count,
    price,
    status,
    deliveryTime,
    deliveryDate,
    pendingSuppliesCount,
    approvedSuppliesCount,
    name,
    userName,
    subdivisionName,
    companyName,
    city,
    menuItems,
    orderNumber,
    link = '',
    isUnavailable,
  } = options;

  const [countValue, setCountValue] = useState<number | undefined>(count);

  const onCountChange = (value: number) => {
    setCountValue(value);
    changeCount?.(id, value);
  };

  const onDeleteClick = () => {
    onDelete?.(id);
  };

  const handleItemClick = () => {
    onItemClick?.(id);
  };

  return (
    <div className="goods-item" style={{ backgroundColor: isUnavailable ? '#F6B7B6' : '' }}>
      {canBeSelected && (
        <Checkbox className="goods-item__checkbox" checked={checked} onChange={(e) => changeChecked?.(id, e.target.checked)} />
      )}

      <Image wrapperClassName="image image-w103 goods-item__image" src={image} preview={false} onClick={handleItemClick} />

      <div className="goods-item__container">
        <div className="goods-item__header">
          <div className="goods-item__header-left">
            {showVendorCode && (
              <div className="goods-item__vendor-code">
                <span>Артикул:</span>

                <span>{vendorCode}</span>
              </div>
            )}

            {showCounter && <Counter count={countValue} onChange={onCountChange} />}

            {showCount && (
              <div className="goods-item__total-amount">
                <span>{countFormatter(Number(countValue))}</span>
              </div>
            )}

            {showTotalAmount && (
              <div className="goods-item__total-amount">
                <span>Сумма:</span>

                <span>{priceFormatter(Number(price) * Number(countValue))}</span>
              </div>
            )}

            {showNeedStatus && (
              <div className={`status ${getGoodsItemStatusColor(status)}`}>{ENeedStatusLabel[status as ENeedStatus]}</div>
            )}
          </div>

          <div className="goods-item__header-right">
            {showSupplyStatus && (
              <div className={`status ${getSupplyStatusColor(status)}`}>{ESupplyStatusLabel[status as ESupplyStatus]}</div>
            )}

            {showMenu && <ThreeDotMenu items={menuItems} />}

            {showDeliveryTime && (
              <div className="goods-item__delivery-date">
                <span>Срок:</span>

                <span>{deliveryTime}</span>
              </div>
            )}

            {showDeliveryDate && (
              <div className="goods-item__delivery-date">
                <span>Срок:</span>

                <span>{deliveryDate ? dayjs(deliveryDate).locale('ru').format(EDateFormat.ShortDate) : 'Нет информации'}</span>
              </div>
            )}

            {showApprovedSuppliesCount && (
              <div className="goods-item__ordered-count">
                <span>{`Заказано ${approvedSuppliesCount} из ${countValue}`}</span>
              </div>
            )}

            {showPendingSuppliesCount && (
              <div className="goods-item__ordered-count">
                <span>{`Подтверждено ${pendingSuppliesCount} из ${countValue}`}</span>
              </div>
            )}
          </div>
        </div>

        <div className="goods-item__name" onClick={handleItemClick}>
          {name}
        </div>

        <div className="goods-item__footer">
          <div className="goods-item__footer-left">
            {showUserName && <span>{userName}</span>}

            {showSubdivisionName && <span>{subdivisionName}</span>}

            {showCompanyName && <span>{companyName}</span>}

            {showCity && <span>{city}</span>}
          </div>

          <div className="goods-item__footer-right">
            {showDeleteBtn && <Button className="btn btn-icon" icon={<CloseOutlined />} onClick={onDeleteClick} />}

            {showOrderNumber && orderNumber && <div>{`Номер заказа: ${orderNumber}`}</div>}

            {showLink && (
              <Link className="goods-item__link" to={link}>
                Ссылка на согласованную потребность
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
