import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Input, Pagination, Select, Spin } from 'antd';
import type { PaginationProps } from 'antd';
import { connect } from 'react-redux';
import { ContentLoader } from 'common/components/ContentLoader';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { EMessage, ESupplyStatus } from 'common/const/enum';
import { DEFAULT_LIST_LIMIT, DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE } from 'common/config';
import { GoodsItem } from 'common/components/GoodsItem';
import { useDebounce } from 'common/hooks/useDebounce';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';
import { ReactComponent as SearchIcon } from 'app/assets/images/search.svg';
import { ISupplyListPayload } from 'entities/Supply/Supply.models';
import { supplyListStatusFilterOptions } from 'entities/Supply/Supply.const';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const SupplyListForUserComponent: FC<AllType> = (props) => {
  const { supplyListState, authState, getSupplyList, setSupplyList } = props;

  const { data: supplyList, loading: supplyListLoading, count: supplyListCount } = supplyListState;
  const { data: auth } = authState;

  const userId = auth?.access.userId;

  const [payload, setPayload] = useState<ISupplyListPayload>({
    userId,
    statuses: [ESupplyStatus.Approved],
    limit: DEFAULT_LIST_LIMIT,
    offset: DEFAULT_LIST_OFFSET,
  });
  const [page, setPage] = useState<number>(DEFAULT_PAGINATION_PAGE);

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newPayload = { ...payload, search: e.target.value, offset: DEFAULT_LIST_OFFSET };

    setPayload(newPayload);
  };

  const onFilterStatusChange = async (value: ESupplyStatus) => {
    const newPayload = { ...payload, statuses: [value], offset: DEFAULT_LIST_OFFSET };

    setPayload(newPayload);
    await getSupplyList(newPayload);
    setPage(DEFAULT_PAGINATION_PAGE);
  };

  const onPageChange: PaginationProps['onChange'] = async (value) => {
    const newPayload = { ...payload, offset: (value - 1) * DEFAULT_LIST_LIMIT };

    setPayload(newPayload);
    await getSupplyList(newPayload);
    setPage(value);
  };

  useEffect(() => {
    return () => {
      setSupplyList([]);
    };
  }, []);

  useDebounce(async () => {
    await getSupplyList(payload);
    setPage(DEFAULT_PAGINATION_PAGE);
  }, [payload.search]);

  const { contentLoading } = useContentLoader(async () => {
    await getSupplyList(payload);
  });

  if (contentLoading) {
    return (
      <div className="supply-list">
        <ContentLoader />
      </div>
    );
  }

  return (
    <div className="supply-list">
      <div className="supply-list__content">
        <div className="supply-list__filter">
          <Input
            className="supply-list__filter-search"
            prefix={<SearchIcon />}
            placeholder="Search"
            value={payload.search}
            onChange={onSearchChange}
          />

          <div className="supply-list__filter-item">
            <span className="supply-list__filter-item-label">Статус</span>

            <Select
              suffixIcon={<ChevronIcon />}
              // @ts-ignore
              value={payload.statuses?.[0]}
              options={supplyListStatusFilterOptions}
              popupMatchSelectWidth={false}
              placeholder="Выберите статус"
              onChange={onFilterStatusChange}
            />
          </div>
        </div>

        <Spin spinning={supplyListLoading}>
          <>
            {supplyList.length ? (
              supplyList.map((supply) => {
                return (
                  <GoodsItem
                    key={supply.id}
                    id={supply.id}
                    options={{
                      showVendorCode: true,
                      showCount: true,
                      showTotalAmount: true,
                      showSupplyStatus: true,
                      showDeliveryDate: true,
                      showOrderNumber: true,
                      image: supply.good.image,
                      name: supply.good.name,
                      vendorCode: supply.good.vendorCode,
                      count: supply.count,
                      price: supply.price,
                      status: supply.status,
                      deliveryDate: supply.deliveryDate,
                      orderNumber: supply.specificationId,
                    }}
                  />
                );
              })
            ) : (
              <div className="supply-list__empty">{EMessage.RequestListIsEmpty}</div>
            )}

            <Pagination total={supplyListCount} current={page} pageSize={DEFAULT_LIST_LIMIT} onChange={onPageChange} />
          </>
        </Spin>
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  supplyListState: state.supplyListState,
  authState: state.authState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getSupplyList: dispatch.supplyListState.getSupplyList,
  setSupplyList: dispatch.supplyListState.setSupplyList,
});

export const SupplyListForUser = connect(mapState, mapDispatch)(SupplyListForUserComponent);
