import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { destroyMessage, showLoadingMessage, showSuccessMessage } from 'common/helpers/message.helper';
import { IRootModel } from 'app/store';
import { propertyTransport } from 'entities/Property/Property.transport';
import {
  IPropertyCascaderState,
  IPropertyCascaderItemCreateOptionPayload,
  IPropertyCascaderItemCreatePropertyPayload,
  IPropertyCascaderItemDeleteOptionPayload,
  IPropertyCascaderItemSelectPropertyPayload,
  IPropertyCascaderItemSubscribePropertyPayload,
  IPropertyCascaderItemUnsubscribePropertyPayload,
  IPropertyListState,
  IPropertyListParams,
  IProperty,
  IPropertyTreeState,
  IPropertyUpdatePayload,
  ISubcategoryCreatePayload,
  ISubcategoryDeletePayload,
  IPropertyCascaderItemSelectOptionPayload,
  IPropertyCascaderItemDeletePropertyPayload,
} from 'entities/Property/Property.models';
import {
  createCascaderItem,
  getPropertyPayload,
  updatePropertyCascaderState,
  updatePropertyListState,
} from 'entities/Property/Property.helper';

export const propertyListState = createModel<IRootModel>()({
  state: {
    data: [],
    count: 0,
    loading: false,
  } as IPropertyListState,
  reducers: {
    setPropertyList: (state, payload: IProperty[]) => ({ ...state, data: payload }),
    setPropertyListCount: (state, payload: number) => ({ ...state, count: payload }),
    setPropertyListLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    addProperty: updatePropertyListState.addProperty,
    updateProperty: updatePropertyListState.updateProperty,
    deleteProperty: updatePropertyListState.deleteProperty,
    updatePropertyWithParent: updatePropertyListState.updatePropertyWithParent,
  },
  effects: (dispatch) => ({
    async getPropertyList(params: IPropertyListParams) {
      dispatch.propertyListState.setPropertyListLoading(true);

      try {
        const response = await propertyTransport.getPropertyList(params);
        dispatch.propertyListState.setPropertyList(response.data);
        dispatch.propertyListState.setPropertyListCount(response.count);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.propertyListState.setPropertyListLoading(false);
      }
    },
    async getPropertyListByCategoryId(categoryId: number) {
      dispatch.propertyListState.setPropertyListLoading(true);

      try {
        const response = await propertyTransport.getPropertyListByCategoryId(categoryId);
        dispatch.propertyListState.setPropertyList(response.data);
        return response.data;
      } catch (error) {
        axiosErrorHandler(error);
        return null;
      } finally {
        dispatch.propertyListState.setPropertyListLoading(false);
      }
    },
  }),
});

export const propertyCascaderState = createModel<IRootModel>()({
  state: {
    data: [],
    selectedPropertyId: null,
    error: null,
  } as IPropertyCascaderState,
  reducers: {
    setCascaderItems: updatePropertyCascaderState.setCascaderItems,
    addCascaderItem: updatePropertyCascaderState.addCascaderItem,
    filterCascaderItems: updatePropertyCascaderState.filterCascaderItems,
    changeCascaderItemSelectedPropertyId: updatePropertyCascaderState.changeCascaderItemSelectedPropertyId,
    changeCascaderItemSelectedOptionId: updatePropertyCascaderState.changeCascaderItemSelectedOptionId,
    setCascaderItemError: updatePropertyCascaderState.setCascaderItemError,
    setCascaderSelectedPropertyId: updatePropertyCascaderState.setCascaderSelectedPropertyId,
    setCascaderError: updatePropertyCascaderState.setCascaderError,
    updateCascaderItemWithOptions: updatePropertyCascaderState.updateCascaderItemWithOptions,
    deleteCascaderItemWithProperties: updatePropertyCascaderState.deleteCascaderItemWithProperties,
    updateCascaderItemWithProperties: updatePropertyCascaderState.updateCascaderItemWithProperties,
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    selectCascaderItemProperty(payload: IPropertyCascaderItemSelectPropertyPayload, models) {
      dispatch.propertyCascaderState.changeCascaderItemSelectedPropertyId(payload);

      const property = models.propertyListState.data.find((propertyItem) => propertyItem.id === payload.propertyId);

      if (property) {
        const cascaderItemWithOptions = createCascaderItem.itemWithOptions(property);
        dispatch.propertyCascaderState.addCascaderItem(cascaderItemWithOptions);
        dispatch.propertyCascaderState.setCascaderError(null);
      }
    },
    async subscribeCascaderItemProperty(payload: IPropertyCascaderItemSubscribePropertyPayload, models) {
      const { propertyId, parentId, parentValue, cascaderItemId } = payload;

      const property = models.propertyListState.data.find((propertyItem) => propertyItem.id === propertyId) as IProperty;

      showLoadingMessage(0);

      try {
        const updatePayload = getPropertyPayload.addPropertyShowConditionValue(property, parentId, parentValue);
        const updatedProperty = await propertyTransport.updateProperty(updatePayload);
        dispatch.propertyListState.updateProperty(updatedProperty);

        const cascaderItemWithOptions = createCascaderItem.itemWithOptions(property);
        dispatch.propertyCascaderState.changeCascaderItemSelectedPropertyId({ propertyId: property.id, cascaderItemId });
        dispatch.propertyCascaderState.addCascaderItem(cascaderItemWithOptions);

        dispatch.propertyCascaderState.onSuccess(payload);
        showSuccessMessage();
      } catch (error) {
        destroyMessage();
        axiosErrorHandler(error);
      }
    },
    async unsubscribeCascaderItemProperty(payload: IPropertyCascaderItemUnsubscribePropertyPayload, models) {
      const { propertyId, parentId, parentValue, cascaderItemId, selectedPropertyId } = payload;

      const property = models.propertyListState.data.find((propertyItem) => {
        return propertyItem.id === propertyId;
      }) as IProperty;

      showLoadingMessage(0);

      try {
        const updatePayload = getPropertyPayload.deletePropertyShowConditionValue(property, parentId, parentValue);
        const updatedProperty = await propertyTransport.updateProperty(updatePayload);
        dispatch.propertyListState.updateProperty(updatedProperty);

        if (propertyId === selectedPropertyId) {
          dispatch.propertyCascaderState.filterCascaderItems(cascaderItemId);
        }

        showSuccessMessage();
      } catch (error) {
        destroyMessage();
        axiosErrorHandler(error);
      }
    },
    async createCascaderItemProperty(payload: IPropertyCascaderItemCreatePropertyPayload) {
      const { name, parentId, parentValue, cascaderItemId } = payload;

      showLoadingMessage(0);

      try {
        const createPayload = getPropertyPayload.createPropertyWithShowCondition(name, parentId, parentValue);
        const response = await propertyTransport.createProperty(createPayload);
        dispatch.propertyListState.addProperty(response);

        const cascaderItemWithOptions = createCascaderItem.itemWithOptions(response);
        dispatch.propertyCascaderState.changeCascaderItemSelectedPropertyId({ propertyId: response.id, cascaderItemId });
        dispatch.propertyCascaderState.addCascaderItem(cascaderItemWithOptions);

        dispatch.propertyCascaderState.onSuccess(payload);
        showSuccessMessage();
      } catch (error) {
        destroyMessage();
        axiosErrorHandler(error, (message) => {
          dispatch.propertyCascaderState.setCascaderItemError({ error: message, cascaderItemId });
        });
      }
    },
    async updateCascaderItemProperty(payload: IPropertyUpdatePayload) {
      showLoadingMessage(0);

      try {
        const response = await propertyTransport.updateProperty(payload);
        dispatch.propertyListState.updateProperty(response);

        dispatch.propertyCascaderState.updateCascaderItemWithOptions(response);
        dispatch.propertyCascaderState.onSuccess(payload);
        showSuccessMessage();
      } catch (error) {
        destroyMessage();
        axiosErrorHandler(error, (message) => dispatch.propertyCascaderState.setCascaderError(message));
      }
    },
    async updateCascaderItemPropertyDisplayName(payload: IPropertyUpdatePayload, models) {
      const property = models.propertyListState.data.find((propertyItem) => {
        return propertyItem.id === payload.id;
      }) as IProperty;

      showLoadingMessage(0);

      try {
        if (property.isCategory && property.parentId) {
          const updatedProperty = await propertyTransport.updateProperty(payload);

          const parent = models.propertyListState.data.find((propertyItem) => {
            return propertyItem.id === property.parentId;
          }) as IProperty;
          const updateParentPayload = getPropertyPayload.updatePropertyValue(
            parent,
            property.displayName,
            payload.displayName as string,
          );
          const updatedParentProperty = await propertyTransport.updateProperty(updateParentPayload);

          dispatch.propertyListState.updatePropertyWithParent({ property: updatedProperty, parent: updatedParentProperty });
          dispatch.propertyCascaderState.updateCascaderItemWithProperties({
            property: updatedProperty,
            parent: updatedParentProperty,
          });

          dispatch.propertyCascaderState.onSuccess(payload);
        } else {
          const response = await propertyTransport.updateProperty(payload);
          dispatch.propertyListState.updateProperty(response);

          dispatch.propertyCascaderState.updateCascaderItemWithOptions(response);
          dispatch.propertyCascaderState.onSuccess(payload);
        }

        showSuccessMessage();
      } catch (error) {
        destroyMessage();
        axiosErrorHandler(error);
      }
    },
    async deleteCascaderItemProperty(payload: IPropertyCascaderItemDeletePropertyPayload) {
      const { propertyId, cascaderItem } = payload;

      showLoadingMessage(0);

      try {
        const response = await propertyTransport.deleteProperty({ id: propertyId });
        dispatch.propertyListState.deleteProperty(response.id);

        if (propertyId === cascaderItem.selectedPropertyId) {
          dispatch.propertyCascaderState.filterCascaderItems(cascaderItem.id);
          dispatch.propertyCascaderState.setCascaderSelectedPropertyId(null);
        }

        showSuccessMessage();
      } catch (error) {
        destroyMessage();
        axiosErrorHandler(error);
      }
    },
    selectCascaderItemOption(payload: IPropertyCascaderItemSelectOptionPayload) {
      const { valueId, value, cascaderItemId, cascaderItemPropertyId } = payload;

      dispatch.propertyCascaderState.changeCascaderItemSelectedOptionId({ valueId, cascaderItemId });

      const cascaderItemWithProperties = createCascaderItem.itemWithProperties(cascaderItemPropertyId, value);
      dispatch.propertyCascaderState.addCascaderItem(cascaderItemWithProperties);
      dispatch.propertyCascaderState.setCascaderError(null);
    },
    async createCascaderItemOption(payload: IPropertyCascaderItemCreateOptionPayload, models) {
      const { value, propertyId, cascaderItemId } = payload;

      const property = models.propertyListState.data.find((propertyItem) => {
        return propertyItem.id === propertyId;
      }) as IProperty;

      showLoadingMessage(0);

      try {
        if (property.isCategory) {
          const createPayload = getPropertyPayload.createSubcategory(value, propertyId);
          const createdProperty = await propertyTransport.createProperty(createPayload);
          dispatch.propertyListState.addProperty(createdProperty);
        }

        const updatePayload = getPropertyPayload.addPropertyValue(value, property);
        const updatedProperty = await propertyTransport.updateProperty(updatePayload);
        dispatch.propertyListState.updateProperty(updatedProperty);

        dispatch.propertyCascaderState.onSuccess(payload);
        showSuccessMessage();
      } catch (error) {
        destroyMessage();
        axiosErrorHandler(error, (message) =>
          dispatch.propertyCascaderState.setCascaderItemError({ error: message, cascaderItemId }),
        );
      }
    },
    async deleteCascaderItemOption(payload: IPropertyCascaderItemDeleteOptionPayload, models) {
      const { valueId, value, propertyId, cascaderItemId, selectedOptionId } = payload;

      const property = models.propertyListState.data.find((propertyItem) => {
        return propertyItem.id === propertyId;
      }) as IProperty;

      showLoadingMessage(0);

      try {
        if (property.isCategory) {
          const childProperty = models.propertyListState.data.find((propertyItem) => {
            return propertyItem.parentId === propertyId && propertyItem.displayName === value;
          }) as IProperty;
          const deletedProperty = await propertyTransport.deleteProperty({ id: childProperty.id });
          dispatch.propertyListState.deleteProperty(deletedProperty.id);
          dispatch.propertyCascaderState.deleteCascaderItemWithProperties(deletedProperty);
        }

        const updatePayload = getPropertyPayload.deletePropertyValue(value, property);
        const updatedProperty = await propertyTransport.updateProperty(updatePayload);
        dispatch.propertyListState.updateProperty(updatedProperty);

        if (valueId === selectedOptionId) {
          dispatch.propertyCascaderState.filterCascaderItems(cascaderItemId);
        }

        dispatch.propertyCascaderState.onSuccess(payload);
        showSuccessMessage();
      } catch (error) {
        destroyMessage();
        axiosErrorHandler(error, (message) =>
          dispatch.propertyCascaderState.setCascaderItemError({ error: message, cascaderItemId }),
        );
      }
    },
    createCascaderItemWithAllProperties() {
      const rootCascaderItem = createCascaderItem.itemWithAllProperties();
      dispatch.propertyCascaderState.setCascaderItems([rootCascaderItem]);
      dispatch.propertyCascaderState.setCascaderSelectedPropertyId(null);
    },
  }),
});

export const propertyTreeState = createModel<IRootModel>()({
  state: {
    loading: false,
    error: null,
  } as IPropertyTreeState,
  reducers: {
    setPropertyTreeLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    setPropertyTreeError: (state, payload: string | null) => ({ ...state, error: payload }),
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    selectSubcategory(id: number, models) {
      const property = models.propertyListState.data.find((propertyItem) => propertyItem.id === id);

      if (property) {
        const rootCascaderItem = createCascaderItem.rootItem(property);
        dispatch.propertyCascaderState.setCascaderItems([rootCascaderItem]);
        dispatch.propertyCascaderState.setCascaderSelectedPropertyId(null);
      }
    },
    async createSubcategory(payload: ISubcategoryCreatePayload, models) {
      const { name, parentId } = payload;

      dispatch.propertyTreeState.setPropertyTreeError(null);
      dispatch.propertyTreeState.setPropertyTreeLoading(true);
      showLoadingMessage(0);

      try {
        const createPayload = getPropertyPayload.createSubcategory(name, parentId);
        const createdProperty = await propertyTransport.createProperty(createPayload);
        dispatch.propertyListState.addProperty(createdProperty);

        if (parentId) {
          const parentProperty = models.propertyListState.data.find((property) => {
            return property.id === parentId;
          }) as IProperty;
          const updatePayload = getPropertyPayload.addPropertyValue(name, parentProperty);
          const updatedProperty = await propertyTransport.updateProperty(updatePayload);
          dispatch.propertyListState.updateProperty(updatedProperty);
        }

        dispatch.propertyTreeState.onSuccess(payload);
        showSuccessMessage();
      } catch (error) {
        destroyMessage();
        axiosErrorHandler(error, dispatch.propertyTreeState.setPropertyTreeError);
      } finally {
        dispatch.propertyTreeState.setPropertyTreeLoading(false);
      }
    },
    async deleteSubcategory(payload: ISubcategoryDeletePayload, models) {
      const { id, isSelected } = payload;

      dispatch.propertyTreeState.setPropertyTreeError(null);
      dispatch.propertyTreeState.setPropertyTreeLoading(true);
      showLoadingMessage(0);

      try {
        const deletedProperty = await propertyTransport.deleteProperty({ id });
        dispatch.propertyListState.deleteProperty(deletedProperty.id);

        if (deletedProperty.isCategory) {
          dispatch.propertyCascaderState.deleteCascaderItemWithProperties(deletedProperty);
        }

        if (isSelected) {
          dispatch.propertyCascaderState.setCascaderItems([]);
          dispatch.propertyCascaderState.setCascaderSelectedPropertyId(null);
        }

        if (deletedProperty.parentId) {
          const parentProperty = models.propertyListState.data.find((property) => {
            return property.id === deletedProperty.parentId;
          }) as IProperty;
          const updatePayload = getPropertyPayload.deletePropertyValue(deletedProperty.displayName, parentProperty);
          const updatedProperty = await propertyTransport.updateProperty(updatePayload);
          dispatch.propertyListState.updateProperty(updatedProperty);
        }

        dispatch.propertyTreeState.onSuccess(payload);
        showSuccessMessage();
      } catch (error) {
        destroyMessage();
        axiosErrorHandler(error, dispatch.propertyTreeState.setPropertyTreeError);
      } finally {
        dispatch.propertyTreeState.setPropertyTreeLoading(false);
      }
    },
  }),
});
