import React, { FC } from 'react';
import { Button, InputNumber } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { DEFAULT_COUNTER_MAX_VALUE } from 'common/config';
import { countFormatter } from 'common/helpers/formatter.helper';
import { getCounterWidthStyles } from 'common/helpers/date.helper';

interface IComponentProps {
  count?: number;
  max?: number;
  onChange: (value: number) => void;
}

export const Counter: FC<IComponentProps> = (props) => {
  const { count, max = DEFAULT_COUNTER_MAX_VALUE, onChange } = props;

  const minValue = 1;
  const formattedValue = countFormatter(count || minValue);

  const onDecrementClick = () => {
    if (count === minValue) {
      return;
    }

    onChange(Number(count) - 1);
  };

  const onIncrementClick = () => {
    if (count === max) {
      return;
    }

    onChange(Number(count) + 1);
  };

  const handleChange = (value: number | null) => {
    if (!value) {
      onChange(minValue);
      return;
    }

    if (value > max) {
      return;
    }

    onChange(value);
  };

  return (
    <div className="counter">
      <Button className="counter__btn decrement" icon={<MinusOutlined />} onClick={onDecrementClick} />

      <InputNumber
        className="counter__input"
        value={count}
        controls={false}
        min={minValue}
        max={max}
        formatter={countFormatter}
        onChange={handleChange}
        style={getCounterWidthStyles(formattedValue, 48, 300)}
      />

      <Button className="counter__btn increment" icon={<PlusOutlined />} onClick={onIncrementClick} />
    </div>
  );
};
